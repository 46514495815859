import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import * as moment from 'moment';
import { CalendarService } from '@core/services';
import { Router } from '@angular/router';

@Component({
  selector: '[calendar-event-toast]',
  templateUrl: './calendar-events-toast.component.html',
  styleUrls: ['./calendar-events-toast.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        display: 'none',
        opacity: 0
      })),
      state('active', style({})),
      state('removed', style({ opacity: 0 })),
      transition('inactive => active', animate('{{ easeTime }}ms {{ easing }}')),
      transition('active => removed', animate('{{ easeTime }}ms {{ easing }}'))
    ])
  ],
  preserveWhitespaces: false
})
export class CalendarEventToast extends Toast {
  @Input() calendarEvent: any;
  @Input() showEntityLink: boolean;

  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private calendarService: CalendarService,
    private router: Router,
  ) {
    super(toastrService, toastPackage);
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }

  closeToast(toastrId) {
    this.calendarService.toggleEventRead(toastrId).subscribe(r => {
      this.toastrService.remove(toastrId);
    }, err => console.log('Error toggleEventRead', err));
  }

  getFormatFromNow(datetime) {
    var timedifference = new Date().getTimezoneOffset();
    return moment(datetime).utcOffset(timedifference).fromNow();
  }

  postponeReminder(calendarEvent: any, interval, unit) {
    this.calendarService.update(calendarEvent.id, {
      "description": calendarEvent.description,
      "entityName": calendarEvent.entityName,
      "entityId": calendarEvent.entityId,
      "eventDate": moment(calendarEvent.eventDate).add(interval, unit),
      "eventRead": false,
      "title": calendarEvent.title,
      "userId": calendarEvent.userId
    }).subscribe((r: any) => {

      calendarEvent.calendarReminders.forEach(item => {
        this.calendarService.deleteCalendarReminder(item.calendarEntryId, item.id).subscribe();
      });

      const data = {
        "reminderRead": false,
        "interval": interval,
        "unit": "Minutes"
      }
      this.calendarService.createCalendarReminder(calendarEvent.id, data).subscribe((r: any) => { });

      this.toastrService.remove(calendarEvent.id);
    }, err => console.log('Error updating postpone', err));
  }

  openEntityLink(row, e) {
    if (row.entityType && row.entityId) {
      this.router.navigate([row.entityType, row.entityId, row.entityType == 'law-firms' || row.entityType == 'isos' ? 'general-info' : 'overview']);
    }
  }
}
