import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@core/authentication/auth.service';

import { LookupListService, StipulationService, TenantService } from '@core/services';
import { ActiveTab } from '@shared/models/tab';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'oiq-edit-lookup-list-dialog',
  templateUrl: './edit-lookup-list-dialog.component.html',
  styleUrls: ['./edit-lookup-list-dialog.component.scss']
})
export class EditLookupListDialogComponent implements OnInit, OnDestroy {

  dialogTitle: string = 'Value';

  form: UntypedFormGroup;

  private destroy$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private lookupListService: LookupListService,
    private tenantService: TenantService,
    private authService: AuthService,
    private stipulationService: StipulationService,
    public dialogRef: MatDialogRef<EditLookupListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit() {
    const valueValidators = [Validators.required];
    if (this.data.type === 'Application Types') {
      valueValidators.push(Validators.pattern(/^[^`~!@#$%\^&*()+={}|[\]\\:';"<>?,./]*$/));
      this.dialogTitle = 'Application Type';
    }
    this.form = this.fb.group({
      id: [],
      type: ['', Validators.required],
      value: ['', Validators.compose([...valueValidators])],
      tenantId: ['']
    });

    if(this.data.type === 'Stipulations_List') {
      this.form.get('value').patchValue(this.data.lookupList?.name);
    } else {
      this.form.patchValue(this.data.lookupList);
    }

    this.form.patchValue({
      type: this.data.type,
      tenantId: this.data.tenantId
    });
  }

  save() {
    const type = this.data.type as ActiveTab;
    let observable: Observable<unknown>;

    switch(type) {
      case 'Application_Decline_Reason':
      case 'Revision_Reason':
        if(this.form.value.id) {
          observable = this.lookupListService.update(this.form.value.id, this.form.value);
        } else {
          observable = this.lookupListService.create(this.form.value);
        }
        break;

      case 'Stipulations_List':
        if(this.data.lookupList) {
          observable = this.updateStipulation(this.form.value.value);
        } else {
          observable = this.saveStipulation(this.form.value.value);
        }
        break;

      case 'Application_Types':
        const newValue = this.form.value.value.trim();
        if(this.data.lookupList) {
          observable = this.tenantService.editApplicationType(this.data.tenantId, this.data.lookupList.value, newValue)
        } else {
          observable = this.tenantService.saveApplicationType(this.data.tenantId, newValue)
        }
        break;
    }

    observable
      .pipe(takeUntil(this.destroy$))
      .subscribe((r: any) => {
        this.dialogRef.close(true);
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  private saveStipulation(value: string) {
    return this.stipulationService.create({ name: value, tenantId: this.authService.getUser().tenantId });
  }

  private updateStipulation(value: string) {
    return this.stipulationService.update(this.data.lookupList.id, { name: value, tenantId: this.authService.getUser().tenantId });
  }

}
