<div class="content" *ngIf="types.length > 0">
  <div class="content-wrapper">
    <div class="tabbed-tables">
      <div class="heading">
        <h5 [ngClass]="{ 'active': activeTab === type }" (click)="selectTab(type)" *ngFor="let type of types">
          {{ type === 'Revision_Reason' ? 'APPLICATION REVISION REASON' : type | status | titlecase }}
        </h5>
        <h5 [ngClass]="{ 'active': activeTab === 'Stipulations_List' }" (click)="selectTab('Stipulations_List')">{{ 'Stipulations_List' | status | titlecase }}</h5>
      </div>

      <div class="body">
        <div class="clearfix heading">
          <div class="action-buttons">
            <button mat-raised-button color="accent" (click)="editRow()">ADD</button>
          </div>
        </div>

        <table mat-table [dataSource]="datasource" matSort multiTemplateDataRows class="clickable">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef class="select-cell">
              <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" class="select-cell">
              <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [disabled]="!row.tenantId">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef class="ml-5">Name</th>
            <ng-container *ngIf="activeTab === 'Application_Types'; else defaultView">
              <td mat-cell *matCellDef="let row"> {{ row.value | type }} </td>
            </ng-container>
            <ng-template #defaultView>
              <td mat-cell *matCellDef="let row"> {{ row.value ?? row.name }} </td>
            </ng-template>
          </ng-container>
          <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
          <td mat-cell *matCellDef="let row" class="text-end text-nowrap">
            <div [ngSwitch]="activeTab">
              <div *ngSwitchCase="'Application_Types'">
                <div *ngIf="!row.deleting && row.removable">
                  <button mat-raised-button color="accent" class="btn-table me-2"
                          (click)="editRow(row)">EDIT
                  </button>
                  <button mat-raised-button color="warn" class="btn-table"
                          (click)="deleteRow(row)">DELETE
                  </button>
                </div>
              </div>
              <div *ngSwitchDefault>
                <div *ngIf="!row.deleting">
                  <button mat-raised-button color="accent" class="btn-table me-2"
                          (click)="editRow(row)">EDIT
                  </button>
                  <button mat-raised-button color="warn" class="btn-table"
                          (click)="deleteRow(row)">DELETE
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="row.deleting">
              Are you sure?
              <a href="#" (click)="confirmDelete(row, $event)">Yes</a>
              |
              <a href="#" (click)="cancelDelete(row, $event)">No</a>
            </div>
          </td>
        </ng-container>
          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>
        <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="datasource?.data?.length === 0"></oiq-loading-message>
      </div>
    </div>
  </div>
</div>
