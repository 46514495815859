import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators, AbstractControl } from '@angular/forms';
import { Address } from './address';
import { Iso } from './iso';
import { Contact } from './contact';
import { Offer } from './offer';
import { zeroValueValidator } from '@shared/utils-custom-form-validators';

export class ApplicationUpdate extends UntypedFormGroup {
  constructor() {
    super({
      whiteLabelId: new UntypedFormControl(),
      type: new UntypedFormControl(),
      installment: new UntypedFormControl()
    });
  }
}

export class CreditCheckRequest extends UntypedFormGroup {
  constructor(contactId?: string, firstName?: string, lastName?: string, ssn?: string, address1?: string, address2?: string, city?: string, state?: string, zipCode?: string, dob?: string, exclude?: boolean) {
    super({
      contactId: new UntypedFormControl(contactId, Validators.required),
      firstName: new UntypedFormControl(firstName, Validators.required),
      lastName: new UntypedFormControl(lastName, Validators.required),
      ssn: new UntypedFormControl(ssn, Validators.required),
      address1: new UntypedFormControl(address1, Validators.required),
      address2: new UntypedFormControl(address2),
      city: new UntypedFormControl(city, Validators.required),
      state: new UntypedFormControl(state, Validators.required),
      zipCode: new UntypedFormControl(zipCode, Validators.required),
      dob: new UntypedFormControl(dob, Validators.required),
      exclude: new UntypedFormControl(exclude)
    });
  }
}

export class RunCreditCheckRequest extends UntypedFormGroup {
  constructor() {
    super({
      contactId: new UntypedFormControl(),
      addressId: new UntypedFormControl(),
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      address: new Address(),
      addresses: new UntypedFormArray([new Address()]),
      ssn: new UntypedFormControl('', Validators.required),
      dob: new UntypedFormControl()
    });
  }
}

export class DecisionLogicRequest extends UntypedFormGroup {
  constructor() {
    super({
      bankId: new UntypedFormControl('', Validators.required),
      accountNumber: new UntypedFormControl('', Validators.required),
      routingNumber: new UntypedFormControl('', Validators.required),
      contactId: new UntypedFormControl(''),
      emailAddress: new UntypedFormControl('', { validators: Validators.email, updateOn: 'blur' }),
      notificationTarget: new UntypedFormControl('Client', Validators.required)
    });
  }
}

export class PlaidRequest extends UntypedFormGroup {
  constructor() {
    super({
      contactId: new UntypedFormControl('', Validators.required),
      applicationId: new UntypedFormControl(''),
      notificationTarget: new UntypedFormControl('Client', Validators.required),
      emailAddress: new UntypedFormControl('', { validators: Validators.email, updateOn: 'blur' }),
    });
  }
}

export class Stipulation extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(),
      name: new UntypedFormControl(),
      tenantId: new UntypedFormControl()
    });
  }
}

export class ApplicationStipulation extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(),
      name: new UntypedFormControl(),
      status: new UntypedFormControl(),
      notes: new UntypedFormControl(),
      tenantId: new UntypedFormControl()
    });
  }
}

export class ApplicationApproval extends UntypedFormGroup {
  constructor(id, applicationId) {
    super({
      id: new UntypedFormControl(id),
      applicationId: new UntypedFormControl(applicationId),
      fundingMethod: new UntypedFormControl('ACH', Validators.required),
      fundingProcessor: new UntypedFormControl('', Validators.required),
      paymentMethod: new UntypedFormControl('ACH', Validators.required),
      paymentProcessor: new UntypedFormControl('', Validators.required),
      fundingBankId: new UntypedFormControl(),
      paymentBankId: new UntypedFormControl()
    });
  }
}

export class RefinancedFunding extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(),
      fundingId: new UntypedFormControl(),
      applicationId: new UntypedFormControl(),
      source: new UntypedFormControl(),
      companyName: new UntypedFormControl('', Validators.required),
      payoffAmount: new UntypedFormControl('', Validators.required),
      transmissionMethod: new UntypedFormControl('', Validators.required),
      transmissionProcessor: new UntypedFormControl(),
      routingNumber: new UntypedFormControl(),
      accountNumber: new UntypedFormControl(),
      bankId: new UntypedFormControl(),
      deleted: new UntypedFormControl(false)
    });
  }
}

export class Credit extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(''),
      type: new UntypedFormControl(''),
      amount: new UntypedFormControl('', [Validators.required, zeroValueValidator()]),
      bankId: new UntypedFormControl('', Validators.required),
      creditDate: new UntypedFormControl('', Validators.required),
      transmissionMethod: new UntypedFormControl('', Validators.required),
      transmissionProcessor: new UntypedFormControl('', Validators.required)
    });
  }
}

export class ApplicationSettings extends UntypedFormGroup {
  constructor() {
    super({
      notificationSettings: new NotificationSettings()
    });
  }
}

export class NotificationSettings extends UntypedFormGroup {
  constructor() {
    super({
      overrideDefaultSettings: new UntypedFormControl(false),
      events: new UntypedFormArray([]),
      sendIsoNonFundingEmailNotifications: new UntypedFormControl(),
      sendIsoNonFundingSmsNotifications: new UntypedFormControl(),
      sendIsoFundingEmailNotifications: new UntypedFormControl(),
      sendIsoFundingSmsNotifications: new UntypedFormControl(),
      sendSyndicatorEmailNotifications: new UntypedFormControl(),
      sendSyndicatorSmsNotifications: new UntypedFormControl(),
      sendEmailNotifications: new UntypedFormControl(),
      sendSmsNotifications: new UntypedFormControl(),
      failedPaymentNotificationTarget: new UntypedFormControl()
    });
  }
}

export class Email extends UntypedFormGroup {
  constructor() {
    super({
      email: new UntypedFormControl('', [Validators.required, Validators.email])
    });
  }
}

export class ApplicationListExport extends UntypedFormGroup {
  constructor() {
    super({
      output: new UntypedFormControl('Excel', Validators.required),
      type: new UntypedFormControl('Standard', Validators.required),
      tenantUserIdRecipients: new UntypedFormControl(),
      isoUserIdRecipients: new UntypedFormControl(),
      isoEmailAddressRecipients: new UntypedFormControl(),
      emailAddressRecipients: new UntypedFormArray([]),
      emailMessage: new UntypedFormControl(),
      includeType: new UntypedFormControl(true, Validators.required),
      includeStatus: new UntypedFormControl(true, Validators.required),
      includeClientName: new UntypedFormControl(true, Validators.required),
      includeClientLegalName: new UntypedFormControl(true, Validators.required),
      includeDateSubmitted: new UntypedFormControl(true, Validators.required),
      includeDateApproved: new UntypedFormControl(false, Validators.required),
      includeApprovedAmount: new UntypedFormControl(false, Validators.required),
      includeDateFunded: new UntypedFormControl(false, Validators.required),
      includeFundingStatus: new UntypedFormControl(false, Validators.required),
      includePaymentFrequency: new UntypedFormControl(false, Validators.required),
      includeAchAmount: new UntypedFormControl(false, Validators.required),
      includePercentagePaidIn: new UntypedFormControl(false, Validators.required),
      includeBuyRate: new UntypedFormControl(false, Validators.required),
      includeFactorRate: new UntypedFormControl(false, Validators.required),
      includePosition: new UntypedFormControl(false, Validators.required),
      includePayBackAmount: new UntypedFormControl(false, Validators.required),
      includeBalanceWithFees: new UntypedFormControl(false, Validators.required),
      includeDateDeclined: new UntypedFormControl(false, Validators.required),
      includeDeclineReason: new UntypedFormControl(false, Validators.required),
      includeUnderwriterNotes: new UntypedFormControl(false, Validators.required),
      includeCreatedDate: new UntypedFormControl(false, Validators.required),
      includeStatusChangeDate: new UntypedFormControl(false, Validators.required)
    });
  }
}

export class RefinanceForm extends UntypedFormGroup {
  constructor() {
    super({
      externalFundings: new UntypedFormArray([]),
      refinanceBalanceSource: new UntypedFormControl({ value: 'Outstanding_Balance', disabled: true }),
      refinanceBalanceSourceIncludeFees: new UntypedFormControl({ value: true, disabled: true }),
      outstandingBalance: new UntypedFormControl(0),
      outstandingBalanceWithFees: new UntypedFormControl(0),
      pendingBalance: new UntypedFormControl(0),
      pendingBalanceWithFees: new UntypedFormControl(0),
      externalBalance: new UntypedFormControl(0),
      totalPayoffBalance: new UntypedFormControl(0),
      totalPayoffBalanceWithFees: new UntypedFormControl(0),
      refinanceAmount: new UntypedFormControl(0),
      disbursementAmount: new UntypedFormControl(0),
      refinanceNotes: new UntypedFormControl(),
    });
  }
}

export class FundingOnlyApplication extends UntypedFormGroup {
  constructor() {
    super({
      client: new UntypedFormGroup({
        id: new UntypedFormControl(),
        legalName: new UntypedFormControl(),
        dbaName: new UntypedFormControl(),
        email: new UntypedFormControl('', { validators: Validators.email, updateOn: 'blur' }),
        phoneNumber: new UntypedFormControl(),
        cellNumber: new UntypedFormControl(),
        grossMonthlySales: new UntypedFormControl(),
        // industryType: new FormControl(),
        // sicCode: new FormControl(),
        startDate: new UntypedFormControl(),
        incorporationState: new UntypedFormControl(),
        businessType: new UntypedFormControl(),
        ein: new UntypedFormControl(),
        voidedCheckName: new UntypedFormControl(),
        addresses: new UntypedFormArray([
          new UntypedFormGroup({
            id: new UntypedFormControl(),
            address1: new UntypedFormControl('', Validators.required),
            address2: new UntypedFormControl(),
            city: new UntypedFormControl('', Validators.required),
            state: new UntypedFormControl('', Validators.required),
            zipCode: new UntypedFormControl('', Validators.required),
            country: new UntypedFormControl()
          })
        ]),
        contacts: new UntypedFormArray([
          new UntypedFormGroup({
            id: new UntypedFormControl(),
            firstName: new UntypedFormControl('', Validators.required),
            lastName: new UntypedFormControl('', Validators.required),
            email: new UntypedFormControl('', [Validators.required, Validators.email]),
            cellNumber: new UntypedFormControl('', Validators.required),
            workNumber: new UntypedFormControl(),
            ssn: new UntypedFormControl(),
            fico: new UntypedFormControl(),
            dob: new UntypedFormControl(),
            percent: new UntypedFormControl(),
            primaryOwner: new UntypedFormControl(),
            addresses: new UntypedFormArray([
              new Address()
            ])
          })
        ]),
        contact: new UntypedFormGroup({
          id: new UntypedFormControl(),
          firstName: new UntypedFormControl('', Validators.required),
          lastName: new UntypedFormControl('', Validators.required),
          email: new UntypedFormControl('', { validators: Validators.email, updateOn: 'blur' }),
          cellNumber: new UntypedFormControl(),
          workNumber: new UntypedFormControl(),
          ssn: new UntypedFormControl(),
          fico: new UntypedFormControl(),
          dob: new UntypedFormControl(),
          percent: new UntypedFormControl(),
          primaryOwner: new UntypedFormControl()
        })
      }),
      appliedFundingOffer: new UntypedFormGroup({
        fundedAmount: new UntypedFormControl(),
        buyRate: new UntypedFormControl(),
        factorRate: new UntypedFormControl(),
        commissionPercent: new UntypedFormControl(),
        commissionAmount: new UntypedFormControl(),
        paybackAmount: new UntypedFormControl(),
        position: new UntypedFormControl(),
        paymentAmount: new UntypedFormControl(),
        totalPayments: new UntypedFormControl(),
        paymentFrequency: new UntypedFormControl('Daily'),
        paymentBreakdown: new UntypedFormControl(''),
        percentOfGross: new UntypedFormControl(),
        termLength: new UntypedFormControl(),
        fundingDate: new UntypedFormControl()
      }),
      clientId: new UntypedFormControl(),
      sendEmailNotifications: new UntypedFormControl(),
      sendSmsNotifications: new UntypedFormControl(),
      operationsNotes: new UntypedFormControl(),
      source: new UntypedFormControl(),
      type: new UntypedFormControl(),
      whiteLabelId: new UntypedFormControl(),
      isoId: new UntypedFormControl('', Validators.required),
      isoSalesRepresentativeId: new UntypedFormControl(),
    });
  }
}



export class OfferRange {
  id: number;
  applicationId: number;
  minTerm = 1;
  maxTerm = 3;
  minBuyRate = 1;
  maxBuyRate = 2;
  minCommissionPercent = 0;
  maxCommissionPercent = 50;
  minPercentOfGross = 1;
  maxPercentOfGross = 50;
  position = 1;
  paymentFrequency = 'Daily';
  fundingOffers = []
  sentOn: number[] | null;
  currentlyActive = false;
  requiresUpdate = false


  serialize(dto: any) {
    this.minBuyRate = dto.minBuyRate;
    this.maxBuyRate = dto.maxBuyRate;
    this.minCommissionPercent = dto.minCommissionPercent;
    this.maxCommissionPercent = dto.maxCommissionPercent;
    this.minPercentOfGross = dto.minPercentOfGross;
    this.maxPercentOfGross = dto.maxPercentOfGross;
    this.minTerm = dto.minTerm;
    this.maxTerm = dto.maxTerm;
    this.position = dto.position;
    this.applicationId = dto.applicationId;
    this.fundingOffers = dto.fundingOffers;
    this.id = dto.id;
    this.sentOn = dto.sentOn ? dto.sentOn : null;
    this.currentlyActive = dto.currentlyActive;
    this.paymentFrequency = dto.paymentFrequency;
  }
}

export class PostFunding extends UntypedFormGroup {
  constructor() {
    super({
      fundingEditDto: new UntypedFormGroup({
        amountPaid: new UntypedFormControl(),
        applicationId: new UntypedFormControl(),
        balance: new UntypedFormControl(),
        balanceWithFees: new UntypedFormControl(),
        commissionAmount: new UntypedFormControl(),
        commissionPercent: new UntypedFormControl(),
        disbursementAmount: new UntypedFormControl(),
        fundingDate: new UntypedFormControl(),
        paybackAmountWithFees: new UntypedFormControl(),
        offerBankFeeAmount: new UntypedFormControl(),
        offerBankFeePercent: new UntypedFormControl(),
      }),
      fundingOfferDto: new UntypedFormGroup({
        applicationId: new UntypedFormControl(),
        bankFeeAmount: new UntypedFormControl(),
        bankFeePercent: new UntypedFormControl(),
        buyRate: new UntypedFormControl(),
        commissionAmount: new UntypedFormControl(),
        commissionPercent: new UntypedFormControl(),
        customFeeAmount: new UntypedFormControl(),
        expectedStartDate: new UntypedFormControl(),
        factorRate: new UntypedFormControl(),
        fundedAmount: new UntypedFormControl(0, Validators.compose([Validators.required, Validators.min(0.01)])),
        paybackAmount: new UntypedFormControl(),
        paymentAmount: new UntypedFormControl(),
        paymentBreakdown: new UntypedFormControl(),
        paymentFrequency: new UntypedFormControl('Daily'),
        percentOfGross: new UntypedFormControl(),
        position: new UntypedFormControl('', Validators.required),
        sendOffer: new UntypedFormControl(),
        sentOn: new UntypedFormControl(),
        splitLockBoxExternalId: new UntypedFormControl(),
        termLength: new UntypedFormControl(),
        totalPayments: new UntypedFormControl(),
        transmissionMethod: new UntypedFormControl(),
        splitPercent: new UntypedFormControl(''),
        wireFeeAmount: new UntypedFormControl(),
        customCommissionEnabled: new UntypedFormControl(false)
      }, { updateOn: 'blur' }),
      prepayDtos: new UntypedFormArray([])
    });
  }
}

export class PrepaysDto extends UntypedFormGroup {
  constructor() {
    super({
      applied: new UntypedFormControl(),
      buyRate: new UntypedFormControl('', Validators.compose([Validators.required, buyRateLessThanFactorRateValidator])),
      commissionAmount: new UntypedFormControl(),
      commissionPercent: new UntypedFormControl(),
      factorRate: new UntypedFormControl('', Validators.compose([Validators.required, factorRateGreaterThanBuyRateValidator])),
      numberOfDays: new UntypedFormControl(),
      paybackAmount: new UntypedFormControl(),
      startDay: new UntypedFormControl('', Validators.required),
      endDay: new UntypedFormControl('', Validators.required),
      term: new UntypedFormControl(),
      id: new UntypedFormControl()
    });
  }
}

export type Application = {
  id: string;
  amount: number;
  applicationId: number;
  applicationStatus: ApplicationStatus;
  clientId: number;
  clientName: string;
  comparingFieldName: string;
  createdDate: Date;
  isoManagerName: string;
  isoName: string;
  operationsNotes: string;
  submittedDate: Date;
  tenantId: number;
  updatedByName: string;
  lastUpdatedDate: Date;
  applicationType: string;
  userId: number;
  isAccessible: boolean;
};

export type UnderwritingApplication = Application;

export type PreUnderwritingApplication = {
  id: string;
  amount: number;
  applicationId: number;
  applicationStatus: ApplicationStatus;
  clientId: number;
  clientName: string;
  comparingFieldName: string;
  createdDate: Date;
  isoManagerName: string;
  isoName: string;
  operationsNotes: string;
  submittedDate: Date;
  tenantId: number;
  updatedByName: string;
  lastUpdatedDate: Date;
  applicationType: string;
  userId: number;
}

export type OperationsApplication = {
  id: string;
  amount: number;
  applicationId: number;
  applicationStatus: ApplicationStatus;
  clientId: number;
  clientName: string;
  comparingFieldName: string;
  createdDate: Date;
  tenantId: number;
  isAccessible: boolean;
};

export enum ApplicationStatus {
  New = 'NEW',
  Draft = 'DRAFT',
  Credit_Check = 'CREDIT CHECK',
  Operations_Queue = 'OPERATIONS QUEUE',
  Underwriting_Queue = 'UNDERWRITING QUEUE',
  Underwriting = 'UNDERWRITING',
  Application_Revision = 'APPLICATION REVISION',
  Application_Approved = 'APPLICATION APPROVED',
  Offer_Applied = 'OFFER APPLIED',
  Disclosure_Sent = 'DISCLOSURE SENT',
  Disclosure_Signed = 'DISCLOSURE SIGNED',
  Contracts_Sent = 'CONTRACTS SENT',
  Contracts_Received_Incomplete = 'CONTRACTS RECEIVED INCOMPLETE',
  Contracts_Received_Complete = 'CONTRACTS RECEIVED COMPLETE',
  Contracts_Awaiting_Stipulations = 'CONTRACTS AWAITING STIPULATIONS',
  Stipulations_Review = 'STIPULATIONS REVIEW',
  Final_Underwriting = 'FINAL UNDERWRITING',
  Underwriting_Review_Queue = 'UNDERWRITING REVIEW QUEUE',
  Underwriting_Review = 'UNDERWRITING REVIEW',
  Final_Funding_Approval = 'FINAL FUNDING APPROVAL',
  Funded = 'FUNDED',
  No_Go = 'NO GO',
  Declined = 'DECLINED',
  Pre_Underwriting_Queue = 'PRE UNDERWRITING QUEUE',
  Pre_Underwriting = 'PRE UNDERWRITING'
}


export enum ApplicationPriority {
  Regular = 'REGULAR',
  High = 'HIGH'
};


function buyRateLessThanFactorRateValidator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }
  const factorRate = formControl.parent.get('factorRate').value;
  const buyRate = formControl.parent.get('buyRate').value;
  if (factorRate && buyRate) {
    if (buyRate > factorRate) {
      return { buyRateGreaterThanFactorRateError: true };
    }
    formControl.parent.get('factorRate').setErrors(null);
    return null;
  }
  return null;
}

function factorRateGreaterThanBuyRateValidator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }
  const factorRate = formControl.parent.get('factorRate').value;
  const buyRate = formControl.parent.get('buyRate').value;
  if (factorRate && buyRate) {
    if (factorRate < buyRate) {
      return { factorRateLessThanBuyRateError: true };
    }
    formControl.parent.get('buyRate').setErrors(null);
    return null;
  }
  return null;
}
