import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlaidService, TenantService } from '@core/services';
import { DomSanitizer } from '@angular/platform-browser';

import { environment } from '../../environments/environment';

declare var Plaid: any;

@Component({
  selector: 'oiq-plaid-link-component',
  templateUrl: './plaid-link-component.component.html',
  styleUrls: ['./plaid-link-component.component.scss']
})
export class PlaidLinkComponentComponent implements OnInit {

  contactId: number;
  tenantId: number;
  tenant: any;
  linkToken: string;
  plaidReportId: number;
  accounts: Array<any> = [];
  transactions: Array<any> = [];
  imagePath = '';
  errorMessage: string;
  disableButton: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private plaidService: PlaidService,
    private tenantService: TenantService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.contactId = parseInt(params.contactId);
      this.tenantId = parseInt(params.tenantId);
      this.linkToken = params.linkToken;
      this.plaidReportId = parseInt(params.plaidReportId);
      this.getTenantLogo();
    });
  }

  openPlaidLink(linkToken: string) {
    const handler = Plaid.create({
      token: linkToken,
      onSuccess: (publicToken, metadata) => {
        this.plaidService.exchangePublicToken({ tenantId: this.tenantId, contactId: this.contactId, publicToken, plaidReportId: this.plaidReportId }).subscribe((data: any) => {
          // KICK OFF completed after bank is added to initiate full plaidRequest
          this.plaidService.completeLinkToken({
            tenantId: this.tenantId,
            plaidReportId: this.plaidReportId
          }).subscribe((complete: any) => {
          });
        });
      },
      onLoad: () => {
        // test resendByLinkToken from plaidService
      },
      onExit: (err, metadata) => {
        if (err !== null) {
          this.errorMessage = err.error_message;
        }

        if (
          err.error_type === 'INVALID_REQUEST' &&
          metadata.status === 'requires_credentials' &&
          err.error_message === 'OAuth redirect URI must be configured in the developer dashboard. See https://plaid.com/docs/#oauth-redirect-uris') {
          this.errorMessage = '';
          this.resendExpiredLink(this.plaidReportId);
        }
      },
      onEvent: (eventName, metadata) => { },
      //required for OAuth; if not using OAuth, set to null or omit:
      receivedRedirectUri: null
    });

    handler.open();
  }

  getTenantLogo() {
    this.tenantService.getLogo(this.tenantId)
      .subscribe((r: any) => {
        this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + r.logo) as string;
      });
  }

  addBank() {
    this.openPlaidLink(this.linkToken);
  }

  resendExpiredLink(plaidReportId: number) {
    this.plaidService.resendLinkTokenByReportId(plaidReportId).subscribe((data: any) => {
      this.errorMessage = 'Plaid Link expired. A new email with the link has been sent.';
      this.disableButton = true;
    }, (error) => {
      this.errorMessage = 'Plaid Link expired. There was an error resending the link. Please try again.';
    });
  }
}
