import { OnChanges, SimpleChanges, Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { AuthService } from '@core/authentication/auth.service';
import { TenantService, MoneyThumbColumnsService } from '@core/services';
import { trigger, state, style } from '@angular/animations';
import { AccountAverages, AccountData, ColumnAverages, FormattedMTAccount, MTInsight, ThumbPrintData } from '@core/models';

@Component({
  selector: 'oiq-moneythumb-scorecard-uw',
  templateUrl: './moneythumb-scorecard-uw.component.html',
  styleUrls: ['./moneythumb-scorecard-uw.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' }))
    ])
  ]
})
export class MoneythumbScorecardUwComponent implements OnInit, OnChanges {
  @Input() mtInsights: MTInsight[] = [];
  @Input() formattedMTAccounts: FormattedMTAccount[] = [];
  @Input() thumbPrintData: ThumbPrintData[] = [];
  @Input() thumbPrintEnabled: boolean;

  tenantId: number;
  displayedColumns: string[] = [];
  availableColumns = [];
  selectedColumns = [];
  columnsLoading: boolean = false;
  accountAverages: AccountAverages = {};
  expandedElement: AccountData | null = null;
  expandedType: 'transactions' | 'fraud' | null = null;

  columnPipeMap = {
    "ending_date": "date",
    "total_credits": "currency",
    "#_credits": "number",
    "true_credits": "currency",
    "#_true_credits": "number",
    "non-true_credits": "currency",
    "#_non-true_credits": "number",
    "total_debits": "currency",
    "#_debits": "number",
    "true_debits": "currency",
    "#_true_debits": "number",
    "non-true_debits": "currency",
    "#_non-true_debits": "number",
    "avg_balance": "currency",
    "avg_true_balance": "currency",
    "ending_balance": "currency",
    "days_neg": "number",
    "#_nsf's": "number",
    "#_od's": "number",
    "thumbprint_score": "number",
    "incoming_transfers_total": "currency",
    "outgoing_transfers_total": "currency",
    "mca_credits": "currency",
    "mca_debits": "currency"
  };

  constructor(
    private authService: AuthService,
    private tenantService: TenantService,
    private moneyThumbColumnsService: MoneyThumbColumnsService
  ) { }

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formattedMTAccounts && this.formattedMTAccounts?.length) {
      this.calculateAverages();
    }
  }

  ngOnInit() {
    this.tenantId = this.authService.getUser().tenantId;
    this.getAvailableColumns();
  }

  getAvailableColumns() {
    this.columnsLoading = true;
    this.moneyThumbColumnsService.list().subscribe((r: any) => {
      this.availableColumns = r;

      this.getSelectedOcrColumns(this.tenantId);
    });
  }
  getSelectedOcrColumns(tenantId: number): void {
    this.tenantService.getOcrColumns(tenantId).subscribe((r: string[]) => {
      const columnsMap: { [id: string]: { id: string, value: string } } = {};
  
      this.availableColumns.forEach(obj => {
        columnsMap[obj.id] = obj;
      });
  
      this.selectedColumns = r.map(id => columnsMap[id]).filter(column => column !== undefined);
  
      this.displayedColumns = this.selectedColumns.length > 0
        ? this.selectedColumns
            .map(item => item.value)
            .filter(column => !(column === 'thumbprint_score' && !this.thumbPrintEnabled))
        : [
            'statement_month_#',
            'statement_year',
            'total_credits',
            'ending_balance',
            'avg_balance',
            'days_neg',
            'NsfS',
            'OdS',
            '#_credits',
            'incoming_transfers_total',
            'mca_credits',
            'true_credits'
          ];
      if (this.formattedMTAccounts?.length) {
        this.calculateAverages();
        this.mergeFraudFactors();
      }
      this.columnsLoading = false;
    });
  }

  calculateAverages() {
    if (!this.formattedMTAccounts || this.formattedMTAccounts.length === 0) {
      return;
    }
  
    const excludedColumns = ['statement_month_#', 'statement_year'];
  
    this.formattedMTAccounts.forEach(account => {
      const accountKey = account[0];
      const data = account[1];

      if (!data || data.length === 0) {
        this.accountAverages[accountKey] = {};
        return;
      }

      this.accountAverages[accountKey] = this.displayedColumns.reduce((averages, column) => {
        if (excludedColumns.includes(column)) {
          return averages;
        }

        const pipeType = this.getPipe(column);

        const validValues = data
          .map(row => (row[column] !== undefined && row[column] !== null ? Number(row[column]) : null))
          .filter(value => value !== null && !isNaN(value));

        if (validValues.length === 0) {
          averages[column] = null;
        } else {
          const sum = validValues.reduce((acc, value) => acc + value, 0);
          const average = sum / validValues.length;

          switch (pipeType) {
            case 'currency':
              averages[column] = Math.round(average * 100) / 100;
              break;
            case 'number':
              averages[column] = Math.round(average);
              break;
            default:
              averages[column] = average;
              break;
          }
        }

        return averages;
      }, {} as ColumnAverages);
    });
  }

  getPipe(column: string): string {
    return this.columnPipeMap[column] || 'string';
  }

  get fullDisplayedColumns(): string[] {
    return ['toggle', ...this.displayedColumns];
  }

  toggleTransactions(row: AccountData, type: 'transactions' | 'fraud'): void {
    if (this.expandedElement === row && this.expandedType === type) {
      this.expandedElement = null;
      this.expandedType = null;
    } else {
      this.expandedElement = row;
      this.expandedType = type;
    }
  }
  
  mergeFraudFactors(): void {
    if (!this.thumbPrintEnabled) {
      return;
    }
  
    this.formattedMTAccounts.forEach(([accountId, rows]) => {
  
      rows.forEach((row) => {
        const rowId = typeof row.DT_RowId === 'string' ? row.DT_RowId.substring(1) : String(row.DT_RowId).substring(1);
  
        const matchingThumbPrint = this.thumbPrintData.find((thumbprint) => {
          const thumbprintRowId = typeof thumbprint.DT_RowId === 'string' ? thumbprint.DT_RowId.substring(1) : String(thumbprint.DT_RowId).substring(1);
          return (
            thumbprint.account === accountId &&
            Number(thumbprint.thumbprint_score) === Number(row.thumbprint_score) &&
            thumbprintRowId === rowId
          );
        });
  
        if (matchingThumbPrint && matchingThumbPrint.contributing_factors) {
          row.contributing_factors = matchingThumbPrint.contributing_factors.split('\n').map(factor => factor.trim());
        } else {
          row.contributing_factors = [];
        }
      });
    });
  }
  
  getFraudFactors(row: AccountData): string[] {
    if (Array.isArray(row.contributing_factors)) {
      return row.contributing_factors;
    }
    if (typeof row.contributing_factors === 'string') {
      return row.contributing_factors.split('\n').map(factor => factor.trim());
    }
    return [];
  }
}