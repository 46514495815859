import { UntypedFormControl, UntypedFormGroup, FormArray, Validators } from '@angular/forms';

export class Payment extends UntypedFormGroup {
  constructor() {
    super({
      fundedAmount: new UntypedFormControl(),
      buyRate: new UntypedFormControl(),
      factorRate: new UntypedFormControl(),
      termLength: new UntypedFormControl(),
      commissionAmount: new UntypedFormControl(),
      commissionPercent: new UntypedFormControl(),
      paybackAmount: new UntypedFormControl(),
      paymentAmount: new UntypedFormControl(),
      paymentBreakdown: new UntypedFormControl(),
      paymentFrequency: new UntypedFormControl('Daily'),
      percentOfGross: new UntypedFormControl(),
      position: new UntypedFormControl('', Validators.required),
      totalPayments: new UntypedFormControl(),
      updatedField: new UntypedFormControl(),
    }, { updateOn: 'blur' });
  }
}

export class Fee extends UntypedFormGroup {
  constructor(feeId?: number, amount?: number) {
    super({
      feeId: new UntypedFormControl(feeId, Validators.required),
      amount: new UntypedFormControl(amount ? amount : 0, Validators.required)
    });
  }
}

export class FundingSyndication extends UntypedFormGroup {
  constructor(fundingSyndicationId?: number, amount?: number) {
    super({
      fundingSyndicationId: new UntypedFormControl(fundingSyndicationId, Validators.required),
      amount: new UntypedFormControl(amount ? amount : 0, Validators.required)
    });
  }
}

export enum AutomaticPaymentStatus {
  ACTIVE = 'Active',
  PAUSED = 'Paused'
}
