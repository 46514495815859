import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ApplicationNotificationsSettings } from '@core/models/application-notifications-settings';
import { ApplicationNotificationsStateService } from '@core/services/global-state-services/application-notifications-state.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'oiq-application-heading',
  templateUrl: './application-heading.component.html',
  styleUrls: ['./application-heading.component.scss']
})
export class ApplicationHeadingComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  application: any;

  disabled = false;
  applicationNotificationSettings: ApplicationNotificationsSettings;
  componentDestroyed$ = new Subject();

  constructor(private applicationNotificationsStateService: ApplicationNotificationsStateService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.application) {
      if (this.application && this.application.status === 'Offer_Applied' && !this.application.contract) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    }
  }

  ngOnInit(): void {
    this.applicationNotificationsStateService
    .applicationNotificationsSettings$
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe((applicationNotificationSettings: ApplicationNotificationsSettings) => this.applicationNotificationSettings = applicationNotificationSettings)
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(null);
    this.componentDestroyed$.complete();
  }

}

