import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';

import { Address } from './address';
import { Contact } from './contact';

export class Client extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(),
      name: new UntypedFormControl(),
      type: new UntypedFormControl(),
      legalName: new UntypedFormControl(),
      dbaName: new UntypedFormControl(),
      ein: new UntypedFormControl(),
      email: new UntypedFormControl('', { validators: Validators.email, updateOn: 'blur' }),
      phoneNumber: new UntypedFormControl(),
      cellNumber: new UntypedFormControl(),
      faxNumber: new UntypedFormControl(),
      startDate: new UntypedFormControl(),
      entityType: new UntypedFormControl(),
      sicCode: new UntypedFormControl('', [Validators.pattern('^[0-9]+$')]),
      businessType: new UntypedFormControl(),
      incorporationState: new UntypedFormControl(),
      industryType: new UntypedFormControl(),
      locationType: new UntypedFormControl(),
      productsSold: new UntypedFormControl(),
      grossMonthlySales: new UntypedFormControl(),
      contactId: new UntypedFormControl(),
      isoId: new UntypedFormControl(),
      isoSalesRepresentativeId: new UntypedFormControl(),
      tradeReferences: new UntypedFormArray([]),
      addresses: new UntypedFormArray([new Address()]),
      lengthOfOwnership: new UntypedFormControl(),
      incorporationDate: new UntypedFormControl(),
      primaryWebsite: new Website(),
      voidedCheckName: new UntypedFormControl(),
      sicId: new UntypedFormControl(),
      externalSource: new UntypedFormControl()
      // otherWebsites: new FormArray([new Website()])
    });
  }
}

export class Website extends UntypedFormGroup {
  constructor() {
    super({
      url: new UntypedFormControl(),
      description: new UntypedFormControl()
    })
  }
}

export class TradeReference extends UntypedFormGroup {
  constructor() {
    super({
      name: new UntypedFormControl('', Validators.required),
      contact: new UntypedFormControl('', Validators.required),
      number: new UntypedFormControl('', Validators.required)
    });
  }
}
