import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class IsoService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'isos/';
  }

  listByCriteria(ids: string, name: string, whiteLabelId: number, region: string, activity: string, isoManagerIds: Array<any>, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (ids) { params['ids'] = ids.toString(); }
    if (name) { params['name'] = name.toString(); }
    if (whiteLabelId) { params['whiteLabelId'] = whiteLabelId; }
    if (region) { params['region'] = region.toString(); }
    if (activity) { params['activity'] = activity.toString(); }
    if (isoManagerIds && isoManagerIds.length > 0) { params['isoManagerIds'] = isoManagerIds.join(','); }

    return this.http.get(this.getUrl(), { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listApplicationsByCriteria(id: number, ids: string, description: string, fundingAmountStart: number, fundingAmountEnd: number, clientId, clientName: string, statuses: string, isoIds: Array<any>, isoManagerIds: Array<any>, startDate: string, endDate: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (ids) { params['ids'] = ids.toString(); }
    if (description) { params['description'] = description.toString(); }
    if (fundingAmountStart) { params['fundingAmountStart'] = fundingAmountStart.toString(); }
    if (fundingAmountEnd) { params['fundingAmountEnd'] = fundingAmountEnd.toString(); }
    if (clientId) { params['clientId'] = clientId; }
    if (clientName) { params['clientName'] = clientName; }
    if (statuses) { params['statuses'] = statuses; }
    if (isoIds && isoIds.length > 0) { params['isoIds'] = isoIds.join(','); }
    if (isoManagerIds && isoManagerIds.length > 0) { params['isoManagerIds'] = isoManagerIds.join(','); }
    if (startDate) { params['startDate'] = startDate; }
    if (endDate) { params['endDate'] = endDate; }

    return this.http.get(this.getUrl() + `${id}/applications`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listFundingsByCriteria(id: number, ids: string, clientId?: number, clientName?: string, description?: string, fundingAmountStart?: number, fundingAmountEnd?: number, isoIds?: Array<any>, statuses?: string, startDate?: string, endDate?: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string, includeFees: boolean = false): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (ids) { params['ids'] = ids.toString(); }
    if (clientId) { params['clientId'] = clientId.toString(); }
    if (clientName) { params['clientName'] = clientName; }
    if (description) { params['description'] = description.toString(); }
    if (fundingAmountStart) { params['fundingAmountStart'] = fundingAmountStart.toString(); }
    if (fundingAmountEnd) { params['fundingAmountEnd'] = fundingAmountEnd.toString(); }
    if (isoIds && isoIds.length > 0) { params['isoIds'] = isoIds.join(','); }
    if (statuses && statuses.length > 0) { params['statuses'] = statuses; }
    if (startDate) { params['startDate'] = startDate; }
    if (endDate) { params['endDate'] = endDate; }
    if (includeFees) { params['includeFees'] = includeFees.toString(); }

    return this.http.get(this.getUrl() + `${id}/fundings`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listUsers(id: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${id}/users`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  listUsersMinimal(id: number, whiteLabelId?: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    if (whiteLabelId) {
      params['whiteLabelId'] = whiteLabelId.toString();
    }

    return this.http.get(this.getUrl() + `${id}/users-minimal`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  listBanks(id: number) {
    return this.http.get(this.getUrl() + `${id}/banks`)
      .pipe(map(res => {
        return res;
      }));
  }

  listOutstandingCommissions(fundingStartDate?: string, fundingEndDate?: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (fundingStartDate) { params['fundingStartDate'] = fundingStartDate; }
    if (fundingEndDate) { params['fundingEndDate'] = fundingEndDate; }

    return this.http.get(this.getUrl() + `outstanding-commissions`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  listCommissions(offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `commissions`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  listPaidCommissionsGroupedByIso(fundingStartDate?: string, fundingEndDate?: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    if (fundingStartDate) { params['fundingStartDate'] = fundingStartDate; }
    if (fundingEndDate) { params['fundingEndDate'] = fundingEndDate; }

    return this.http.get(this.getUrl() + 'commissions-grouped-by-iso', { params })
      .pipe(map(res => {
        return res;
      }));
  }

  listPaidCommissionsWithPartsGroupedByIso(startDate?: string, endDate?: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    if (startDate) { params['startDate'] = startDate; }
    if (endDate) { params['endDate'] = endDate; }

    return this.http.get(this.getUrl() + 'commissions-grouped-by-iso-with-parts', { params })
      .pipe(map(res => {
        return res;
      }));
  }
  addUser(id: number, userId: number): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/users/${userId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  assignIsoUserWhiteLabels(data: any, isoId: number, userId: number): Observable<any> {
    return this.http.put(this.getUrl() + `${isoId}/users/${userId}/whitelabels`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  createIsoUser(data: any, isoId?: number) {
    return this.http.post(this.getUrl() + `${isoId}/users`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateIsoUser(data: any, isoId: number, userId: number) {
    return this.http.put(this.getUrl() + `${isoId}/users/${userId}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteUser(id: number, userId: number): Observable<any> {
    return this.http.delete(this.getUrl() + `${id}/users/${userId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getSettings(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/settings`)
      .pipe(map(res => {
        return res;
      }));
  }

  getWhiteLabels(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/whitelabels`)
      .pipe(map(res => {
        return res;
      }));
  }

  getIsoWhiteLabelNames(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/whitelabelnames`)
      .pipe(map(res => {
        return res;
      }));
  }

  assignIsoWhiteLabels(id: number, data: any, newIso: boolean): Observable<any> {
    const params = {};
    params['newIso'] = newIso;
    return this.http.put(this.getUrl() + `${id}/whitelabels`, data, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  updateBilling(id: number, data: any): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/billing`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateSettings(id: number, data: any): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/settings`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  assignBank(id: number, bankId: number): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/banks/${bankId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  listAttachments(id: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${id}/attachments`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  addAttachment(isoId: number, attachmentId: number) {
    return this.http.put(this.getUrl() + `${isoId}/attachments/${attachmentId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  addAttachments(isoId: number,fileIds: number[]) {
    return this.http.put(this.getUrl() + `${isoId}/attachments`, { attachments: fileIds})
       .pipe(map(res => {
      return res;
    }))
  }

  payCommissions(data: any): Observable<any> {
    return this.http.post(this.getUrl() + `pay-commissions`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  paySpecificCommissions(data: any): Observable<any> {
    return this.http.post(this.getUrl() + `pay-commissions-fundingids`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  zeroOutCommissions(isoIds: any[], date: string) {
    return this.http.put(this.getUrl() + `${isoIds}/zero-out-fundingids?upToDate=${date}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  getFirstPossibleEffectiveDate(isoIds: any[]) {
    const params: any = {};
    params['isoIds'] = isoIds.toString();

    return this.http.get(this.getUrl() + `first-possible-effective-date`, { params: params, responseType: 'text' })
    .pipe(map(res => {
      return res;
    }));
  }

  saveClawbacks(result: any) {
    return this.http.post(this.getUrl() + `clawback-commission`, result)
      .pipe(map(res => {
        return res;
      }));
  }
}
