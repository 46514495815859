import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { V2RoutingModule } from "./v2-routing.module";
import { V2AppComponent } from './app/v2-app.component';

@NgModule({
  imports: [CommonModule, V2RoutingModule],
  exports: [],
  declarations: [V2AppComponent],
  providers: [],
})
export class V2Module {
}
