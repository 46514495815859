import { UntypedFormControl, UntypedFormGroup, FormArray, Validators } from '@angular/forms';

export class CalendarEvent extends UntypedFormGroup {
  constructor(id?: number, date?: string, time?: string, interval?: string, interval2?: string, title?: string, description?: string, entityId?: number, entityName?: string, entityType?: string) {
    super({
      id: new UntypedFormControl(id),
      date: new UntypedFormControl(date, Validators.required),
      time: new UntypedFormControl(time, Validators.required),
      interval: new UntypedFormControl(interval, Validators.required),
      interval2: new UntypedFormControl(interval2),
      title: new UntypedFormControl(title, Validators.required),
      description: new UntypedFormControl(description),
      entityId: new UntypedFormControl(entityId),
      entityName: new UntypedFormControl(entityName),
      entityType: new UntypedFormControl(entityType)
    });
  }
}
