import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthService } from '@core/authentication/auth.service';
import { CalendarEvent } from '@core/models';
import { CalendarService } from '@core/services';
import { ThemePalette } from '@angular/material/core';

import * as moment from 'moment';

import { EditNotificationDialogComponent } from '@shared/navigation/edit-notification-dialog/edit-notification-dialog.component';

@Component({
  selector: 'oiq-calendar-events',
  templateUrl: './calendar-events.component.html',
  styleUrls: ['./calendar-events.component.scss']
})
export class CalendarEventsComponent implements OnInit {
  @Input() showNotifications: boolean;
  @Output() showNotificationsChange = new EventEmitter<boolean>();

  background: ThemePalette = undefined;
  events: any[];
  eventsTotal: number;
  upcomingEvents: any[];
  upcomingEventsTotal: number;
  futureEvents: any[];
  futureEventsTotal: number;
  unreadEvents: any[];
  unreadEventsTotal: number;
  pastEvents: any[];
  pastEventsTotal: number;
  isDeleting: boolean;

  constructor(
    public dialog: MatDialog,
    private calendarService: CalendarService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getUpcomingEvents();
    this.getPastEvents();
    this.getFutureEvents();
  }

  getEvents() {
    this.calendarService.eventsList('false')
      .subscribe((r: any) => {
        this.events = r;
        this.eventsTotal = r.total;
      });
  }

  getUpcomingEvents() {
    this.calendarService.upcomingEventsList('true', 'false')
      .subscribe((r: any) => {
        this.upcomingEvents = r;
        this.upcomingEventsTotal = r.total;
      });
  }

  getPastEvents() {
    this.calendarService.pastEventsList('true', 'false')
      .subscribe((r: any) => {
        this.pastEvents = r;
        this.pastEventsTotal = r.total;
      });
  }

  getFutureEvents() {
    this.calendarService.futureEventsList('true', 'false')
      .subscribe((r: any) => {
        this.futureEvents = r;
        this.futureEventsTotal = r.total;
      });
  }

  getUnreadEvents() {
    this.calendarService.eventsList('true')
      .subscribe((r: any) => {
        this.unreadEvents = r;
        this.unreadEventsTotal = r.total;
      });
  }

  deleteEventReminder(calendarEventId:number, id:number) {
    this.calendarService.deleteCalendarReminder(calendarEventId, id)
      .subscribe();
  }

  openEditCalendarEventDialog(row?: any, setRead?: boolean): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '700px';
    dialogConfig.data = new CalendarEvent();
    const now = Date.now().toString();
    if(row.id){
      dialogConfig.data = row;
      dialogConfig.data.interval = row.calendarReminders[0].interval;
      dialogConfig.data.interval2 = row.calendarReminders[1] ? row.calendarReminders[1].interval : '';
      dialogConfig.data.unit = row.calendarReminders[0].unit;
      dialogConfig.data.time = moment(row.eventDate).format('HH:mm');

      if(!row.eventRead && setRead) {
        this.calendarService.toggleEventRead(row.id).subscribe(r => {
          dialogConfig.data.eventRead = true;
        });
      }
    }
    dialogConfig.data.user = this.authService.getUser();
    const dialogRef = this.dialog.open(EditNotificationDialogComponent , dialogConfig);
    dialogRef.afterClosed().subscribe(closeNotifications => {
      this.getUpcomingEvents();
      this.getPastEvents();
      this.getFutureEvents();
      if(closeNotifications){
        this.showNotificationsChange.emit(false)
      }
    });
  }

  delete(item) {
    this.isDeleting = true;
    item.calendarReminders.forEach((reminder) => {
      this.deleteEventReminder(item.id, reminder.id);
    });

    this.calendarService.delete(item.id)
      .subscribe((r: any) => {
        this.getUpcomingEvents();
        this.getPastEvents();
        this.getFutureEvents();
        this.showNotificationsChange.emit(true);
        this.isDeleting = false;
      });
  }

  getFormatFromNow(datetime){
    return moment(datetime).fromNow();
  }
}
