import { Component, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Subject, Observable, of } from 'rxjs';

import { AttachmentService, WhiteLabelsService, TenantService, IsoService, SyndicatorService, LawFirmService, ReportService, SearchService } from '@core/services';

import { AuthService } from '@core/authentication/auth.service';

import { Report } from '@core/models';

import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';

import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'oiq-bb-reports',
  templateUrl: './bb-reports.component.html',
  styleUrls: ['./bb-reports.component.scss']
})
export class BBReportsComponent implements OnInit {

  type: string;
  name: string;
  reportId: number;
  report: any;
  form: UntypedFormGroup;
  tenant: any = {};
  isos: Array<any> = [];
  isoUsers: Array<any> = [];
  syndicators: Array<any> = [];
  syndicatorUsers: Array<any> = [];
  lawFirms: Array<any> = [];
  lawFirmUsers: Array<any> = [];
  tenantUsers: Array<any> = [];
  editMode: boolean = true;
  reportGenerated: boolean = false;
  loading: boolean = false;
  selectedWhiteLabels = [];

  interval: any;

  searching = false;

  exclusions = false;

  reportDates = 'fixed';

  selectedIsos = [];
  selectedSyndicators = [];
  selectedLawFirms = [];

  isoManagerList = [];

  @Inject(MAT_DIALOG_DATA) public data: any

  formatter = (x: {name: string}) => x.name;

  @ViewChild('isoInstance') isoInstance: NgbTypeahead;
  @ViewChild('syndicatorInstance') syndicatorInstance: NgbTypeahead;
  @ViewChild('lawFirmInstance') lawFirmInstance: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  rangeUnits = [
    { name: 'Days', value: 'days' },
    { name: 'Weeks', value: 'weeks' },
    { name: 'Months', value: 'months' },
    { name: 'Years', value: 'years' },
  ];

  whiteLabels = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private attachmentService: AttachmentService,
    private tenantService: TenantService,
    private isoService: IsoService,
    private syndicatorService: SyndicatorService,
    private lawFirmService: LawFirmService,
    private authService: AuthService,
    private reportService: ReportService,
    private searchService: SearchService,
    private whiteLabelsService: WhiteLabelsService
  ) {
  }

  ngOnInit() {
    this.type = this.activatedRoute.snapshot.queryParams['type'];
    this.name = this.activatedRoute.snapshot.queryParams['name'];

    this.form = new Report();

    let name;

    this.form.addControl('whiteLabelIds', new UntypedFormControl('', Validators.required));
    this.form.addControl('asOfDate', new UntypedFormControl('', Validators.required));
    if (this.type === 'exhibit') {
      name = 'Borrowing Base Exhibit';
      this.form.addControl('priorReportDate', new UntypedFormControl('', Validators.required));
    } else if (this.type === 'Borrowing_Base_Payments') {
      name = 'Borrowing Base Payments List';
      this.form.removeControl('asOfDate');
      this.form.addControl('startDate', new UntypedFormControl('', Validators.required));
      this.form.addControl('endDate', new UntypedFormControl('', Validators.required));
    } else {
      name = 'Borrowing Base Report';
      this.form.addControl('createExclusions', new UntypedFormControl(false, Validators.required));
    }
    this.form.patchValue({ name });

    this.getTenant();
    this.getTenantUsers();
    this.getWhiteLabels();
  }

  ngOnDestroy() {
    if (this.interval) {
      this.interval.unsubscribe();
    }
  }

  reValidate() {
    if (this.reportDates === 'fixed') {
      switch (this.type) {
        case 'Borrowing_Base_Payments':
          this.form.addControl('startDate', new UntypedFormControl('', Validators.required));
          this.form.addControl('endDate', new UntypedFormControl('', Validators.required));

          this.form.removeControl('rangeUnits');
          this.form.removeControl('range');
          break;

        default:
          break;
      }
    } else {
      switch (this.type) {
        case 'Borrowing_Base_Payments':
          this.form.addControl('rangeUnits', new UntypedFormControl('', Validators.required));
          this.form.addControl('range', new UntypedFormControl('', Validators.required));

          this.form.removeControl('startDate');
          this.form.removeControl('endDate');
          break;

        default:
          break;
      }
    }
  }

  getWhiteLabels() {
    this.whiteLabelsService.list()
    .subscribe((r: any) => {
      this.whiteLabels = r.content;
    });
  }

  toggleExclusions(event) {
    this.form.get('createExclusions').setValue(event.target.checked);
  }

  previewReport() {
    this.editMode = false;
  }

  editReport() {
    this.editMode = true;

    if (this.interval) {
      this.interval.unsubscribe();
    }
  }

  getIsoManagersList() {
    this.tenantUsers.forEach(user => {
      if (user.roles.some(r => r.name === 'ISO MANAGER')) {
        this.isoManagerList.push(user);
      }
    });
  }

  showIsoManager() {
    const selectedIsoManager = this.isoManagerList.filter(user => user.id === this.form.value.funderIsoManagerId);
    return selectedIsoManager[0].firstName + ' ' + selectedIsoManager[0].lastName;
  }

  onSelected(event, type) {

    event.preventDefault();

    switch (type) {
      case 'iso':
        this.selectedIsos.push(event.item.name);

        this.isoInstance.writeValue('');

        this.getIsoUsers(event.item.isoId, event.item.name);

        break;

      case 'syndicator':
        this.selectedSyndicators.push(event.item.name);

        this.syndicatorInstance.writeValue('');

        this.getSyndicatorUsers(event.item.syndicatorId, event.item.name);

        break;

      case 'lawFirm':
        this.selectedLawFirms.push(event.item.name);

        this.lawFirmInstance.writeValue('');

        this.getLawFirmUsers(event.item.id, event.item.name);

        break;

      default:
        break;
    }
  }

  onWhiteLabelSelected(event) {
    this.selectedWhiteLabels = this.whiteLabels.filter(whiteLabel => event.includes(whiteLabel.id)).map(whiteLabel => whiteLabel.name);
  }

  removeIso(iso) {

    this.selectedIsos.splice(this.selectedIsos.indexOf(iso), 1);

    const removedIsoUsersArray = this.isoUsers.filter((user) => user.isoName === iso).map(user => user.id);

    this.isoUsers = this.isoUsers.filter((user) => user.isoName !== iso);

    const selectedUsers = this.form.get('isoUserIdRecipients').value;

    selectedUsers && this.form.get('isoUserIdRecipients').setValue(selectedUsers.filter(value => !removedIsoUsersArray.includes(value)));
  }

  removeSyndicator(item) {

    this.selectedSyndicators.splice(this.selectedSyndicators.indexOf(item), 1);

    const removedSyndicatorsUsersArray = this.syndicatorUsers.filter((user) => user.syndicatorName === item).map(user => user.id);

    this.syndicatorUsers = this.syndicatorUsers.filter((user) => user.syndicatorName !== item);

    const selectedUsers = this.form.get('syndicatorUserIdRecipients').value;

    selectedUsers && this.form.get('syndicatorUserIdRecipients').setValue(selectedUsers.filter(value => !removedSyndicatorsUsersArray.includes(value)));
  }

  removeLawFirm(item) {

    this.selectedLawFirms.splice(this.selectedLawFirms.indexOf(item), 1);

    const removedLawFirmUsersArray = this.lawFirmUsers.filter((user) => user.lawFirmName === item).map(user => user.id);

    this.lawFirmUsers = this.lawFirmUsers.filter((user) => user.lawFirmName !== item);

    const selectedUsers = this.form.get('lawFirmUserIdRecipients').value;

    selectedUsers && this.form.get('lawFirmUserIdRecipients').setValue(selectedUsers.filter(value => !removedLawFirmUsersArray.includes(value)));
  }

  searchIsos = (text$: Observable<string>) => text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term =>
      this.searchService.performIsosTypeahead(term).pipe(
        catchError(() => {
          return of([]);
        }))
    ),
    tap(() => this.searching = false)
  );

  searchSyndicators = (text$: Observable<string>) => text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term =>
      this.searchService.performSyndicatorsTypeahead(term).pipe(
        catchError(() => {
          return of([]);
        }))
    ),
    tap(() => this.searching = false)
  );

  searchLawFirms = (text$: Observable<string>) => text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term =>
      this.searchService.performLawFirmsTypeahead(term).pipe(
        catchError(() => {
          return of([]);
        }))
    ),
    tap(() => this.searching = false)
  );

  getUserRecipients(controlName, rows) {
    if (!this.form.controls[controlName].value || this.form.controls[controlName].value === '') {
      return [];
    }

    const userIds = this.form.controls[controlName].value;

    return rows.filter((row) => {
      for (let i = 0; i < userIds.length; i++) {
        if (userIds[i] === row.id) {
          return true;
        }
      }

      return false;
    });
  }

  getTenant() {
    this.tenantService.getById(this.authService.getUser().tenantId)
      .subscribe((r: any) => {
        this.tenant = r;
      });
  }

  getTenantUsers() {
    this.tenantService.getTenantUsersByIdMinimal(this.authService.getUser().tenantId)
      .subscribe((r: any) => {
        this.tenantUsers = r;
        this.getIsoManagersList();
      });
  }

  getIsoUsers(isoId, isoName) {
    this.isoService.listUsersMinimal(isoId)
      .subscribe((r: any) => {
        r.content.forEach(user => {
          user['isoName'] = isoName;
        });
        this.isoUsers = this.isoUsers.concat(r.content);
      });
  }

  getSyndicatorUsers(syndicatorId, syndicatorName) {
    this.syndicatorService.listUsersMinimal(syndicatorId)
      .subscribe((r: any) => {
        r.content.forEach(user => {
          user['syndicatorName'] = syndicatorName;
        });
        this.syndicatorUsers = this.syndicatorUsers.concat(r.content);
      });
  }

  getLawFirmUsers(lawFirmId, lawFirmName) {
    this.lawFirmService.listUsers(lawFirmId)
      .subscribe((r: any) => {
        r.content.forEach(user => {
          user['lawFirmName'] = lawFirmName;
        });
        this.lawFirmUsers = this.lawFirmUsers.concat(r.content);
      });
  }

  submitReport() {
    let observable;
    const data = this.form.value;

    data.startDate = moment(data.startDate).format('YYYY-MM-DD');
    data.endDate = moment(data.endDate).format('YYYY-MM-DD');

    if (data.asOfDate) {
      if (data.asOfDate === '') {
        data.asOfDate = null;
      } else {
        data.asOfDate = moment(data.asOfDate).format('YYYY-MM-DD');
      }
    }

    if (data.priorReportDate) {
      if (data.priorReportDate === '') {
        data.priorReportDate = null;
      } else {
        data.priorReportDate = moment(data.priorReportDate).format('YYYY-MM-DD');
      }
    }

    if (this.type !== 'exhibit') {
      data.type = this.type;
    }

    data.config = Object.assign({}, data);

    if (this.type === 'exhibit') {
      observable = this.reportService.generateBorrowingBaseExhibitReport(data);
    } else if (this.type === 'Borrowing_Base_Payments') {
      observable = this.reportService.generateBorrowingBasePaymentsReport(data);
    } else {
      observable = this.reportService.generateBorrowingBaseReport(data);
    }

    this.loading = true;

    observable.subscribe((r: any) => {
      this.pollReport(r.id);
    });
  }

  pollReport(id: number) {
    this.interval = this.reportService.poll(id)
      .subscribe((r: any) => {
          if (r.completed) {
            this.loading = false;
            this.editMode = false;
            this.reportGenerated = true;

            this.interval.unsubscribe();

            this.report = r;
          }
        },
        (error) => {

          this.interval.unsubscribe();
        });
  }

  download() {
    this.attachmentService.openInWindowById(this.report.attachmentId);

    return false;
  }
}

