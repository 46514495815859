import { Component, OnInit, Inject } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DefaultPrepays } from '@core/models';

@Component({
  selector: 'oiq-prepayment-dialog',
  templateUrl: './prepayment-dialog.component.html',
  styleUrls: ['./prepayment-dialog.component.scss']
})
export class PrepaymentDialogComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<PrepaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.form = new DefaultPrepays();
    if (this.data?.offer?.customCommissionEnabled) {
      this.form.get('factorRate').setValidators(Validators.compose([Validators.required, Validators.min(1.00), this.buyRateLessThanFactorRateValidator, this.factorRateGreaterThanBuyRateValidator]));
    } else {
      this.form.get('factorRate').setValidators(Validators.compose([Validators.min(1.00), this.buyRateLessThanFactorRateValidator, this.factorRateGreaterThanBuyRateValidator]));
    }
    this.form.updateValueAndValidity();

    let minDateValue;
    if (this.data?.prepayment?.id) {
      this.form.patchValue(this.data.prepayment);

      if (this.data?.prepayments.length > 0) {
        minDateValue = this.data.prepayments[this.data.index - 1] ? this.data.prepayments[this.data.index - 1].endDay + 1 : 1;
      }
      const fundedAmount: number = this.data.prepayment.paybackAmount / this.data.prepayment.factorRate;
      this.form.valueChanges.subscribe((value) => {
        this.data.prepayment.commissionPercent = (value.factorRate - value.buyRate) * 100;
        this.data.prepayment.paybackAmount = fundedAmount * value.factorRate;
        this.data.prepayment.factorRate = value.factorRate;
      });
    } else {
      const lastPrepaymentIndex = this.data?.prepayments.length - 1;
      minDateValue = this.data?.prepayments[lastPrepaymentIndex] ? this.data.prepayments[lastPrepaymentIndex].endDay + 1 : 1;
      this.form.controls['startDay'].setValue(minDateValue);
    }

    this.form.controls['startDay'].addValidators([Validators.min(minDateValue), Validators.max(minDateValue)]);
    this.form.controls['endDay'].addValidators(Validators.min(minDateValue + 1));
  }

  buyRateLessThanFactorRateValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }
    const factorRate = formControl.parent.get('factorRate').value;
    const buyRate = formControl.parent.get('buyRate').value;
    if (factorRate && buyRate) {
      if (buyRate > factorRate) {
        return { buyRateGreaterThanFactorRateError: true };
      }
      formControl.parent.get('factorRate').setErrors(null);
      return null;
    }
    return null;
  }

   factorRateGreaterThanBuyRateValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }
    const factorRate = formControl.parent.get('factorRate').value;
    const buyRate = formControl.parent.get('buyRate').value;
    if (factorRate && buyRate) {
      if (factorRate < buyRate) {
        return { factorRateLessThanBuyRateError: true };
      }
      formControl.parent.get('buyRate').setErrors(null);
      return null;
    }
    return null;
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

}
