import { UntypedFormControl, UntypedFormGroup, FormArray, Validators } from '@angular/forms';

export class ESignatureTemplate extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(),
      templateSource: new UntypedFormControl('upload'),
      name: new UntypedFormControl('', Validators.required),
      code: new UntypedFormControl(''),
      type: new UntypedFormControl('DocuSign', Validators.required),
      templateId: new UntypedFormControl(''),
      attachmentId: new UntypedFormControl(''),
      documentId: new UntypedFormControl('1', Validators.required),
      brandId: new UntypedFormControl(''),
    });
  }
}


export enum TENANT_ID {
	WallFunding = 1,
	AmeriquestFundingLLC = 2,
	Throttle = 3,
	TestTenantName = 4,
	PropellaCapital = 5,
	ThreeSCapitalGroup = 6,
	Demo = 7,
	Marketing = 8,
	Torro = 9,
	MeritBusinessFunding = 10,
	SeamlessCapitalGroup = 11,
	SimplyFunding = 12,
	FlexibilityCapital = 13,
	PurpleTreeFunding = 14,
	Capify = 15,
	PremiumCapitalFund = 16,
	NorthQuestCapitalLLC = 17,
	Instafunding = 18,
	Blackbridge = 19,
	MNYCapital = 20,
	SquareFundingLLC = 21,
	RaptorAdvance = 22,
	WideIndustrySolutionsInc = 23,
	ProVentrueCapital = 24,
	MilstoneFundingInc = 25,
	SwiftFundingSource = 26,
	RogueCapital = 28,
	TSGFunding = 29,
	KickAdvance = 30,
	LoopOneFunding = 31,
  TvtCapital = 32,
  SprintFounder = 33,
  CapitalInfusion = 34,
  Trifecta = 35,
  LavoieCapitalLLC = 36,
  OneStopCap = 37,
}
