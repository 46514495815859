import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NotificationTemplateService } from '@core/services';

import { AuthService } from '@core/authentication/auth.service';

@Component({
  selector: 'oiq-edit-template-dialog',
  templateUrl: './edit-template-dialog.component.html',
  styleUrls: ['./edit-template-dialog.component.scss']
})
export class EditTemplateDialogComponent implements OnInit {

  form: UntypedFormGroup;
  showTemplates: boolean = false;

  template: any;
  templates: Array<any> = [];

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private notificationTemplateService: NotificationTemplateService,
    public dialogRef: MatDialogRef<EditTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {

    this.form = this.fb.group({
      id: [],
      type: ['', Validators.required],
      medium: ['', Validators.required],
      name: ['', Validators.required],
      from: [''],
      subject: [''],
      body: ['', Validators.required],
      tenantId: ['']
    });

    if (this.data.medium === 'Email') {
      this.form.controls['subject'].setValidators([Validators.required]);
      this.form.addControl('useMasterTemplate', new UntypedFormControl(true, Validators.required));
    }

    if (this.data.body) {
      this.form.patchValue(this.data);
    } else {
      this.showTemplates;

      this.form.patchValue({
        type: this.data.type,
        medium: this.data.medium,
        tenantId: this.authService.getUser().tenantId
      });

      this.getTemplatesByTypeAndMedium(this.data.type, this.data.medium);
    }

    if (this.data.body && !this.data.tenantId) {
      this.form.get('type').clearValidators();
      this.form.get('name').clearValidators();
    }
  }

  templateChanged() {
    this.template.id = null;
    this.template.tenantId = this.authService.getUser().tenantId;

    this.form.patchValue(this.template);
  }

  save() {
    let observable;

    if (this.form.value.id) {
      observable = this.notificationTemplateService.update(this.form.value.id, this.form.value);
    } else {
      observable = this.notificationTemplateService.create(this.form.value);
    }

    observable.subscribe((r: any) => {
        this.dialogRef.close(true);
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  getTemplatesByTypeAndMedium(type: string, medium: string) {
    this.notificationTemplateService.listByTypeAndMedium(type, medium)
      .subscribe((r: any) => {
        this.templates = r;
      });
  }

}
