import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddSyndicationsComponent } from '../add-syndications/add-syndications.component';
import { UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { ApplicationService } from '@core/services';
import { notEmptyFormArrayValidator } from '@shared/utils-custom-form-validators';

@Component({
  selector: 'oiq-add-syndications-dialog',
  templateUrl: './add-syndications-dialog.component.html',
  styleUrls: ['./add-syndications-dialog.component.scss']
})
export class AddSyndicationsDialogComponent implements OnInit {

  form = new UntypedFormGroup({syndicationForms: new UntypedFormArray([], notEmptyFormArrayValidator())});

  constructor(
    public dialogRef: MatDialogRef<AddSyndicationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private applicationService: ApplicationService) { }

  ngOnInit() {
  }

  getCommissionPercent(): number {
    if(this.data.customCommissionPercentEnabled) { //if feature is enabled and deal has maximum commission set
      if(this.data.application?.appliedFundingOffer?.maximumCommissionPercent) {
        let defaultCommissionPercent = this.data.application?.appliedFundingOffer?.commissionPercent;
        let maximumCommissionPercent = this.data.application?.appliedFundingOffer?.maximumCommissionPercent;

        if(defaultCommissionPercent < maximumCommissionPercent) {
          return defaultCommissionPercent;
        } else {
          return maximumCommissionPercent;
        }
      } else { //if feature is enabled and deal does not have maximum commission set
        return  this.data.application?.appliedFundingOffer?.commissionPercent;
      }
    } else { //if feature is not enabled
      return this.data.application?.appliedFundingOffer?.commissionPercent;
    }
  }

  save() {
    let payload;
    if (this.data.isEdit && this.form.value.syndicationForms && this.form.value.syndicationForms.length) {
      payload = this.form.value.syndicationForms[0]
      this.applicationService.updateSyndicationOffer(this.data.application.id,payload.id, payload).subscribe(r => this.dialogRef.close(true));
    } else {
      let payload = this.form.value.syndicationForms
      this.applicationService.createBulkSyndications(this.data.application.id, payload).subscribe(r => this.dialogRef.close(true));
    }
  }
}
