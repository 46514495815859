import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, FormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'oiq-stipulation-notes-dialog',
  templateUrl: './stipulation-notes-dialog.component.html',
  styleUrls: ['./stipulation-notes-dialog.component.scss']
})
export class StipulationNotesDialogComponent implements OnInit {

  event: string;
  application: any;
  form: UntypedFormGroup;

  submitEnabled = false;

  values: Array<any> = [];

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<StipulationNotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  cancel(): void {
    this.dialogRef.close();
  }

  toggleLoading(event) {
    if (event) {
      this.submitEnabled = true;
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      notes: ['', Validators.required]
    });
  }

  submit() {
    const notes = this.form.get('notes').value;

    this.dialogRef.close({
      notes
    });
  }
}
