import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ErrorDialogComponent } from '@shared/error-dialog/error-dialog.component';

import { CookieService } from 'ngx-cookie-service';

import { AuthService } from './auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Injectable()
export class AuthGuard implements CanActivate {
  userResources: any[] = [];

  roles: any[] = [
    {
      'name': 'SUPER_ADMIN',
      'access': [
        'home',
        'login',
        'admin',
        'tenants',
        'settings',
        'templates',
        'template',
        'moneythumb-columns'
      ],
      'resources': this.userResources
    },
    // tslint:disable-next-line:max-line-length
    {
      'name': 'OPERATIONS',
      'access': [
        'login',
        'clients',
        'client',
        'isos',
        'application',
        'applications',
        'contract',
        'new-application',
        'fundings',
        'funding',
        'contacts',
        'contact',
        'templates',
        'template',
        'search-results',
        'contract-renewal',
        'accounting-exports',
        'reports',
        'awaiting-contract-queue',
        'pre-underwriting-queue',
        'pre-underwriting'
      ],
      'resources': this.userResources
    },
    {
      'name': 'PRE UNDERWRITING',
      'access': [
        'login',
        'clients',
        'client',
        'isos',
        'application',
        'applications',
        'new-application',
        'fundings',
        'funding',
        'contacts',
        'contact',
        'templates',
        'template',
        'search-results',
        'contract-renewal',
        'accounting-exports',
        'reports',
        'awaiting-contract-queue',
        'pre-underwriting-queue',
        'pre-underwriting'
      ],
      'resources': this.userResources
    },
    {
      'name': 'UNDERWRITING',
      'access': [
        'login',
        'clients',
        'client',
        'application',
        'applications',
        'contract',
        'fundings',
        'funding',
        'contacts',
        'contact',
        'templates',
        'template',
        'search-results',
        'contract-renewal',
        'accounting-exports',
        'reports',
        'pre-underwriting-queue',
      ],
      'resources': this.userResources
    },
    {
      'name': 'COLLECTIONS',
      'access': [
        'login',
        'clients',
        'client',
        'application',
        'applications',
        'contract',
        'new-application',
        'fundings',
        'funding',
        'contacts',
        'contact',
        'templates',
        'template',
        'search-results',
        'contract-renewal',
        'accounting-exports',
        'reports',
        'isos'
      ],
      'resources': this.userResources
    },
    {
      'name': 'ACCOUNTING',
      'access': [
        'login',
        'clients',
        'applications',
        'contract',
        'fundings',
        'search-results',
        'accounting-exports',
        'reports'
      ],
      'resources': this.userResources
    },
    {
      'name': 'ISO MANAGER',
      'access': [
        'login',
        'clients',
        'applications',
        'contract',
        'fundings',
        'search-results'
      ],
      'resources': this.userResources
    },
    {
      'name': 'SYNDICATOR',
      'access': [
        'login',
        'search-results',
        // 'syndicators',
        'reports'
      ],
      'resources': this.userResources
    },
    {
      'name': 'ADMIN',
      'access': [
        'login',
        'search-results',
        'applications',
        'contract',
        'new-application',
        'clients',
        'fundings',
        'syndicators',
        'isos',
        'iso',
        'law-firms',
        'law-firm',
        'reports',
        'settings',
        'users',
        'roles',
        'general-info',
        'general-settings',
        'financial',
        'bb-settings',
        'ocr',
        'stipulations',
        'events',
        'notifications',
        'lookup-lists',
        'tenant-notifications',
        'public-api',
        'white-labels',
        'final-approval-queue',
        'awaiting-contract-queue',
        'pre-underwriting-queue',
        'pre-underwriting',
        'fundings-list-settings'
      ],
      'resources': this.userResources
    },
    {
      'name': 'MANAGER',
      'access': [
        'login',
        'search-results',
        'applications',
        'contract',
        'new-application',
        'clients',
        'fundings',
        'syndicators',
        'isos',
        'iso',
        'law-firms',
        'law-firm',
        'final-approval-queue', 
        'awaiting-contract-queue',
        'pre-underwriting-queue',
        'pre-underwriting'
      ],
      'resources': this.userResources
    },
    {
      'name': 'ONYXIQ_ADMIN',
      'access': [
        'login',
        'search-results',
        'applications',
        'contract',
        'new-application',
        'clients',
        'fundings',
        'syndicators',
        'isos',
        'iso',
        'law-firms',
        'law-firm',
        'reports',
        'settings',
        'users',
        'roles',
        'general-info',
        'general-settings',
        'financial',
        'bb-settings',
        'ocr',
        'stipulations',
        'events',
        'notifications',
        'lookup-lists',
        'tenant-notifications',
        'public-api',
        'white-labels',
        'final-approval-queue',
        'awaiting-contract-queue',
        'pre-underwriting-queue',
        'pre-underwriting',
        'fundings-list-settings'
      ],
      'resources': this.userResources
    },
    {
      'name': 'WHITE_LABEL_ADMIN',
      'access': [
        'login',
        'search-results',
        'applications',
        'contract',
        'new-application',
        'clients',
        'fundings',
        'syndicators',
        'isos',
        'iso',
        'law-firms',
        'law-firm',
        'reports',
        'settings',
        'users',
        'final-approval-queue',
        'awaiting-contract-queue',
        'pre-underwriting-queue',
        'pre-underwriting'
      ],
      'resources': this.userResources
    },
    {
      'name': 'FULL',
      'access': [
        'login',
        'search-results',
        'applications',
        'contract',
        'new-application',
        'clients',
        'fundings',
        'syndicators',
        'isos',
        'iso',
        'law-firms',
        'law-firm',
        'reports',
        'awaiting-contract-queue',
        'pre-underwriting-queue',
        'pre-underwriting'
      ],
      'resources': this.userResources
    },
    {
      'name': 'USER',
      'access': [],
      'resources': this.userResources
    }
  ];
  constructor(
    private authService: AuthService,
    private router: Router,
    private cookieService: CookieService,
    public dialog: MatDialog
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn.pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (!this.cookieService.get('currentUser') || !isLoggedIn) {
          localStorage.removeItem('token');
          this.router.navigate(
            ['/login'],
            {
              queryParams:
                { returnUrl: state.url, returnParams: JSON.stringify(next.queryParams) }
            }
          );
          return false;
        } else if (this.cookieService.get('currentUser')) {
          const assignedRoles = this.authService.getUser().roles;
          const activatedRoute = next.routeConfig.path;
          const userResources = this.authService.getUser().resources ? this.authService.getUser().resources.map(r => r.resource) : [];
          for (const assignedRole of assignedRoles) {
            for (const role of this.roles) {
              if (assignedRole.name === role.name) {
                if (role.access.includes(activatedRoute) || activatedRoute === '') {
                  return true;
                } else {
                  if (userResources.includes(activatedRoute)) {
                    return true;
                  } else {
                    return this.denyAccess();
                  }
                }
              }
            }
          }
        } else {
          return this.denyAccess();
        }
      })
    );
  }

  denyAccess(): boolean {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.data = 'You have no permission to access this page. Please contact your administrator.';
    this.dialog.open(ErrorDialogComponent, dialogConfig);
    return false;
  }
}
