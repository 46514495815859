import { UntypedFormControl, UntypedFormGroup, UntypedFormArray } from '@angular/forms';

export class WhiteLabel extends UntypedFormGroup {
  constructor(name?: string, id?: number) {
    super({
      whiteLabelId: new UntypedFormControl(id),
      whiteLabelName: new UntypedFormControl(name),
      reasons: new UntypedFormArray([])
    });
  }
}

export class Reason extends UntypedFormGroup {
  constructor(name?: string) {
    super({
      name: new UntypedFormControl(name),
      methods: new UntypedFormArray([])
    });
  }
}

export class Method extends UntypedFormGroup {
  constructor(name?: string) {
    super({
      name: new UntypedFormControl(name),
      processors: new UntypedFormArray([])
    });
  }
}

export class Processor extends UntypedFormGroup {
  constructor(name?: string) {
    super({
      name: new UntypedFormControl(name),
      sameDayEnabled: new UntypedFormControl(),
      sameDayCutoffTime: new UntypedFormControl(),
      daysToSettlement: new UntypedFormControl(),
      accountName: new UntypedFormControl(),
      defaultAccount: new UntypedFormControl(false),
      fields: new UntypedFormGroup({
      })
    })
  }
}
