import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export class Attachment extends UntypedFormGroup {
  constructor(id?: number, type?: string, filename?: string, size?: number, storageKey?: string, location?: string, source?: string) {
    super({
      id: new UntypedFormControl(id),
      type: new UntypedFormControl(type),
      filename: new UntypedFormControl(filename),
      size: new UntypedFormControl(size),
      storageKey: new UntypedFormControl(storageKey),
      location: new UntypedFormControl(location),
      source: new UntypedFormControl(source)
    });
  }
}
