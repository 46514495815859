import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class LawFirmService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'law-firms/';
  }

  assignLawFirmWhiteLabels(data: any, id?: number) {
    return this.http.put(this.getUrl() + `${id}/whitelabels`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  createLawFirmUser(data: any, id?: number) {
    return this.http.post(this.getUrl() + `${id}/users`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateLawFirmUser(data: any, lawFirmId: number, userId: number) {
    return this.http.put(this.getUrl() + `${lawFirmId}/users/${userId}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  assignLawFirmUserWhiteLabels(data: any, lawFirmId?: number, userId?: number, ): Observable<any> {
    return this.http.put(this.getUrl() + `${lawFirmId}/users/${userId}/whitelabels`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getWhiteLabels(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/whitelabels`)
      .pipe(map(res => {
        return res;
      }));
  }

  listUsers(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/users`)
      .pipe(map(res => {
        return res;
      }));
  }

  addUser(id: number, userId: number): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/users/${userId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  deleteUser(id: number, userId: number): Observable<any> {
    return this.http.delete(this.getUrl() + `${id}/users/${userId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getSettings(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/settings`)
      .pipe(map(res => {
        return res;
      }));
  }

  updateSettings(id: number, data: any): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/settings`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getLawFirmWhiteLabelNames(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/whitelabelnames`)
      .pipe(map(res => {
        return res;
      }));
  }
}
