import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { TransmissionService } from '@core/services';

@Component({
  selector: 'oiq-funding-payment-methods',
  templateUrl: './funding-payment-methods.component.html',
  styleUrls: ['./funding-payment-methods.component.scss']
})
export class FundingPaymentMethodsComponent implements OnInit {

	@Input()
	application: any;

	@Input()
  form: UntypedFormGroup;

  @Input()
  layout: string;

  @Input()
  refinances: any[];

  @Output() onPaymentMethodChange: EventEmitter<any> = new EventEmitter<any>();

  fundingBankVisible: boolean = true;
  paymentBankVisible: boolean = true;
  fundingBankDisabled: boolean;
  paymentBankDisabled: boolean;

  fundingMethodsList = [];
  fundingMethods = [];
  fundingProcessors = [];

  repaymentMethodsList = [];
  repaymentMethods = [];
  repaymentProcessors = [];

  selectedFundingProcessor: any;
  selectedRepaymentProcessor: any;

  constructor(
    private transmissionService: TransmissionService
  ) { }

  ngOnInit() {
    if (this.application) {
      this.selectedFundingProcessor = this.application.fundingProcessorAccountName;
      this.selectedRepaymentProcessor = this.application.paymentProcessorAccountName;
    }
    this.transmissionService.getProcessorsWithMultipleAccounts(this.application.whiteLabelId, 'Tenant_Funding_Client')
      .subscribe((r: any) => {
        this.fundingMethods = r;
        this.setFundingMethodsList();

        if (this.application && this.application.fundingMethod) {
          this.setFundingMethodProcessors(this.application.fundingMethod);
        }
      });

    this.transmissionService.getProcessorsWithMultipleAccounts(this.application.whiteLabelId, 'Client_Repayment')
      .subscribe((r: any) => {
        this.repaymentMethods = r;
        this.setRepaymentMethodsList();

        if (this.application && this.application.paymentMethod) {
          this.setRepaymentMethodProcessors(this.application.paymentMethod);
        }
      });

  	this.form.get('fundingMethod').valueChanges.subscribe(val => {
      if (val === 'ACH') {
        this.fundingBankVisible = true;

        this.form.get('fundingBankId').setValidators([Validators.required]);
      } else {
        this.fundingBankVisible = false;

        this.form.get('fundingBankId').clearValidators();
      }

      this.form.get('fundingBankId').updateValueAndValidity();
    });

    this.form.get('paymentMethod').valueChanges.subscribe(val => {
      if (val === 'ACH' || val === 'Split_Lockbox') {
        this.paymentBankVisible = true;

        this.form.get('paymentBankId').setValidators([Validators.required]);
      } else {
        this.paymentBankVisible = false;

        this.form.get('paymentBankId').clearValidators();
      }

      this.form.get('paymentBankId').updateValueAndValidity();
    });

    if (this.form.value.fundingMethod === 'ACH') {
    	this.fundingBankVisible = true;
  	} else {
  		this.fundingBankVisible = false;
  	}

  	if (this.form.value.paymentMethod === 'ACH' || this.form.value.paymentMethod === 'Split_Lockbox') {
    	this.paymentBankVisible = true;
  	} else {
  		this.paymentBankVisible = false;
  	}
  }

  mapFundingProcessors() {
    if (this.application && this.fundingProcessors) {
      let matchedFundingProcessor = false;

      if (this.application.fundingProcessorAccountName) {
        const matchingAccountProcessor = this.fundingProcessors.find(
          processor => processor.processorAccountName === this.application.fundingProcessorAccountName
        );
        if (matchingAccountProcessor) {
          this.form.controls['fundingProcessor'].setValue(matchingAccountProcessor);
          matchedFundingProcessor = true;
        }
      }

      if (!matchedFundingProcessor && this.application.fundingProcessor) {
        const matchingProcessor = this.fundingProcessors.find(
          processor => processor.processor === this.application.fundingProcessor && processor.automated && processor.defaultProcessor
        );
        if (matchingProcessor) {
          this.form.controls['fundingProcessor'].setValue(matchingProcessor);
          matchedFundingProcessor = true;
        } else {
          const nonAutomatedProcessor = this.fundingProcessors.find(
            processor => processor.processor === this.application.fundingProcessor && !processor.automated
          );
          if (nonAutomatedProcessor) {
            this.form.controls['fundingProcessor'].setValue(nonAutomatedProcessor);
            matchedFundingProcessor = true;
          }
        }
      }
    }
  }

  mapRepaymentProcessors() {
    if (this.application && this.repaymentProcessors) {
      let  matchedRepaymentProcessor = false;

      if (this.application.paymentProcessorAccountName) {
        const matchingAccountProcessor = this.repaymentProcessors.find(
          processor => processor.processorAccountName === this.application.paymentProcessorAccountName
        );
        if (matchingAccountProcessor) {
          this.form.controls['paymentProcessor'].setValue(matchingAccountProcessor);
          matchedRepaymentProcessor = true;
        }
      }

      if (!matchedRepaymentProcessor && this.application.paymentProcessor) {
        const matchingProcessor = this.repaymentProcessors.find(
          processor => processor.processor === this.application.paymentProcessor && processor.automated && processor.defaultProcessor
        );
        if (matchingProcessor) {
          this.form.controls['paymentProcessor'].setValue(matchingProcessor);
          matchedRepaymentProcessor= true;
        } else {
          const nonAutomatedProcessor = this.repaymentProcessors.find(
            processor => processor.processor === this.application.paymentProcessor && !processor.automated
          );
          if (nonAutomatedProcessor) {
            this.form.controls['paymentProcessor'].setValue(nonAutomatedProcessor);
            matchedRepaymentProcessor = true;
          }
        }
      }
    }
  }

  showNonBalanceFees(fees) {
    return fees.filter(fee => !fee.includeInBalance);
  }

  calculateFees(fees) {
    return fees.filter(fee => !fee.includeInBalance && !fee.recurring).reduce((a, b) => +a + +b.amount, 0);
  }

  onFundingMethodChanged(event) {
    this.setFundingMethodProcessors(event.value);

    if (this.fundingProcessors && this.fundingProcessors.length > 0) {
      this.form.get('fundingProcessor').setValidators([Validators.required]);

      this.form.patchValue({
        fundingProcessor: ''
      });
    } else {
      this.form.get('fundingProcessor').clearValidators();

      // used until BE removes hardcoded 'Manual' value for manual processors
      this.form.patchValue({
        fundingProcessor: 'Manual'
      });
    }

    this.form.get('fundingProcessor').updateValueAndValidity();
  }

  onPaymentMethodChanged(event) {
    this.onPaymentMethodChange.emit({
      paymentMethod: event.value
    });
    this.setRepaymentMethodProcessors(event.value);

    if (this.repaymentProcessors && this.repaymentProcessors.length > 0) {
      this.form.get('paymentProcessor').setValidators([Validators.required]);

      this.form.patchValue({
        paymentProcessor: '',
      });
    } else {
      this.form.get('paymentProcessor').clearValidators();

      // used until BE removes hardcoded 'Manual' value for manual processors
      this.form.patchValue({
        paymentProcessor: 'Manual'
      });
    }

    this.form.get('paymentProcessor').updateValueAndValidity();
  }

  onFundingBankSelected(event) {
    this.form.get('fundingBankId').setValue(event);
  }

  onPaymentBankSelected(event) {
    this.form.get('paymentBankId').setValue(event);
  }

  transformLetters(string) {
    if (string) {
      return string.toLowerCase();
    }
  }

  setFundingMethodProcessors(methodName: string) {
    this.fundingProcessors = this.fundingMethods.filter((method: any) => method.name === methodName)[0].processors;
    this.mapFundingProcessors();
  }

  setFundingMethodsList() {
     this.fundingMethods.map((obj:any) => {
      this.fundingMethodsList.push({
        name: obj.name.replace('_', ' '),
        value: obj.name
      });
    });
  }

  selectFundingProcessor(event, processorName) {
    if (event.isUserInput) {
      this.selectedFundingProcessor = processorName;
    }
  }

  selectPaymentProcessor(event, processorName) {
    if (event.isUserInput) {
      this.selectedRepaymentProcessor = processorName;
    }
  }

  setRepaymentMethodProcessors(methodName: string) {
    this.repaymentProcessors = this.repaymentMethods.filter((method: any) => method.name === methodName)[0].processors;
    this.mapRepaymentProcessors();
  }

  setRepaymentMethodsList() {
     this.repaymentMethods.map((obj:any) => {
      this.repaymentMethodsList.push({
        name: obj.name.replace('_', ' '),
        value: obj.name
      });
    });
  }

}
