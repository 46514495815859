<table *ngIf="changedProperties && changedProperties.length > 0" class="diff-table" mat-table matSort
  [dataSource]="changesDataSource" [trackBy]="trackByProperty" multiTemplateDataRows>

  <ng-container matColumnDef="label">
    <th mat-header-cell *matHeaderCellDef>Property Name</th>
    <td mat-cell *matCellDef="let change"> {{change.label}} </td>
  </ng-container>

  <ng-container matColumnDef="removedValueIndicator">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let change">
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#9e1210">
        <path d="M200-440v-80h560v80H200Z" />
      </svg>
    </td>
  </ng-container>

  <ng-container matColumnDef="oldValue">
    <th mat-header-cell *matHeaderCellDef>Old Value</th>
    <td mat-cell *matCellDef="let change" class="p-3"> {{ change.oldValue ?? 'N/A' }}    </td>
  </ng-container>

  <ng-container matColumnDef="editedValueIndicator">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let change">
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ea8730">
        <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
      </svg>
    </td>
  </ng-container>

  <ng-container matColumnDef="addedValueIndicator">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let change">
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#177d48">
        <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
      </svg>
    </td>
  </ng-container>

  <ng-container matColumnDef="newValue">
    <th mat-header-cell *matHeaderCellDef>New Value</th>
    <td mat-cell *matCellDef="let change" class="p-3">
      {{ change.newValue ?? 'N/A' }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let change; columns: columns;" class="element-row"></tr>
</table>
<div class="empty-table" *ngIf="changedProperties && !changedProperties.length">
  No records found.
</div>
