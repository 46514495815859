import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

export class Offer extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(),
      fundedAmount: new UntypedFormControl(),
      buyRate: new UntypedFormControl(),
      factorRate: new UntypedFormControl(),
      termLength: new UntypedFormControl(),
      commissionAmount: new UntypedFormControl(0, Validators.compose([Validators.min(0), Validators.required])),
      commissionPercent: new UntypedFormControl(),
      paybackAmount: new UntypedFormControl(),
      paymentAmount: new UntypedFormControl(),
      paymentBreakdown: new UntypedFormControl(),
      paymentFrequency: new UntypedFormControl('Daily'),
      percentOfGross: new UntypedFormControl(),
      position: new UntypedFormControl('', Validators.required),
      totalPayments: new UntypedFormControl(),
      updatedField: new UntypedFormControl(),
      sendOffer: new UntypedFormControl(),
      transmissionMethod: new UntypedFormControl('ACH'),
      splitPercent: new UntypedFormControl(''),
      maximumCommissionPercent: new UntypedFormControl(),
      customCommissionEnabled: new UntypedFormControl(false)
    }, { updateOn: 'blur' });
  }
}
