import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClearService } from '@core/services';

@Component({
  selector: 'app-docket-dialog',
  templateUrl: './docket-dialog.component.html',
  styleUrls: ['./docket-dialog.component.scss']
})
export class DocketDialogComponent implements OnInit {
  docketHtml: string = '';
  loading: boolean = true;

  constructor(
    private clearService: ClearService,
    public dialogRef: MatDialogRef<DocketDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { reportId: number, docketNumber: string }
  ) {}

  ngOnInit() {
    this.clearService.getDocketHtml(this.data.reportId, this.data.docketNumber).subscribe({
      next: (html) => {
        if (html) {
          this.docketHtml = html.replace(/<title>.*?<\/title>/i, '');
        } else {
          this.docketHtml = '<p>No additional details on this </p>';
        }

        this.loading = false;
      },
      error: () => {
        this.docketHtml = '<p>Error loading docket details. Please try again.</p>';
        this.loading = false;
      }
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}