import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthService {

  private loggedIn = new BehaviorSubject<boolean>(this.userAuthorized());
  private loggedInAs = new BehaviorSubject<boolean>(false);
  authUrl = 'authentication/';

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get isMimicUserLoggedIn() {
    return this.loggedInAs.asObservable();
  }

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private http: HttpClient
  ) { }

  login(user: any, returnUrl?: string): Observable<any> {
    const data = { 'email': user.userName, 'password': user.password };
    return this.http.post(`${this.authUrl}login`, data)
      .pipe(map(res => {

      const date = new Date(Date.now() + 1800 * 1000);
      const expireDate = date.toUTCString();

        const userCookieObject = {
          'name': res['firstName'] + ' ' + res['lastName'],
          'email': res['email'],
          'roles': res['roles'],
          'resources': res['resources'],
          'tenantIds': res['tenantIds'],
          'isoIds': res['isoIds'],
          'syndicatorIds': res['syndicatorIds'],
          'lawFirmIds': res['lawFirmIds'],
          'id': res['id'],
          'tenantId': res['tenantId'],
          'expiryTime': expireDate
        };
        document.cookie = 'currentUser=' + JSON.stringify(userCookieObject) + '; expires = ' + expireDate + '; path=/';

        this.loggedIn.next(true);
        const path = returnUrl ? returnUrl : this.mapRoutes();
        this.router.navigateByUrl(path);
        return res;
      }));
  }

  loginWithOtp(data: any, returnUrl?: string): Observable<any> {
    return this.http.post(`${this.authUrl}login-with-otp`, data)
    .pipe(map(res => {

      const date = new Date(Date.now() + 1800 * 1000);
      const expireDate = date.toUTCString();

        const userCookieObject = {
          'name': res['firstName'] + ' ' + res['lastName'],
          'email': res['email'],
          'roles': res['roles'],
          'resources': res['resources'],
          'tenantIds': res['tenantIds'],
          'isoIds': res['isoIds'],
          'syndicatorIds': res['syndicatorIds'],
          'lawFirmIds': res['lawFirmIds'],
          'id': res['id'],
          'tenantId': res['tenantId'],
          'expiryTime': expireDate
        };

        document.cookie = 'currentUser=' + JSON.stringify(userCookieObject) + '; expires = ' + expireDate + '; path=/';

        this.loggedIn.next(true);
        const path = returnUrl ? returnUrl : this.mapRoutes();
        this.router.navigateByUrl(path);
        return res;
      }));
  }

  getUser(): any {
    return this.cookieService.get('currentUser') ?
    JSON.parse(this.cookieService.get('currentUser')) :
    false;
  }

  loginAs(userId: number) {
    const data = { 'userId': userId };
    return this.http.post(this.authUrl + 'login-as', data)
      .pipe(map(res => {
        return res;
      }));
  }

  loginAsSet(bool: boolean) {
    this.loggedInAs.next(bool);
  }

  logoutAs() {
    return this.http.post(this.authUrl + 'logout-as', {})
      .pipe(map(res => {
        return res;
      }));
  }

  handleMimicUser(loggedInAs: boolean) {
    this.loggedInAs.next(loggedInAs);
  }

  public returnRolesArrayObs() {
    const rolesArray = this.getUser() && from([this.getUser().roles.map(role => role.name)]);
    return rolesArray;
  }

  public returnRolesArray() {
    const rolesArray = this.getUser() && [this.getUser().roles.map(role => role.name)];
    return rolesArray;
  }

  public hasRole(name: string) {
    return this.getUser() && this.getUser().roles.map(role => role.name).indexOf(name) > -1;
  }

  public getUserName() {
    return this.getUser().name;
  }

  public getUserId() {
    return this.getUser().id;
  }

  createToken(token) {
    localStorage.setItem('token', token);
  }

  public getToken(): string {
    return localStorage.getItem('token');
  }
  public userAuthorized(): boolean {
    const token = this.getToken();
    return !!token && !!this.cookieService.get('currentUser');
  }

  logout(): Observable<any> {
    return this.http.post(this.authUrl + 'logout', {})
      .pipe(map(res => {
        this.loggedIn.next(false);
        return res;
      }),
      catchError(err => {
        this.loggedIn.next(false);
        const error = err.error || err.statusText;
        return throwError(error);
      }))
  }

  requestPasswordReset(data: any): Observable<any> {
    return this.http.post(this.authUrl + 'resetpassword/request', data)
      .pipe(map(res => {
        return res;
      }));
  }

  verifyPasswordResetToken(token: string): Observable<any> {
    const data = { 'token': token };
    return this.http.post(this.authUrl + 'resetpassword/verify', data)
      .pipe(map(res => {
        return res;
      }));
  }

  submitNewPassword(token: string, password: string): Observable<any> {
    const data = { 'password': password, 'token': token };
    return this.http.post(this.authUrl + 'resetpassword/process', data)
      .pipe(map(res => {
        return res;
      }));
  }

  killSession() {
    document.cookie.split(';').forEach(
      function (c) {
        document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
      }
    );

    sessionStorage.removeItem('clearTypeGenerated');
    sessionStorage.removeItem('dlReportGenerated');
    sessionStorage.removeItem('experianGenerated');

    localStorage.removeItem('token');
    localStorage.removeItem('masterUser');

    this.loginAsSet(false);
  }

  mapRoutes() {
    let path ='';
    const userRoles = this.getUser() && this.getUser().roles.map(role => role.name);
    userRoles.forEach(role => {
      switch (role) {
        case 'SUPER_ADMIN':
          path = '/admin';
          break;
        case 'SYNDICATOR':
          path = '/';
          break;
        case 'OPERATIONS':
          path = '/';
          break;
        case 'UNDERWRITING':
          path = '/';
          break;
        case 'ISO':
          path = '/';
          break;
        case 'USER':
          path = this.getUser().resources[0].resource;
          break
        case 'MANAGER':
          path = '/applications'
          break;
        default:
          path = '/';
          break;
      }
    });
    return path;
  }

  extendCookie() {
    if (this.cookieService.get('currentUser')) {
      let user = this.cookieService.get('currentUser');
      const date = new Date(Date.now() + 1800 * 1000);
      const expireDate = date.toUTCString();
      const parsedUser = JSON.parse(user);
      parsedUser['expiryTime'] = expireDate;
      user = JSON.stringify(parsedUser);
      document.cookie = 'currentUser=' + user + '; expires = ' + expireDate + '; path=/';
    } else {
      this.router.navigate(['/login']);
    }
  }

}
