import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'oiq-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss']
})
export class SearchSelectgComponent implements OnInit {

  @Input() label = 'Select';
  @Input() options = [];
  @Output() selectionChanged = new EventEmitter();

  selectedOptions: string[] = [];
  selectFormControl = new UntypedFormControl();
  filteredOptions: string[];

  constructor() { }

  ngOnInit (): void {
    this.filteredOptions = this.options;
  }

  filterOptions(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredOptions = this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  onSelectionChange(event){
    this.selectionChanged.emit(event.value);
  }
}
