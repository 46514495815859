import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';

import { AuthService } from '@core/authentication/auth.service';
import { CalendarService } from '@core/services';

import { Subscription, interval } from 'rxjs';
import { startWith, switchMap } from "rxjs/operators";
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'oiq-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  userRole: string;
  currentUser: string;
  loggedInAs: boolean;
  showMenu: boolean;
  showNotifications: boolean = false;
  timeInterval: Subscription;
  status: any;
  unreadEvents: number;
  upcomingEvents: number;
  hidden = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private cookieService: CookieService,
    private calendarService: CalendarService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.authService.isMimicUserLoggedIn.subscribe(loggedInAs => this.handleRoles(loggedInAs));

    this.timeInterval = interval(60 * 1000 * 10)
      .pipe(
        startWith(0),
        switchMap(() => this.calendarService.getNotifications('true'))
      ).subscribe(r => {
        this.unreadEvents = r['unread-events'];
        this.upcomingEvents = r['upcoming-events'];
        if ((this.unreadEvents || this.upcomingEvents) > 0) {
          this.hidden = false;
        }
        if (this.upcomingEvents) {
          this.showUpcomingEventsToastrs();
          this.showNotifications = false;
        }
      }, err => console.log('Error reading reminders counter', err));
  }

  showUpcomingEventsToastrs() {
    this.calendarService.upcomingEventsList('true', 'true')
      .subscribe((r: any) => {
        r.rows.forEach(row => {
          this.openToastr(row);
        });
      });
  }

  openToastr(calendarEvent) {
    let message = `<span class="d-none">${calendarEvent.id}</span> ${calendarEvent.title}`;
    let action = (calendarEvent.entityName && calendarEvent.entityId  && calendarEvent.entityType) ? calendarEvent.entityType : 'Calendar';

    const activeToast = this.toastr.show(message, action, {
      disableTimeOut: true,
      enableHtml: true,
      tapToDismiss: false,
      closeButton: true,
      toastClass: 'toast-calendar toast'
    });

    if (activeToast && activeToast.toastRef) {
      activeToast.toastId = calendarEvent.id;
      activeToast.toastRef.componentInstance.calendarEvent = calendarEvent; // use the component instance to send the input parameters
      activeToast.toastRef.componentInstance.showEntityLink = true; // use the component instance to send the input parameters
      activeToast.onHidden.subscribe(x => {
        this.calendarService.getNotifications('true').subscribe(r => {
          this.unreadEvents = r['unread-events'];
          this.upcomingEvents = r['upcoming-events'];
          if ((this.unreadEvents || this.upcomingEvents) > 0) {
            this.hidden = false;
          }
        }, err => console.log('Error reading reminders counter', err));
      });
    }
  }

  ngOnDestroy() {
    this.timeInterval.unsubscribe();
  }

  handleRoles(loggedInAs) {
    this.loggedInAs = loggedInAs;
    if (this.cookieService.get('currentUser') &&
      this.authService.getUser() &&
      this.authService.getUser().roles.filter(role => role.name === 'SUPER_ADMIN').length > 0) {
      this.userRole = 'admin';
    } else {
      this.userRole = 'user';
    }
  }

  userHasAccessToResources(resource) {
    const userResources = this.authService.getUser().resources ? this.authService.getUser().resources.map(r => r.resource) : [];
    return userResources.includes(resource);
  }

  logout() {
    this.authService.logout()
      .subscribe((r: any) => {
        document.cookie.split(';').forEach(
          function (c) {
            document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
          }
        );
        localStorage.removeItem('token');
        localStorage.removeItem('selectedWhiteLabels');
        this.router.navigate(['/login']);
      });
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
    this.showMenu ? document.body.classList.add("mobile-overlay-open") : document.body.classList.remove("mobile-overlay-open");
  }

  closeMenu() {
    if (this.showMenu) {
      this.showMenu = false;
      document.body.classList.remove("mobile-overlay-open");
    }
  }
}
