import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { ContactService, ClientService } from '@core/services';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

import { ExtendedContact, addressValidator } from '@core/models/';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, catchError, switchMap } from 'rxjs/operators';

@Component({
  selector: 'oiq-add-contact-dialog',
  templateUrl: './add-contact-dialog.component.html',
  styleUrls: ['./add-contact-dialog.component.scss']
})
export class AddContactDialogComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private contactService: ContactService,
    private clientService: ClientService,
    public dialogRef: MatDialogRef<AddContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  cancel(): void {
    this.dialogRef.close();
  }

  formatter = (x: {name: string}) => x.name;

  ngOnInit() {
    this.form = new ExtendedContact();
    this.form.get('email').setValidators([this.nullOrValidEmailValidator, Validators.required]);
    this.form.get('ssn').setValidators([Validators.required]);

    this.form.get('addresses')['controls'][0].setValidators([addressValidator]);
    this.form.get('addresses')['controls'][0].get('address1').setValidators([Validators.required]);

  }

  nullOrValidEmailValidator(control: AbstractControl): ValidationErrors {
    if (!control.value || control.value === '') {
      return null;
    }

    return Validators.email(control);
  }

  onSubmit() {
    if (this.form.valid) {
      this.contactService.create(this.form.value).subscribe((r: any) => {
        this.clientService.addContact(this.data, r.id).subscribe((data) => {
          this.clientService.addOwner(this.data, r.id).subscribe((data) => {
            this.dialogRef.close(true);
          });
        });
      });
    }
  }
}
