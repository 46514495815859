import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';

import { Address } from './address';

export class Contact extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(),
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      title: new UntypedFormControl(),
      email: new UntypedFormControl('', { validators: Validators.email, updateOn: 'blur' }),
      cellNumber: new UntypedFormControl(),
      workNumber: new UntypedFormControl(),
      faxNumber: new UntypedFormControl(),
      homeNumber: new UntypedFormControl(),
      // address: new Address(),
      addresses: new UntypedFormArray([new Address()]),
      ssn: new UntypedFormControl(),
      fico: new UntypedFormControl(),
      dob: new UntypedFormControl(),
      percent: new UntypedFormControl(),
      driversLicense: new UntypedFormControl(),
      externalSource: new UntypedFormControl()
    });
  }
}

export class BasicContact extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(),
      firstName: new UntypedFormControl(),
      lastName: new UntypedFormControl(),
      email: new UntypedFormControl('', { validators: Validators.email, updateOn: 'blur' }),
      cellNumber: new UntypedFormControl()
    });
  }
}

export class QuickContact extends UntypedFormGroup {
  constructor() {
    super({
      firstName: new UntypedFormControl(),
      lastName: new UntypedFormControl()
    });
  }
}

export class ExtendedContact extends UntypedFormGroup {
  constructor() {
    super({
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      cellNumber: new UntypedFormControl(),
      email: new UntypedFormControl(),
      ssn: new UntypedFormControl(),
      addresses: new UntypedFormArray([new Address()])
    });
  }
}
