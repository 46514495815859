import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators, FormControl } from '@angular/forms';
import { Address } from './address';

const numberPattern = /^\d+$/;

export class Bank extends UntypedFormGroup {
  constructor(id?: number, name?: string, branch?: string, routingNumber?: string, accountNumber?: string, accountType?: string, dda?: string, bankStatements?: [], attachments?: [], completeBankInfo?: boolean, deletable?: boolean, includeInCalculations?: boolean) {
    super({
      id: new UntypedFormControl(id),
      name: new UntypedFormControl(name, Validators.required),
      branch: new UntypedFormControl(branch),
      routingNumber: new UntypedFormControl(routingNumber, {validators: Validators.compose([Validators.pattern(numberPattern)])}),
      accountNumber: new UntypedFormControl(accountNumber, {validators: Validators.compose([Validators.pattern(numberPattern)])}),
      accountType: new UntypedFormControl(accountType, Validators.required),
      aba: new UntypedFormControl(),
      number: new UntypedFormControl(),
      dda: new UntypedFormControl(),
      // address: new Address(),
      clientId: new UntypedFormControl(),
      applicationId: new UntypedFormControl(),
      bankStatements: new UntypedFormArray(bankStatements ? bankStatements : []),
      attachments: new UntypedFormArray(attachments ? attachments : []),
      completeBankInfo: new UntypedFormControl(completeBankInfo),
      deletable: new UntypedFormControl(deletable),
      includeInCalculations: new FormControl<boolean>(includeInCalculations ?? true )
    });
  }
}

export class BankInfo extends UntypedFormGroup {
  constructor(id?: number, name?: string, routingNumber?: string, accountNumber?: string, accountType?: string, dda?: string, branch?: string, address?: string) {
    super({
      id: new UntypedFormControl(id),
      name: new UntypedFormControl(name, Validators.required),
      routingNumber: new UntypedFormControl(routingNumber, {validators: Validators.compose([Validators.pattern(numberPattern)])}),
      accountNumber: new UntypedFormControl(accountNumber, {validators: Validators.compose([Validators.pattern(numberPattern)])}),
      accountType: new UntypedFormControl(accountType, Validators.required),
      dda: new UntypedFormControl(dda),
      branch: new UntypedFormControl(branch),
      address: new Address(),
    });
  }
}

export class BankStatement extends UntypedFormGroup {
  constructor(id?: number, month?: string, deposits?: number, endBalance?: number, averageLedgers?: number, negativeDays?: number, nsfs?: number, overdrafts?: number, numberDeposits?: number, transfers?: number, loans?: number, adjustedDeposits?: number, year?: number, positionIds?: []) {
    super({
      id: new UntypedFormControl(id),
      month: new UntypedFormControl(month, Validators.required),
      year: new UntypedFormControl(year, Validators.required),
      deposits: new UntypedFormControl(deposits, [Validators.required]),
      endBalance: new UntypedFormControl(endBalance, [Validators.required]),
      averageLedgers: new UntypedFormControl(averageLedgers, [Validators.required]),
      negativeDays: new UntypedFormControl(negativeDays, [Validators.required]),
      nsfs: new UntypedFormControl(nsfs, [Validators.required]),
      overdrafts: new UntypedFormControl(overdrafts, [Validators.required]),
      numberDeposits: new UntypedFormControl(numberDeposits, [Validators.required]),
      transfers: new UntypedFormControl(transfers || 0, [Validators.required]),
      loans: new UntypedFormControl(loans || 0, [Validators.required]),
      adjustedDeposits: new UntypedFormControl(adjustedDeposits, [Validators.required]),
      positionIds: new UntypedFormControl(positionIds),
      deleted: new UntypedFormControl(false)
    });
  }
}
