import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IsoPortal, OfferData, Stipulation } from '../models/iso-portal';

@Injectable({
  providedIn: 'root'
})
export class IsoPortalService {

  constructor(private httpClient: HttpClient) { }

  getIsoPortalData(hash: string): Observable<IsoPortal> {
    return this.httpClient.get<IsoPortal>(`applications/broker-portal`, { params: { hash } });
  }

  updateOffers(hash: string): Observable<IsoPortal> {
    return this.httpClient.put<IsoPortal>(`applications/broker-portal/update`, null, { params: { hash } });
  }

  getStipulations(hash: string): Observable<Stipulation[]> {
    return this.httpClient.get<Stipulation[]>(`applications/broker-portal/stipulations`, { params: { hash } });
  }

  uploadStipulation(hash: string, stipulationId: number, file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.httpClient.post(`applications/broker-portal/upload-stipulations`, data, {
      params: { hash, stipulationId },
    });
  }

  applyOffer(offer: OfferData) {
    return this.httpClient.post(`applications/broker-portal/offer`, offer);
  }
}
