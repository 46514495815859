import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, EMPTY, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IsoPortalService } from '../services/iso-portal.service';
import { IsoPortal } from '../models/iso-portal';
import { QueryParams } from '../models/route';

@Injectable({ providedIn: 'root' })
export class IsoPortalResolver implements Resolve<IsoPortal> {
  constructor(private isoPortalService: IsoPortalService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot & { queryParams: QueryParams }): Observable<IsoPortal> {
    return this.isoPortalService.getIsoPortalData(route.queryParams.hash).pipe(
      catchError((error) => {
        if (error.error.applicationId) {
          return of({ ...error.error, updateNeeded: true });
        }
        this.router.navigate(['/v2/error']);
        return EMPTY;
      })
    );
  }
}
