import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import moment from "moment";

export function firstDateIsAfterSecondDateValidator(firstDateControlName: string, secondDateControlName: string): ValidatorFn {
    return (group: AbstractControl) => {
        const control1 = group.get(firstDateControlName);
        const control2 = group.get(secondDateControlName);

        if (!control1 || !control2) {
            return null;
        }

        if (control2.errors && !control2.errors.firstDateIsAfterSecond) {
            return null;
        }

        if (moment(control1.value).isAfter(moment(control2.value))) {
            control2.setErrors({ firstDateIsAfterSecond: true });
        } else {
            control2.setErrors(null);
        }
        return null;
    }
}

export function notEmptyFormArrayValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const isArrayEmpty = control.value && Array.isArray(control.value) && control.value.length === 0;
        return isArrayEmpty ? { emptyArray: true } : null;
    };
}

export function zeroValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {        
        if (!control) 
            return null;
        const isControlValueZero = control.value === 0;
        return isControlValueZero ? { zeroValue: true } : null; 
    };
}