import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

export class Report extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(),
      name: new UntypedFormControl('', Validators.required),
      emailAddressRecipients: new UntypedFormControl(),
      tenantUserIdRecipients: new UntypedFormControl(),
      isoUserIdRecipients: new UntypedFormControl(),
      syndicatorUserIdRecipients: new UntypedFormControl(),
      lawFirmUserIdRecipients: new UntypedFormControl(),
      sendReport: new UntypedFormControl(),
      reportType: new UntypedFormControl(),
      cron: new Cron()
    });
  }
}

export class Cron extends UntypedFormGroup {
  constructor() {
    super({
      daily: new UntypedFormControl(false),
      weekly: new UntypedFormControl(false),
      monthly: new UntypedFormControl(false),
      lastDayOfMonth: new UntypedFormControl(),
      days: new Days(),
      daysOfMonth: new UntypedFormControl(),
      hours: new UntypedFormControl(),
      minute: new UntypedFormControl()
    });
  }
}


export class Days extends UntypedFormGroup {
  constructor() {
    super({
      monday: new UntypedFormControl(false),
      tuesday: new UntypedFormControl(false),
      wednesday: new UntypedFormControl(false),
      thursday: new UntypedFormControl(false),
      friday: new UntypedFormControl(false),
      saturday: new UntypedFormControl(false),
      sunday: new UntypedFormControl(false)
    });
  }
}
