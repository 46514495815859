import { Component, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

import { AuthService } from '@core/authentication/auth.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from '@core/services//google-analytics.service';
import { HotjarService } from '@core/services/hotjar.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'oiq-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private stylesheet: HTMLLinkElement | null = null;
  private previousHref: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private title: Title,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly hotJarService: HotjarService,
    private renderer: Renderer2
  ) {
    this.isRouterLoading = true;
    const routerSubscription = router.events.pipe(filter(event => event instanceof NavigationEnd));
      routerSubscription.subscribe((event: NavigationEnd) => {
        this.hotJarService.init();

        this.isV2ModuleRoute = event.urlAfterRedirects.includes('v2');

      if (this.isV2ModuleRoute && document.body.classList.contains('onyxiq-theme')) {
        this.switchStylesheets(); // Load V2 stylesheet
        // Add 'onyxiq-theme-v2' when the user navigates to the v2 module
        document.body.classList.add('onyxiq-theme-v2');
        document.body.classList.remove('onyxiq-theme');
      }

      if (!this.isV2ModuleRoute && document.body.classList.contains('onyxiq-theme-v2')) {
        this.switchStylesheets(); // Load Regular App stylesheet
        // Restore original class when leaving the v2 module
        document.body.classList.add('onyxiq-theme');
        document.body.classList.remove('onyxiq-theme-v2');
      }

      this.isRouterLoading = false;
    })
  }

  private switchStylesheets() {
    // Find existing <link rel="stylesheet"> elements
    const existingLinks = Array.from(document.head.querySelectorAll('link[rel="stylesheet"]'));

    let targetPosition: Node | null = null;
    let newHref: string;

    existingLinks.forEach(link => {
      const linkElement = link as HTMLLinkElement;
      if (linkElement.href.includes('styles.') || linkElement.href.includes('styles-v2.')) {
        newHref = this.previousHref ?? 'styles-v2.css';
        this.previousHref = linkElement.href;
        targetPosition = linkElement.nextSibling; // Save the position before removing
        this.renderer.removeChild(document.head, linkElement);
      }
    });

    // Create and append the new stylesheet in the same position
    this.stylesheet = this.renderer.createElement('link');
    this.renderer.setAttribute(this.stylesheet, 'rel', 'stylesheet');
    this.renderer.setAttribute(this.stylesheet, 'href', newHref);

    if (targetPosition) {
      this.renderer.insertBefore(document.head, this.stylesheet, targetPosition);
    } else {
      this.renderer.appendChild(document.head, this.stylesheet);
    }
  }

  production = environment.production;
  staging = environment.staging;
  dev = environment.development;
  isLoggedIn$: Observable<boolean>;
  isMimicUserLoggedIn$: Observable<boolean>;
  mimicUser: any;
  loggedInAs: boolean;
  isV2ModuleRoute = false;
  isRouterLoading: boolean;

  ngOnInit() {
    if (this.production) {
      this.googleAnalyticsService.initialize();
      this.hotJarService.init();
      this.title.setTitle("Onyx IQ");
    }
    else if (this.staging) {
      this.title.setTitle("[STAGING] Onyx IQ");
    }
    else if (this.dev) {
      this.title.setTitle("[DEV] Onyx IQ");
    }
    else {
      this.title.setTitle("[DEV/LOCAL] Onyx IQ");
    }

    if (localStorage.getItem('masterUser')) {
      this.authService.loginAsSet(true);
    } else {
      this.authService.loginAsSet(false);
    }
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.isMimicUserLoggedIn$ = this.authService.isMimicUserLoggedIn;
    this.isMimicUserLoggedIn$.subscribe(loggedInAs => this.handleMimicUser(loggedInAs));

    // this.hotJarService.init();
  }

  handleMimicUser(loggedInAs: boolean) {
    this.loggedInAs = loggedInAs;
    if (loggedInAs) {
      this.mimicUser = {
        name: this.authService.getUserName(),
        title: this.authService.returnRolesArray()
      };
    } else {
      this.mimicUser = {};
    }
  }

  logoutAsUser() {
    this.authService.logoutAs()
      .subscribe((r: any) => {
        this.removeMasterUser();
        const date = new Date(Date.now() + 1800 * 1000);
        const expireDate = date.toUTCString();
        const userCookieObject = {
          'name': r.firstName + ' ' + r.lastName,
          'email': r.email,
          'roles': r.roles,
          'id': r.id,
          'resources': r.resources,
          'tenantId': r.tenantId,
          'expiryTime': expireDate
        };
        document.cookie = 'currentUser=' + JSON.stringify(userCookieObject) + '; expires = ' + expireDate + '; path=/';
        localStorage.setItem('token', r.apiKey);

        if (localStorage.getItem('selectedWhiteLabels')) {
          localStorage.removeItem('selectedWhiteLabels');
        }

        if (localStorage.getItem('redirect')) {
          this.router.navigate(localStorage.getItem('redirect').split('/'));

          localStorage.removeItem('redirect');
        } else {
          this.router.navigate(['/settings/users']);
        }
      },
        null,
        () => {
          this.authService.handleMimicUser(false);
        }
      );
  }

  removeMasterUser() {
    localStorage.removeItem('masterUser');
  }



}
