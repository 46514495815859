<div class="bg-slate-300 h-screen flex flex-col">
  <div class="bg-slate-200 w-full">
    <header class="h-20 flex justify-between items-center xxl:container mx-auto px-5">
      <span>Logo</span>
      <span>DateTime</span>
    </header>
  </div>

  <!-- <div class="flex-1 flex gap-5">
    <nav class="bg-purple-200 w-24">NAV</nav> -->

  <div id="content-wrapper"
    class="h-full bg-red-50 flex-1 flex justify-between overflow-hidden xxl:container xxl:mx-auto">

    <main class="flex-1 flex flex-col gap-5 relative z-10 px-5">

      <div class="bg-slate-400 px-5 flex justify-between items-center">
        <span>PAGE HEADER</span>

        <button class="-mr-10 p-3 bg-red-300 border rounded-lg" (click)="toggleDrawer()">Toggle</button>
      </div>

      <div class="border border-blue-500 flex-1 flex flex-col xl:flex-row gap-5 overflow-hidden">

        <div class="xl:basis-56 border border-red-700 px-5">
          CALCULATOR
        </div>

        <div class="flex-1 border border-teal-300 flex flex-col gap-5 overflow-hidden">

          <div class="bg-green-200 p-3">
            SELECTED DEAL</div>

          <div class="overflow-auto border border-red-500 px-5">
            <table class="table-auto w-full">
              <thead>
                <tr class="sticky top-0 z-1 bg-white">
                  <th>Deal</th>
                  <th>Amount</th>
                  <th>Rate</th>
                  <th>Term</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of [].constructor(10);index as index">
                  <td>Deal {{index + 1}}</td>
                  <td>100000</td>
                  <td>5%</td>
                  <td>5 years</td>
                  <td>01/01/2020</td>
                  <td>01/01/2025</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </main>

    <div class="basis-96 border border-red-500 relative -ml-5"
      [ngClass]="{'block': this.isOpen, 'hidden': !this.isOpen}">

      <div class="flex flex-col h-full">
        <header class="bg-white py-3 px-5">
          <button class="ml-3" (click)="toggleDrawer()">Toggle</button>
        </header>

        <div class="overflow-y-scroll p-5 bg-yellow-100">
          <div *ngFor="let item of [].constructor(100)">Content</div>
        </div>
      </div>

    </div>
  </div>
  <!-- </div> -->
</div>
