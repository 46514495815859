import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators, AbstractControl, ValidationErrors } from '@angular/forms';

import { Address, addressValidator } from './address';
import { Client } from './client';

export class Contract extends UntypedFormGroup {
  constructor() {
    super({
      client: new Client(),
      expectedStartDate: new UntypedFormControl(null, Validators.required),
      paymentFrequency: new UntypedFormControl(null),
      paymentDay: new UntypedFormControl(null),
      paymentDays: new PaymentDays(),
      addressId: new UntypedFormControl(null),
      clientAddress: new Address(),
      owners: new UntypedFormArray([]),
      crossCollateralizations: new UntypedFormArray([]),
      agreementDate: new UntypedFormControl('', Validators.required),
      paymentMethod: new UntypedFormControl('ACH', Validators.required),
      paymentProcessor: new UntypedFormControl(),
      paymentBankId: new UntypedFormControl('', Validators.required),
      paymentBankAddress: new Address()
    });
  }
}

export class PaymentDays extends UntypedFormGroup {
  constructor() {
    super({
      monday: new UntypedFormControl(false),
      tuesday: new UntypedFormControl(false),
      wednesday: new UntypedFormControl(false),
      thursday: new UntypedFormControl(false),
      friday: new UntypedFormControl(false)
    })
  }
}

export class CrossCollateralization extends UntypedFormGroup {
  constructor() {
    super({
      name: new UntypedFormControl('', Validators.required),
      address: new UntypedFormControl(),
      ein: new UntypedFormControl()
    });
  }
}

export class ContractOwner extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(),
      contactId: new UntypedFormControl(),
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', { validators: [Validators.required, Validators.email] }),
      cellNumber: new UntypedFormControl(),
      workNumber: new UntypedFormControl(),
      faxNumber: new UntypedFormControl(),
      homeNumber: new UntypedFormControl(),
      addressId: new UntypedFormControl(),
      address: new Address(),
      addresses: new UntypedFormArray([new Address()]),
      ssn: new UntypedFormControl('', Validators.required),
      fico: new UntypedFormControl(),
      dob: new UntypedFormControl(),
      driversLicense: new UntypedFormControl(),
      title: new UntypedFormControl(),
      include: new UntypedFormControl(false),
      primary: new UntypedFormControl(false),
      editing: new UntypedFormControl(false),
      updateContactRecord: new UntypedFormControl(false)
    });
  }
}

export class ResendContract extends UntypedFormGroup {
  constructor() {
    super({
      cancelExisting: new UntypedFormControl(false, Validators.required),
      signers: new UntypedFormArray([])
    });
  }
}

export function ownerInfoValidator(control: AbstractControl): ValidationErrors {
  const formGroup = control as UntypedFormGroup;

  if (!formGroup.get('firstName').value || formGroup.get('firstName').value === '') {
    return {
      invalidLastName: true
    };
  }

  if (!formGroup.get('lastName').value || formGroup.get('lastName').value === '') {
    return {
      invalidLastName: true
    };
  }

  if (!formGroup.get('email').value || formGroup.get('email').value === '') {
    return {
      invalidEmail: true
    };
  }

  if (!formGroup.get('ssn').value || formGroup.get('ssn').value === '') {
    return {
      invalidSsn: true
    };
  }

  return null;
}
