import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { AddSyndicationOffersComponent } from '../add-syndication-offers/add-syndication-offers.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationService } from '@core/services';
import { notEmptyFormArrayValidator } from '@shared/utils-custom-form-validators';

@Component({
  selector: 'oiq-add-syndication-offers-dialog',
  templateUrl: './add-syndication-offers-dialog.component.html',
  styleUrls: ['./add-syndication-offers-dialog.component.scss']
})
export class AddSyndicationOffersDialogComponent implements OnInit {

  form = new UntypedFormGroup({syndicationOfferForms: new UntypedFormArray([], notEmptyFormArrayValidator())});

  constructor(
    public dialogRef: MatDialogRef<AddSyndicationOffersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private applicationService: ApplicationService
    ) { }

  ngOnInit() {
  }

  getCommissionPercent(): number {
    if(this.data.customCommissionPercentEnabled) { //if feature is enabled and deal has maximum commission set
      if(this.data.application?.appliedFundingOffer?.maximumCommissionPercent) {
        let defaultCommissionPercent = this.data.application?.appliedFundingOffer?.commissionPercent;
        let maximumCommissionPercent = this.data.application?.appliedFundingOffer?.maximumCommissionPercent;
    
        if(defaultCommissionPercent < maximumCommissionPercent) {
          return defaultCommissionPercent;
        } else {
          return maximumCommissionPercent;
        }
      } else { //if feature is enabled and deal does not have maximum commission set
        return  this.data.application?.appliedFundingOffer?.commissionPercent;
      }
    } else { //if feature is not enabled
      return this.data.application?.appliedFundingOffer?.commissionPercent;
    }
  }

  save() {
    let payload;
    if (this.data.isEdit && this.form.value.syndicationOfferForms && this.form.value.syndicationOfferForms.length) {
      payload = this.form.value.syndicationOfferForms[0]
      this.applicationService.updateSyndicationOffer(this.data.application.id, payload.id, payload).subscribe(r => this.dialogRef.close(true));
    } else {
      let payload = this.form.value.syndicationOfferForms
      this.applicationService.createBulkSyndications(this.data.application.id, payload).subscribe(r => this.dialogRef.close(true));
    }
  }
}
