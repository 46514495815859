import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { V2AppComponent } from './app/v2-app.component';
import { IsoPortalResolver } from './iso-portal/resolver/iso-portal.resolver';

const routes: Routes = [
  {
    path: '',
    component: V2AppComponent,
    children: [
      // features related to app modules gonna be lazy loaded here
    ]
  },
  {
    path: 'iso-portal',
    loadChildren: () => import('./iso-portal').then(m => m.IsoPortalModule),
    resolve: {
      isoPortalData: IsoPortalResolver
    }
  },
  {
    path: 'error',
    loadChildren: () => import('./error').then(m => m.ErrorModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class V2RoutingModule { }
