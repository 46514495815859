import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';

import { Address } from './address';
import { Contact } from './contact';

export class Iso extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(),
      name: new UntypedFormControl('', Validators.required),
      cellNumber: new UntypedFormControl(),
      workNumber: new UntypedFormControl(),
      region: new UntypedFormControl(),
      owner: new Contact(),
      contact: new Contact(),
      address: new Address(),
      isoManagerId: new UntypedFormControl(),
      externalSource: new UntypedFormControl(),
    });
  }
}

export class IsoListExport extends UntypedFormGroup {
  constructor() {
    super({
      output: new UntypedFormControl('Excel', Validators.required),
      type: new UntypedFormControl('Standard', Validators.required),
      tenantUserIdRecipients: new UntypedFormControl(),
      isoUserIdRecipients: new UntypedFormControl(),
      isoEmailAddressRecipients: new UntypedFormControl(),
      emailAddressRecipients: new UntypedFormArray([]),
      startDate: new UntypedFormControl(),
      endDate: new UntypedFormControl()
    });
  }
}


export interface ISO {
  id: number;
  amountOwed: number;
  amountPaid: number;
  commissionAmount: number;
  isoCommissionFundings: ISOCommissionFunding[];
  name: string;
  pendingAmountPaid: number;
  tenantId: number;
  transmissionMethod: string;
  total: number;
  completeBankInfo: boolean;
  checked?: boolean;
  indeterminate?: boolean
}

export interface ISOCommissionFunding {
  amountPaid: number;
  bankId: null;
  clientName: string;
  commissionAmount: number;
  commissionPercent: number;
  fundedAmount: number;
  fundingDate: number[];
  fundingId: number;
  isoId: number;
  isoName: string;
  paybackAmount: number;
  pendingAmountPaid: number;
  transmissionMethod: string;
  transmissionProcessor: null;
  checked?: boolean;
}
