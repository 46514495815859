import { UntypedFormControl, UntypedFormGroup, FormArray, Validators } from '@angular/forms';

export class User extends UntypedFormGroup {
  constructor() {
    super({
      id: new UntypedFormControl(),
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', { validators: Validators.compose([Validators.required, Validators.email]), updateOn: 'blur' }),
      password: new UntypedFormControl('', [ Validators.minLength(8), Validators.maxLength(16)]),
      passwordConfirm: new UntypedFormControl(''),
      roleIds: new UntypedFormControl('', Validators.required),
      resources: new UntypedFormControl([]),
      loginEnabled: new UntypedFormControl(true),
      mfaEnabled: new UntypedFormControl(false),
      mfaDestination: new UntypedFormControl(null),
      cellNumber: new UntypedFormControl(null)
    });
  }
}
