import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CalendarEvent } from '@core/models';
import { CalendarService, SearchService } from '@core/services';

import * as moment from 'moment';
import { Observable, of } from "rxjs";
import { tap, debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'oiq-edit-notification-dialog',
  templateUrl: './edit-notification-dialog.component.html',
  styleUrls: ['./edit-notification-dialog.component.scss']
})
export class EditNotificationDialogComponent implements OnInit {
  formatter = (x: { name: string }) => x.name;
  @ViewChild('isoInstance') isoInstance: NgbTypeahead;
  @ViewChild('clientInstance') clientInstance: NgbTypeahead;

  form: UntypedFormGroup;
  hidden: boolean;
  searching = false;
  model: any;
  isCollectionDetail: boolean;
  minDate: any;
  reminderIntervals2: any[];

  entityTypes: any[] = [
    { name: 'Clients', value: 'clients', overview: 'overview', isSelectOption: true },
    { name: 'Syndicators', value: 'syndicators', overview: 'overview', isSelectOption: true },
    { name: 'ISOS', value: 'isos', overview: 'general-info', isSelectOption: true },
    { name: 'Law Firms', value: 'law-firms', overview: 'general-info', isSelectOption: true },
  ];

  reminderIntervals: any[] = [
    { name: '15m before', value: 15 },
    { name: '30m before', value: 30 },
    { name: '1h before', value: 60 },
    { name: '6h before', value: 360 },
    { name: '12h before', value: 720 },
    { name: '24h before', value: 1400 },
  ];

  constructor(
    public dialogRef: MatDialogRef<EditNotificationDialogComponent>,
    private calendarService: CalendarService,
    private searchService: SearchService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.minDate = new Date();
    this.form = new CalendarEvent(
      this.data.id,
      this.data.eventDate,
      this.data.time,
      this.data.interval,
      this.data.interval2,
      this.data.title,
      this.data.description,
      this.data.entityId,
      this.data.entityName,
      this.data.entityType
    );
    if (this.data.entityType == 'collections-detail') {
      this.isCollectionDetail = true;
    };
    this.getReminderIntervals2(this.data.interval)
  }

  closeDialog(closeNotifications: boolean) {
    this.dialogRef.close(closeNotifications);
  }

  onSubmit() {
    const form = this.form.value;

    let calendarRemindersArray = [
      {
        "reminderRead": false,
        "interval": form.interval,
        "unit": "Minutes"
      }
    ];

    if (form.interval2) {
      calendarRemindersArray.push({
        "reminderRead": false,
        "interval": form.interval2,
        "unit": "Minutes"
      });
    };

    form.eventDate = this.combineDateTime(form.date, form.time);
    form.eventRead = false;
    form.userId = this.data.user.id;
    form.entityId = form.entityId ? form.entityId : null;
    form.calendarReminders = calendarRemindersArray;
    this.calendarService.createOrUpdate(form).subscribe((r: any) => {
      if (this.data.id) {
        // reminder 1 update
        if (form.interval !== this.data.calendarReminders[0].interval) {
          let data = {
            "reminderRead": false,
            "interval": form.interval,
            "unit": "Minutes",
            "calendarEntryId": this.data.id
          };
          this.calendarService.updateCalendarReminder(this.data.calendarReminders[0].calendarEntryId, this.data.calendarReminders[0].id, data).subscribe((r: any) => { });
        }
        // reminder 2 update or delete or create
        if (this.data.calendarReminders[1]) {
          // update or delete
          if (form.interval2!=='') {
            // reminder 2 update
            if (form.interval2 !== this.data.calendarReminders[1].interval) {
              let data = {
                "reminderRead": false,
                "interval": form.interval2,
                "unit": "Minutes",
                "calendarEntryId": this.data.id
              };
              this.calendarService.updateCalendarReminder(this.data.calendarReminders[1].calendarEntryId, this.data.calendarReminders[1].id, data).subscribe((r: any) => { });
            }
          } else {
            // delete calendar reminder
            this.calendarService.deleteCalendarReminder(this.data.id, this.data.calendarReminders[1].id).subscribe();
          }
        } else {
          // reminder 2 create
          let data = {
            "reminderRead": false,
            "interval": form.interval2,
            "unit": "Minutes",
            "calendarEntryId": this.data.id
          };
          this.calendarService.createCalendarReminder(this.data.id, data).subscribe((r: any) => { });
        };
      }
      this.dialogRef.close();
    });
  }

  combineDateTime(date, time) {
    date = moment(date).format('DD/MM/YYYY');
    var dateParts = date.split('/');
    var timeParts = time.split(':');
    if (dateParts && timeParts) {
      const datetime = new Date(dateParts[2], dateParts[1] - 1, dateParts[0], timeParts[0], timeParts[1]);
      return datetime.toISOString();
    }
  }

  getFormatFromNow(datetime) {
    var timedifference = new Date().getTimezoneOffset();
    return moment(datetime).utcOffset(timedifference).fromNow();
  }

  onEntitySelect(event) {
    event.preventDefault();
    this.form.patchValue({ entityId: Number(event.item.id) });
    this.form.patchValue({ entityName: String(event.item.name) });
  }

  onIntervalChange(e) {
    this.getReminderIntervals2(e.value);
  }

  getReminderIntervals2(interval) {
    const index = this.reminderIntervals.map(object => object.value).indexOf(interval);
    if (index === -1) // not found
      return this.reminderIntervals2;
    return this.reminderIntervals2 = this.reminderIntervals.slice(index + 1);
  }

  onEntityTypeChange(e) {
    this.form.patchValue({ entityId: null });
    this.form.patchValue({ entityName: null });
  }

  searchEntity = (text$: Observable<string>) => text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term =>
      this.searchEntityType(term)
    ),
    tap(() => this.searching = false)
  )

  searchEntityType(term) {
    if (this.form.value.entityType == 'clients') {
      return this.searchService.performClientsTypeahead(term).pipe(
        catchError(() => {
          return of([]);
        }))
    }
    if (this.form.value.entityType == 'syndicators') {
      return this.searchService.performSyndicatorsTypeahead(term).pipe(
        catchError(() => {
          return of([]);
        }))
    }
    if (this.form.value.entityType == 'isos') {
      return this.searchService.performIsosTypeahead(term).pipe(
        catchError(() => {
          return of([]);
        }))
    }

    if (this.form.value.entityType == 'law-firms') {
      return this.searchService.performLawFirmsTypeahead(term).pipe(
        catchError(() => {
          return of([]);
        }))
    }
  }

  viewEntity(row) {
    if (row.entityType && row.entityId) {
      this.router.navigate([row.entityType, row.entityId, '/overview']);
    }
  }
}
