import { Component, HostBinding, Input, HostListener, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '@core/services';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'oiq-loading-message',
  templateUrl: './loading-message.component.html',
  styleUrls: ['./loading-message.component.scss']
})
export class LoadingMessageComponent implements OnInit, OnDestroy {
  private loaderSubscription: Subscription;
  @HostBinding('class.loading') loading:boolean;
  @Input() isDataTable:boolean = true;
  @Input() showNoRecordsMessage:boolean = false;

  constructor(
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.loading = this.loaderService.isLoading;
    this.loaderSubscription = this.loaderService.loader.pipe(delay(0)).subscribe((r: boolean) => {
      this.loading = r;
    });
  }

  ngOnDestroy() {
    this.loaderSubscription?.unsubscribe();
  }
}
