import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';

export class SyndicationOffer extends UntypedFormGroup {
  constructor() {
    super({
      syndicatorId: new UntypedFormControl(),
      syndicatorName: new UntypedFormControl(),
      minimumPercentSyndicated: new UntypedFormControl('', Validators.required),
      maximumAmountSyndicated: new UntypedFormControl('', Validators.required),
      maximumPercentSyndicated: new UntypedFormControl('', Validators.required),
      managementFeePercent: new UntypedFormControl('', Validators.required),
      commissionPercent: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
      maxTotalReceived: new UntypedFormControl('', Validators.required),
      maxPayback: new UntypedFormControl('', Validators.required)
    });
  }
}

export class SyndicationOffers extends UntypedFormGroup {
  constructor() {
    super({
      syndicationOffers: new UntypedFormArray([])
    });
  }
}

export class ManualSyndicationOffer extends UntypedFormGroup {
  constructor() {
    super({
      fundingId: new UntypedFormControl(),
      syndicatorId: new UntypedFormControl(),
      syndicatorName: new UntypedFormControl(),
      acceptedAmountSyndicated: new UntypedFormControl('', Validators.required),
      acceptedPercentSyndicated: new UntypedFormControl('', Validators.required),
      managementFeePercent: new UntypedFormControl('', Validators.required),
      commissionPercent: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
      maxTotalReceived: new UntypedFormControl('', Validators.required),
      maxPayback: new UntypedFormControl('', Validators.required)
    });
  }
}

export class ManualSyndicationOffers extends UntypedFormGroup {
  constructor() {
    super({
      manualSyndicationOffers: new UntypedFormArray([])
    });
  }
}

export const SYNDICATIONS_CALCULATION_LOGIC_OPTIONS = [{
  value: 'PRINCIPAL_SYNDICATED_AMOUNT',
  name: 'Principal Syndicated Amount'
},
{
  value: 'DISBURSEMENT_AMOUNT',
  name: 'Disbursement amount'
}]
