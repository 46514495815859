import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

import { TenantService, AttachmentService } from '@core/services';

@Component({
  selector: 'oiq-edit-white-label-dialog',
  templateUrl: './edit-white-label-dialog.component.html',
  styleUrls: ['./edit-white-label-dialog.component.scss']
})
export class EditWhiteLabelDialogComponent implements OnInit {

  form: UntypedFormGroup;
  tenant: any;
  tenantId: number;
  logoBase64: any;
  emailLogoBase64: any;
  isUserTenantAdmin = true;

  constructor(
    private fb: UntypedFormBuilder,
    private domSanitizer: DomSanitizer,
    private attachmentService: AttachmentService,
    public dialogRef: MatDialogRef<EditWhiteLabelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {

    this.form = this.fb.group({
      name: ['', Validators.required],
      memoField: ['', Validators.required],
      logoId: [''],
      emailLogoId: [''],
      tenantDefault: [false, Validators.required],
      emailFromAddress: [null],
      masterEmailNotificationTemplate: [null]
    });
    if (this.dialogRef.componentInstance.data) {
      this.form.controls['name'].setValue(this.dialogRef.componentInstance.data.name);
      this.form.controls['memoField'].setValue(this.dialogRef.componentInstance.data.memoField);
      this.form.controls['logoId'].setValue(this.dialogRef.componentInstance.data.logoId);
      this.form.controls['emailLogoId'].setValue(this.dialogRef.componentInstance.data.emailLogoId);
      this.form.controls['tenantDefault'].setValue(this.dialogRef.componentInstance.data.tenantDefault);
      this.form.controls['emailFromAddress'].setValue(this.dialogRef.componentInstance.data.emailFromAddress);
      this.form.controls['masterEmailNotificationTemplate'].setValue(this.dialogRef.componentInstance.data.masterEmailNotificationTemplate);

      if (this.dialogRef.componentInstance.data.logoId) {
        this.downloadAttachment(this.dialogRef.componentInstance.data.logoId).subscribe((data) => {
          this.logoBase64 = data;
        });
      }

      if (this.dialogRef.componentInstance.data.emailLogoId) {
        this.downloadAttachment(this.dialogRef.componentInstance.data.emailLogoId).subscribe((data) => {
          this.emailLogoBase64 = data;
        });
      }
    }

    if (this.data) {
      this.isUserTenantAdmin = this.data.isUserTenantAdmin;
      if (this.isUserTenantAdmin === false) {
        this.form.controls['tenantDefault'].setValue(false);
      }
    }
  }

  logoUploaded(event) {
    this.form.get('logoId').setValue(event.upload.id);

    this.downloadAttachment(event.upload.id).subscribe((data) => {
      this.logoBase64 = data;
    });
  }

  emailLogoUploaded(event) {
    this.form.get('emailLogoId').setValue(event.upload.id);

    this.downloadAttachment(event.upload.id).subscribe((data) => {
      this.emailLogoBase64 = data;
    });
  }

  removeLogo() {
    this.form.get('logoId').setValue(null);

    this.logoBase64 = null;
  }

  removeEmailLogo() {
    this.form.get('emailLogoId').setValue(null);

    this.emailLogoBase64 = null;
  }

  downloadAttachment(id) {
    return this.attachmentService.downloadAsBase64(id)
      .pipe(map((r) => {
        return this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + r);
      }));
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}

