import { Component, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LookupListService } from '@core/services';
import { ApplicationEventConfirmationResult } from '@shared/models/application-event-confirmation';

const REVISION_REASON_TYPE = 'Revision_Reason';

@Component({
  selector: 'oiq-application-event-confirmation-dialog',
  templateUrl: './application-event-confirmation-dialog.component.html',
  styleUrls: ['./application-event-confirmation-dialog.component.scss']
})
export class ApplicationEventConfirmationDialogComponent implements OnInit {

  application: any;
  event: string;
  form: UntypedFormGroup;
  tenant: any = {};
  tenantUsers: Array<any> = [];
  isos: Array<any> = [];
  isoUsers: Array<any> = [];
  submitEnabled = false;
  showRevisionReasons = false;
  revisions = [];

  readonly revisionEventName = 'Send_For_Revision';
  readonly showRevisionReasonStatuses = ['Underwriting', 'Pre_Underwriting'];

  constructor(
    private lookupListService: LookupListService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ApplicationEventConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      client: [false],
      applicationSubmitter: [false],
      underwriter: [false],
      isoManager: [false],
      isoSalesRepresentative: [false],
      tenantUserIdRecipients: [''],
      isoEmailAddressRecipients: new UntypedFormArray([]),
      isoUserIdRecipients: [''],
      emailAddressRecipients: new UntypedFormArray([])
    });

    this.event = this.data.event;
    this.application = this.data.application;

    if (!this.data.notificationsEnabled) {
      this.submitEnabled = true;
    }

    if ((this.application.status === 'Underwriting' || this.application.status === 'Pre_Underwriting') && this.data.event === this.revisionEventName) {
      this.getRevisionReasons();
      this.form.addControl('revisionReason', new FormControl());
      this.setValidationForRevisionReason();
    }
  }

  setValidationForRevisionReason() {
    const isStipulationsRequired = this.isThereAnyRequestedStipulation(this.data.application.stipulations);

    if (isStipulationsRequired) {
      this.form.get('revisionReason').clearValidators();
      return;
    }

    this.form.get('revisionReason').setValidators([Validators.required]);
  }

  toggleLoading(event) {
    if (event) {
      this.submitEnabled = true;
    }
  }

  onLinkClick() {
    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submit() {
    let result = {
      client: this.form.value.client,
      applicationSubmitter: this.form.value.applicationSubmitter,
      underwriter: this.form.value.underwriter,
      isoManager: this.form.value.isoManager,
      isoSalesRepresentative: this.form.value.isoSalesRepresentative,
      tenantUserIdRecipients: this.form.value.tenantUserIdRecipients,
      isoUserIdRecipients: this.form.value.isoUserIdRecipients,
      isoEmailAddressRecipients: this.form.value.isoEmailAddressRecipients.map((row) => row.email),
      emailAddressRecipients: this.form.value.emailAddressRecipients.map((row) => row.email),
      message: this.form.value.revisionReason,
    } as ApplicationEventConfirmationResult;

    const message = this.form.value.revisionReason;

    if (this.data.event === this.revisionEventName && message) {
      result = {
        ...result,
        message
      };
    }

    this.dialogRef.close(result);
  }

  getRevisionReasons(): void {
    this.lookupListService.listByType(REVISION_REASON_TYPE)
      .subscribe((r: any) => {
        this.revisions = r.content;
      });
  }

  isThereAnyRequestedStipulation(stipulations: any[]): boolean {
    return stipulations.some(stipulation => stipulation.status === "Requested");
  }

}
