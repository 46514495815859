<div [formGroup]="form" autocomplete="off">


  <mat-accordion [@.disabled]="disableAnimation">
    <mat-expansion-panel [expanded]="false" [(expanded)]="panelOpenState">
      <mat-expansion-panel-header class="right-aligned-header">
        <mat-panel-title>
          <span class="expand-indicator" [class.expanded]="panelOpenState"></span>
          <h2 class="font-heading text-uppercase fw-bold">NOTIFICATION RECIPIENTS</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="mb-4">
        <div class="col-sm-12 my-1">
          <mat-checkbox color="blue" formControlName="client" [value]="true">Merchant</mat-checkbox>
        </div>

        <div class="col-sm-12 my-1">
          <mat-checkbox color="blue" formControlName="applicationSubmitter" [value]="true">Application Submitter</mat-checkbox>
        </div>

        <div class="col-sm-12 my-1">
          <mat-checkbox color="blue" formControlName="underwriter" [value]="true">Underwriter</mat-checkbox>
        </div>

        <div class="col-sm-12 my-1">
          <mat-checkbox color="blue" formControlName="isoManager" [value]="true">ISO Manager</mat-checkbox>
        </div>

        <div class="col-sm-12 my-1">
          <mat-checkbox color="blue" formControlName="isoSalesRepresentative" [value]="true">ISO Sales Representative</mat-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <mat-label>{{ tenant.name }} User Recipients</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="tenantUserIdRecipients" multiple>
              <mat-option *ngFor="let user of tenantUsers" [value]="user.id">
                {{ user.firstName }} {{ user.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-6">
          <mat-label>ISO User Recipients</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="isoUserIdRecipients" multiple>
              <mat-option *ngFor="let user of isoUsers" [value]="user.id">
                {{ user.firstName }} {{ user.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <mat-label>ISO Recipients Email Addresses</mat-label>
          <div formArrayName="isoEmailAddressRecipients">
            <div class="row mt-2" *ngFor="let isoEmail of form.get('isoEmailAddressRecipients')['controls']; let index = index" [formGroup]="isoEmail">
              <div class="col-sm-11">
                <mat-form-field appearance="outline">
                  <input matInput formControlName="email">
                </mat-form-field>
              </div>
              <div class="col-sm-1 text-end">
                <button type="button" class="delete-btn" title="Remove Email" (click)="removeEmailAddress(index, 'isoEmailAddressRecipients')"></button>
              </div>
            </div>

            <div class="mt-2 ms-3 link text-blue" (click)="addEmailAddress('isoEmailAddressRecipients')">+ Add New Email Address</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <mat-label>Email Addresses</mat-label>
          <div formArrayName="emailAddressRecipients">
            <div class="row mt-2" *ngFor="let email of form.get('emailAddressRecipients')['controls']; let index = index" [formGroup]="email">
              <div class="col-sm-11">
                <mat-form-field appearance="outline">
                  <input matInput formControlName="email">
                </mat-form-field>
              </div>
              <div class="col-sm-1 text-end">
                <button type="button" class="delete-btn" title="Remove Email" (click)="removeEmailAddress(index, 'emailAddressRecipients')"></button>
              </div>
            </div>

            <div class="mt-2 ms-3 link text-blue" (click)="addEmailAddress('emailAddressRecipients')">+ Add New Email Address</div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
