import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ContactService, IsoService, PlaidService } from '@core/services';

import { Contact, PlaidRequest } from '@core/models';

@Component({
  selector: 'oiq-plaid-token-request-dialog',
  templateUrl: './plaid-token-request-dialog.component.html',
  styleUrls: ['./plaid-token-request-dialog.component.scss']
})
export class PlaidTokenRequestDialogComponent implements OnInit {

  loadingContact = false;

  form: UntypedFormGroup;

  model: any;

  tenantId: number;

  application: any;

  contactId: number;

  contact: any;

  toggleEmail = false;

  contacts: Array<any> = [];

  iso: any;
  isMissingIsoNotificationEmail: Boolean;
  loadingIso: Boolean;
  submiting: Boolean;

  constructor(
    private contactService: ContactService,
    private plaidservice: PlaidService,
    private isoService: IsoService,
    public dialogRef: MatDialogRef<PlaidTokenRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.form = new PlaidRequest();
    this.form.get('emailAddress').setValidators([Validators.required, Validators.email]);

    this.application = this.data.application;
    this.form.get('applicationId').setValue(this.application.id);
    this.form.get('applicationId').updateValueAndValidity();

    this.contacts = this.data.contacts;
    this.contact = this.contacts[0];
    this.contactId = this.contact.id;

    this.getContact();
  }

  contactSelected() {
    this.getContact();
  }

  onNotificationTargetChanged() {
    if (this.form.value.notificationTarget === 'ISO') {
      this.form.get('emailAddress').clearValidators();
      this.getIso();
    } else {
      this.form.get('emailAddress').setValidators([Validators.required, Validators.email]);
      if (this.form.value.notificationTarget === 'Both') {
        this.getIso();
      }
    }

    this.form.get('emailAddress').updateValueAndValidity();
  }

  getContact() {
    this.loadingContact = true;
    this.contactService.getById(this.contactId)
      .subscribe((r: any) => {
        if (r) {
          this.contact = r;

          this.form.get('contactId').setValue(this.contact.id);
          this.form.get('emailAddress').setValue(this.contact.email);
        }
      }, (error) => {
        this.loadingContact = false;
      },
        () => {
          this.loadingContact = false;
        });
  }

  getIso() {
    this.loadingIso = true
    this.isMissingIsoNotificationEmail = true;
    this.isoService.getSettings(this.application.iso.id)
      .subscribe((r: any) => {
        this.iso = r;
        if (this.iso && this.iso.emailAddressRecipients && this.iso.emailAddressRecipients.length) {
          this.isMissingIsoNotificationEmail = false;
        }
      }, (error) => {
        this.loadingIso = false;
      },
        () => {
          this.loadingIso = false;
        });
  }

  initiateTokenEmail() {
    let data = this.form.value;
    delete data.emailAddress;

    this.plaidservice.initiateLinkToken(data)
      .subscribe((r: any) => {
        this.dialogRef.close();
      }, (error) => {
        this.submiting = false;
      }, () => {
        this.submiting = false;
      });
  }

  submit(dirty) {
    this.submiting = true;
    if (dirty) {
      this.contact.email = this.form.get('emailAddress').value;

      this.contactService.update(this.contactId, this.contact)
        .subscribe((r: any) => {
          this.initiateTokenEmail();
        }, (error) => {
          this.submiting = false;
        })
    } else {
      this.initiateTokenEmail();
    }
  }

}
