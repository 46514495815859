import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { Address } from './address';

export class Events extends UntypedFormGroup {
  constructor() {
    super({
      events: new UntypedFormArray([])
    });
  }
}

export class Event extends UntypedFormGroup {
  constructor() {
    super({
      event: new UntypedFormControl(),
      emailTemplateId: new UntypedFormControl(),
      smsTemplateId: new UntypedFormControl(),
      active: new UntypedFormControl(false),
      client: new EventNotification(),
      applicationSubmitter: new EventNotification(),
      underwriter: new EventNotification(),
      tenantUsers: new EventNotification(),
      tenantEmailAddresses: new EventNotification(),
      isoSalesRepresentative: new EventNotification(),
      isoUsers: new EventNotification(),
      isoEmailAddresses: new EventNotification(),
      isoManager: new EventNotification(),
      createStipulations: new UntypedFormArray([]),
      updateStipulations: new UntypedFormArray([]),
      type: new UntypedFormControl()
    });
  }
}

export class EventNotification extends UntypedFormGroup {
  constructor() {
    super({
      medium: new UntypedFormControl(),
      enabled: new UntypedFormControl(false),
      emailTemplateId: new UntypedFormControl(),
      smsTemplateId: new UntypedFormControl(),
      targetValues: new UntypedFormControl()
    });
  }
}

export class StipulationAction extends UntypedFormGroup {
  constructor() {
    super({
      name: new UntypedFormControl(),
      enabled: new UntypedFormControl(false),
      stipulationId: new UntypedFormControl(),
      status: new UntypedFormControl()
    });
  }
}
