import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CookieService } from 'ngx-cookie-service';

import { EditLookupListDialogComponent } from './edit-lookup-list-dialog/edit-lookup-list-dialog.component';

import { LookupListService, StipulationService, TenantService } from '@core/services';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '@core/authentication/auth.service';
import { mapDefaultApplicationType } from '@shared/application-type-mapper.util';
import { ActiveTab } from '@shared/models/tab';

@Component({
  selector: 'oiq-lookup-lists',
  templateUrl: './lookup-lists.component.html',
  styleUrls: ['./lookup-lists.component.scss']
})

export class LookupListsComponent implements OnInit, OnDestroy {

  types: Array<string> = [];

  rows: Array<any> = [];
  datasource: any = new MatTableDataSource([]);
  total: number;

  selection = new SelectionModel(true, []);

  activeTab: ActiveTab | null = null;

  columns: string[] = ['value', 'actions'];

  private tenantId: number;
  private destroy$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private lookupListService: LookupListService,
    private authService: AuthService,
    private tenantService: TenantService,
    private cookieService: CookieService,
    private stipulationService: StipulationService
  ) { }

  ngOnInit() {
    this.tenantId = JSON.parse(this.cookieService.get('currentUser')).tenantId;
    this.getLookupListTypes();
  }


  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.datasource.data.filter(row => row.tenantId).length;

    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.datasource.data.filter(row => row.tenantId).forEach(row => this.selection.select(row));
  }

  editRow(row?: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      type: this.activeTab,
      lookupList: row,
      tenantId: this.tenantId
    };
    dialogConfig.width = '1000px';
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(EditLookupListDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchLookupData(this.activeTab);
      }
    });
  }

  deleteRow(row) {
    row.deleting = true;
  }

  confirmDelete(row: any, event: PointerEvent): void {
    event.preventDefault();

    switch (this.activeTab) {
      case 'Stipulations_List':
        this.stipulationService.delete(row.id)
          .pipe(
            tap(() => this.fetchStipulations()),
            takeUntil(this.destroy$)
          )
          .subscribe();
        break;

      case 'Application_Decline_Reason':
      case 'Revision_Reason':
        this.lookupListService.delete(row.id)
          .pipe(
            tap(() => this.getLookupListByType(this.activeTab)),
            takeUntil(this.destroy$)
          )
          .subscribe();
        break;

      case 'Application_Types':
        this.tenantService.removeApplicationType(this.tenantId, row.type)
          .pipe(
            tap(() => this.getTenantApplicationTypes()),
            takeUntil(this.destroy$)
          )
          .subscribe();
        break;

      default:
        break;
    }
  }

  cancelDelete(row: any, event: PointerEvent): void {
    event.preventDefault();
    row.deleting = false;
  }

  getTenantApplicationTypes() {
    this.tenantService.getApplicationTypes(this.tenantId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.total = response.length;
        this.datasource = new MatTableDataSource(response.map((appType) => ({ ...appType, value: mapDefaultApplicationType(appType.type, appType.isNative), removable: !appType.isNative, })));
      })
  }

  getLookupListTypes() {
    this.lookupListService.listTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe((r: any) => {
        this.types = r;
        if (!this.authService.hasRole('SUPER_ADMIN')) {
          this.types.push('Application_Types');
        }
        this.selectTab(this.types[0] as ActiveTab);
      });
  }

  getLookupListByType(type: string) {
    this.lookupListService.listByType(type)
      .pipe(takeUntil(this.destroy$))
      .subscribe((r: any) => {
        this.total = r.totalElements;
        this.datasource = new MatTableDataSource(r.content.map((row) => ({ ...row, removable: true })));
      });
  }

  selectTab(tab: ActiveTab) {
    this.activeTab = tab;
    this.fetchLookupData(tab);
  }

  private fetchLookupData(tab: ActiveTab) {
    switch (tab) {
      case 'Stipulations_List':
        this.fetchStipulations();
        break;
      case 'Application_Decline_Reason':
      case 'Revision_Reason':
        this.getLookupListByType(tab);
        break;
      case 'Application_Types':
        this.getTenantApplicationTypes();
        break;

      default:
        break;
    }
  }

  private fetchStipulations(): void {
    if (!this.tenantId) {
      return;
    }

    this.stipulationService.list(this.tenantId).subscribe((r: any) => {
      this.total = r.totalElements;
      this.datasource = new MatTableDataSource(r.content);
    });
  }
}
