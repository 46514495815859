import { Component, Input, SimpleChanges, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { FullNamePipe } from '@shared/full-name.pipe';

import { CrsService, ApplicationService } from '@core/services';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

import { default as riskModelData } from '../../../assets/mappings/experian-mappings.json';
import { CreditRequestDialogComponent } from '@shared/credit-request-dialog/credit-request-dialog-component';

@Component({
  selector: 'oiq-credit-report',
  templateUrl: './credit-report.component.html',
  styleUrls: ['./credit-report.component.scss']
})
export class CreditReportComponent implements OnInit {

  @Input()
  application: any;

  @Input()
  creditCheckId: number;

  @Input()
  tenantSettings: any;

  @Input()
  creditChecks: Array<any>;

  creditCheck: any = {};

  creditProfile: any = {};

  loading: boolean;

  riskFactorModelData: any = riskModelData;

  currenciesArray = [
    'installmentBalance',
    'realEstateBalance',
    'revolvingBalance',
    'pastDueAmount',
    'monthlyPayment',
    'realEstatePayment'
  ];

  percentsArray = [
    'revolvingAvailablePercent'
  ];

  countsArray = [
    'disputedAccountsExcluded',
    'publicRecordsCount',
    'totalInquiries',
    'inquiriesDuringLast6Months',
    'totalTradeItems',
    'paidAccounts',
    'satisfactoryAccounts',
    'nowDelinquentDerog',
    'wasDelinquentDerog',
    'delinquencies30Days',
    'delinquencies60Days',
    'delinquencies90to180Days',
    'derogCounter'
  ];

  datesArray = [
    'oldestTradeDate'
  ];

  accountTypeMappings: any = {
    'mortgage': [
      'mortgage',
      'real estate'
    ]
  };

  profileSummaryList: any = [
    'publicRecordsCount',
    'pastDueAmount',
    'totalInquiries',
    'satisfactoryAccounts',
    'installmentBalance',
    'monthlyPayment',
    'inquiriesDuringLast6Months',
    'nowDelinquentDerog',
    'realEstateBalance',
    'realEstatePayment',
    'totalTradeItems',
    'wasDelinquentDerog',
    'revolvingBalance',
    'revolvingAvailablePercent',
    'paidAccounts',
    'oldestTradeDate',
  ];

  profileSummary: any = {};
  extraProfileSummary: any = {};
  extraProfileSummaryList: any = [];

  chunkedItems = [];

  messagesDatasource: any;
  messagesColumns: Array<any> = ['type', 'message'];

  publicRecordsDatasource: any;
  publicRecordsColumns: Array<any> = ['courtName', 'courtCode', 'ecoa', 'evaluation', 'referenceNumber', 'filingDate', 'status', 'statusDate'];

  statementsDatasource: any;
  statementsColumns: Array<any> = ['dateReported', 'statementText'];

  fraudShieldColumns: Array<any> = ['addressCount', 'addressDate', 'addressErrorCode', 'socialCount', 'socialDate', 'socialErrorCode', 'ssnFirstPossibleIssuanceYear', 'ssnLastPossibleIssuanceYear', 'type'];

  scoreSummaryDatasource: any;
  scoreSummaryColumns: Array<any> = ['riskModel', 'score', 'code', 'scoreFactorDescription'];

  inquiriesDatasource: any;
  inquiriesColumns: Array<any> = ['date', 'subscriber', 'amount', 'type', 'terms'];

  months: Array<any> = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
  paymentHistoryColumns: Array<any> = ['year'];

  delinquencyDatasource: any;
  delinquencyColumns: Array<any> = ['thirty', 'sixty', 'ninety', 'derog'];

  constructor(
    private fullNamePipe: FullNamePipe,
    private crsService: CrsService,
    public dialog: MatDialog,
    private router: Router,
    private viewPortScroller: ViewportScroller,
    private applicationService: ApplicationService
  ) {
  }

  ngOnInit() {
    this.mapContactInfoToCreditCheck();
    this.messagesDatasource = new MatTableDataSource([]);
    this.scoreSummaryDatasource = new MatTableDataSource();
    this.paymentHistoryColumns = this.paymentHistoryColumns.concat(this.months);

    this.getCreditCheck(this.creditCheckId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.creditCheckId && changes.creditCheckId.currentValue !== changes.creditCheckId.previousValue) {
      this.mapContactInfoToCreditCheck();
      this.getCreditCheck(this.creditCheckId);
    }
  }

  mapContactInfoToCreditCheck() {
    this.creditChecks.forEach(element => {
      element['contact'] = this.application.client.contacts.filter(contact => contact.id === element.contactId)[0];
    });
  }

  getPrimaryRecord(rows) {
    if (!rows || rows.length === 0) {
      return {};
    }

    return rows[0];
  }

  getOtherRecords(rows) {
    if (!rows || rows.length === 0) {
      return [];
    }

    return rows.slice(1);
  }

  getOtherNames(rows) {
    return this.nameJoinFormatter(this.getOtherRecords(rows));
  }

  getOtherSsns(rows) {
    return this.nameJoinFormatter(this.getOtherRecords(rows));
  }

  getPrimaryAddress() {
    if (this.creditProfile.addressInformation) {
      return this.creditProfile.addressInformation[0];
    } else {
      return {};
    }
  }

  getOtherAddresses() {
    if (!this.creditProfile.addressInformation || this.creditProfile.addressInformation.length < 2) {
      return [];
    } else {
      return this.creditProfile.addressInformation.slice(1);
    }
  }

  getPrimaryEmployer() {
    if (!this.creditProfile || !this.creditProfile.employmentInformation || this.creditProfile.employmentInformation.length === 0) {
      return '';
    }

    return this.creditProfile.employmentInformation[0];
  }

  getOtherEmployers() {
    if (!this.creditProfile || !this.creditProfile.employmentInformation || this.creditProfile.employmentInformation.length < 2) {
      return [];
    } else {
      return this.creditProfile.employmentInformation.slice(1);
    }
  }

  formatDate(value) {
    if (!value || value.length < 6) {
      return value;
    }

    return value.slice(0, 2) + '/' + value.slice(2, 4) + '/' + value.slice(4);
  }

  nameJoinFormatter(rows) {
    if (!rows || rows.length === 0) {
      return '';
    }

    return rows.map((row) => {
      return this.fullNamePipe.transform(row);
    }).join('; ');
  }

  matchesAccountType(mapping, accountTypeDescription) {
    if (!accountTypeDescription) {
      return false;
    }

    if (!this.accountTypeMappings[mapping]) {
      return false;
    }

    for (let i = 0; i < this.accountTypeMappings[mapping].length; i++) {
      if (accountTypeDescription.toLowerCase().indexOf(this.accountTypeMappings[mapping][i]) > -1) {
        return true;
      }
    }

    return false;
  }

  mortgageAccounts() {
    if (!this.creditProfile) {
      return [];
    }

    return this.creditProfile.tradeline.filter((row) => this.matchesAccountType('mortgage', row.accountTypeDescription));
  }

  noneMortgageAccounts() {
    if (!this.creditProfile) {
      return [];
    }

    return this.creditProfile.tradeline.filter((row) => !this.matchesAccountType('mortgage', row.accountTypeDescription));
  }

  getCreditCheck(creditCheckId: number) {
    let observable;
    const crsObservable = this.crsService.getById(creditCheckId);
    const experianObservable = this.applicationService.getCreditCheck(this.application.id, creditCheckId, this.application.tenantId === 7 ? true : false)

    const creditCheck = this.creditChecks.filter(r => r.id === creditCheckId)[0];
    if (creditCheck.provider === 'Experian') {
      observable = experianObservable;
    } else {
      observable = crsObservable;
    }
    observable.subscribe((r: any) => {
      this.creditCheck = r;
      if (this.creditCheck.status === 'Successful') {
        if (this.creditCheck.provider === 'Experian' && this.creditCheck.creditCheckResponse && this.creditCheck.creditCheckResponse.creditProfile && this.creditCheck.creditCheckResponse.creditProfile.length > 0) {
          this.creditProfile = this.creditCheck.creditCheckResponse.creditProfile[0];
        } else {
          this.creditProfile = this.creditCheck.results;
        }
        if (this.creditProfile) {

          if (this.creditProfile.informationalMessage && this.creditProfile.informationalMessage.length > 0) {
            this.messagesDatasource = new MatTableDataSource(this.creditProfile.informationalMessage.map((row) => {
              return {
                type: '',
                message: row.messageText
              };
            }));
          }

          if (this.creditProfile.statement && this.creditProfile.statement.length > 0) {
            this.statementsDatasource = new MatTableDataSource(this.creditProfile.statement.map((row) => {
              return {
                dateReported: row.dateReported,
                statementText: row.statementText
              };
            }));
          }

          if (this.creditProfile.publicRecord && this.creditProfile.publicRecord.length > 0) {
            this.publicRecordsDatasource = new MatTableDataSource(this.creditProfile.publicRecord);
          } else {
            this.publicRecordsDatasource = [];
          }

          if (this.creditProfile.riskModel) {
            this.scoreSummaryDatasource = new MatTableDataSource(this.creditProfile.riskModel.map((row) => {
              return {
                riskModel: {
                  fico: row.score,
                  range: '(Score range: 300 - 850)'
                },
                modelIndicatorDescription: row.modelIndicatorDescription,
                modelIndicator: row.modelIndicator,
                score: row.score,
                scoreFactors: row.scoreFactors
              };
            }));
          }

          if (this.creditProfile.tradeline) {
            // this.creditProfile.mortgageAccounts = this.mortgageAccounts();
            // this.creditProfile.noneMortgageAccounts = this.noneMortgageAccounts();
            this.creditProfile.installmentTradelines = this.creditProfile.tradeline.filter((row) => row.revolvingOrInstallment === 'I');
            this.creditProfile.revolvingTradelines = this.creditProfile.tradeline.filter((row) => row.revolvingOrInstallment === 'R');
          }
          // targeting only "profile summary" assuming it's on index 0
          this.creditProfile.summaries ? this.chunkedItems = this.chunkArray(this.creditProfile.summaries.filter(n => n.summaryType === 'Profile Summary')[0].attributes, 4) : '';

          this.creditProfile.summaries && !this.creditProfile.customData  ? this.profileSummary = this.mapProfileSummary(this.creditProfile.summaries.filter(n => n.summaryType === 'Profile Summary')[0].attributes) : '';

          this.creditProfile.customData ? this.profileSummary = this.mapNewProfileSummary() : '';

          this.creditProfile.inquiry ? this.inquiriesDatasource = new MatTableDataSource(this.creditProfile.inquiry) : '';
        }
      } else {
        this.creditProfile = {};
      }
    });
  }

  scrollToTop() {
    this.router.navigate([], {});
    this.viewPortScroller.scrollToPosition([0, 0])
  }

  fieldBelongsToType(array, field) {
    if (array.includes(field)) {
      return true;
    }
  }

  chunkArray(arr: any[], chunkSize: number): any[][] {
    let results = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      results.push(arr.slice(i, i + chunkSize));
    }
    return results;
  }

  mapToDescription(field, code) {
    return this.riskFactorModelData[field][code];
  }

  rerunCreditCheck() {
    const dialogRef = this.dialog.open(CreditRequestDialogComponent, {
      width: '800px',
      data: {
        application: this.application,
        contactId: this.creditCheck.contactId,
        tenantSettings: this.tenantSettings
      }
    });
    dialogRef.afterClosed().subscribe(report => {
      if (report) {
        this.getCreditChecks(report);
      }
    });
  }

  getCreditChecks(report?: any) {
    this.applicationService.listCreditChecks(this.application.id, this.application.tenantId === 7 ? true : false)
      .subscribe((r: any) => {
        this.creditChecks = r;

        this.creditCheck = this.creditChecks[0];
        this.creditCheckId = this.creditCheck.id;
        if (report) {
          this.mapContactInfoToCreditCheck();
          this.getCreditCheck(this.creditCheckId);
        }
      });
  }

  mapProfileSummary(profileSummary: any) {
    const excludedAttr = [
      'delinquencies30Days',
      'delinquencies60Days',
      'delinquencies90to180Days',
      'derogCounter',
      'disputedAccountsExcluded',
    ]
    const newProfileSummary = {};
    const extraProfileSummary = {};

    profileSummary.forEach((item) => {
      newProfileSummary[item.id] = item.value;

      if (this.profileSummaryList.includes(item.id) === false && excludedAttr.includes(item.id) === false) {
        extraProfileSummary[item.id] = item.value;
      }
    });

    this.extraProfileSummary = extraProfileSummary;
    this.extraProfileSummaryList = Object.keys(extraProfileSummary);

    return newProfileSummary;
  }

  mapNewProfileSummary() {
    let derogCounter = 0;
    this.creditProfile.tradeline.forEach(tradeline => {
      derogCounter += +tradeline.derogCounter;
    });
    this.creditProfile.customData.summaries.derogatorySummary['derogCounter'] = derogCounter;
    this.creditProfile.customData.summaries.derogatorySummary['nowDelinquentDerog'] = this.countNowDelinquentDerog(this.creditProfile.tradeline);
    this.creditProfile.customData.summaries.derogatorySummary['inquiriesDuringLast6Months'] = this.filterLastSixMonths(this.creditProfile.inquiry).length;
    this.creditProfile.customData.summaries.derogatorySummary['wasDelinquentDerog'] = this.countWasDelinquentDerog(this.creditProfile.tradeline);

    this.creditProfile.customData.summaries.tradeSummary['paidAccounts'] = this.countPaidAccounts(this.creditProfile.tradeline);
    this.creditProfile.customData.summaries.tradeSummary['satisfactoryAccounts'] = this.countSatisfactoryAccounts(this.creditProfile.tradeline);
  }

  countPaidAccounts(tradelines): number {
    const paidAccountConditions = new Set(['A2', '12', '13']);
    return tradelines.filter(tl =>
      tl.openOrClosed === 'C' &&
      paidAccountConditions.has(tl.enhancedPaymentData.enhancedAccountCondition)
    ).length;
  }

  countSatisfactoryAccounts(tradelines): number {
    const satisfactoryStatusCodes = new Set(['05', '11', '12', '13', 'A2']);
    return tradelines.filter(tl => satisfactoryStatusCodes.has(tl.status)).length;
  }

  countNowDelinquentDerog(tradelines): number {
    return tradelines.filter(tl =>
      (parseInt(tl.delinquencies30Days) > 0 ||
      parseInt(tl.delinquencies60Days) > 0 ||
      parseInt(tl.delinquencies90to180Days) > 0 ||
      parseInt(tl.derogCounter) > 0) &&
      parseInt(tl.balanceAmount) > 0
    ).length;
  }

  countWasDelinquentDerog(tradelines): number {
    return tradelines.filter(tl =>
      (parseInt(tl.delinquencies30Days) > 0 ||
      parseInt(tl.delinquencies60Days) > 0 ||
      parseInt(tl.delinquencies90to180Days) > 0 ||
      parseInt(tl.derogCounter) > 0) &&
      tl.openOrClosed === 'C' &&
      (parseInt(tl.balanceAmount) === 0 || !tl.balanceAmount)
    ).length;
  }

  filterLastSixMonths(data) {
    const currentDate = new Date();
    const sixMonthsAgo = new Date(currentDate.setMonth(currentDate.getMonth() - 6));

    return data.filter(item => {
      const month = item.date.substring(0, 2) - 1;
      const day = item.date.substring(2, 4);
      const year = item.date.substring(4);

      const itemDate = new Date(year, month, day);

      return itemDate >= sixMonthsAgo;
    });
  }

  refreshCreditCheck() {
    this.applicationService.refreshCreditCheck(this.application.id, this.creditCheckId)
    .subscribe((report) => this.getCreditChecks(report));
  }
}
