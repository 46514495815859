import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, AbstractControl, UntypedFormGroup, UntypedFormArray, Validators, ValidationErrors, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { forkJoin } from 'rxjs';

import { ApplicationService, FundingService, SettingService, SyndicatorService } from '@core/services';

import { SYNDICATIONS_CALCULATION_LOGIC_OPTIONS, SyndicationOffer, SyndicationOffers } from '@core/models';


///
// THIS COMPONENT IS OBSOLETE PLEASE USE AddSyndicationOffersComponent
///

@Component({
  selector: 'oiq-syndication-offer-dialog',
  templateUrl: './edit-syndication-offer-dialog.component.html',
  styleUrls: ['./edit-syndication-offer-dialog.component.scss']
})
export class EditSyndicationOfferDialogComponent implements OnInit {
  private applicationSubscription: Subscription;

  private fundingSubscription: Subscription;

  application: any
  funding: any

  form: SyndicationOffers;
  tenantFinancialSettings: any = {};
  syndicator: any;

  fieldUpdating: string;

  syndicationCalculationLogicOptions = SYNDICATIONS_CALCULATION_LOGIC_OPTIONS;
  isSavingDefaultSyndicationCalculationLogic = false;

  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(
    public dialogRef: MatDialogRef<EditSyndicationOfferDialogComponent>,
    private applicationService: ApplicationService,
    private fundingService: FundingService,
    private settingService: SettingService,
    private syndicatorService: SyndicatorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.form = new SyndicationOffers();

    this.fundingSubscription = this.fundingService.funding.subscribe(funding=> this.funding = funding);

    this.applicationSubscription = this.applicationService.application.subscribe((application) => {
      this.application = application;

      this.getSettings();
      this.populateSyndicationOffer();
    });
  }

  ngOnDestroy() {
    this.applicationSubscription.unsubscribe();
    this.fundingSubscription.unsubscribe();
  }

  onMaximumPercentSyndicatedChange(row) {
    if (row.get('maximumPercentSyndicated').value !== '') {
      let maximumPercentSyndicated = parseFloat(row.get('maximumPercentSyndicated').value);
      const fundedAmount = parseFloat(this.application.appliedFundingOffer.fundedAmount);
      const disbursementAmount = parseFloat(this.funding.disbursementAmount);
      row.get('maximumAmountSyndicated').setValue(fundedAmount * (maximumPercentSyndicated / 100));

      if(row.get('calculationLogic').value === 'PRINCIPAL_SYNDICATED_AMOUNT') {
        row.get('principalToBePaid').setValue(fundedAmount * (maximumPercentSyndicated / 100));
      } else {
        row.get('principalToBePaid').setValue(disbursementAmount * (maximumPercentSyndicated / 100));
      }
      this.recalculate(row);
    }
  }

  onMaximumAmountSyndicatedChange(row) {
    if (row.get('maximumAmountSyndicated').value !== '') {
      const maximumAmountSyndicated = parseFloat(row.get('maximumAmountSyndicated').value);
      const fundedAmount = parseFloat(this.application.appliedFundingOffer.fundedAmount);
      const disbursementAmount = parseFloat(this.funding.disbursementAmount);
      let maximumPercentSyndicated = this.roundPercent((maximumAmountSyndicated / fundedAmount) * 100);
      row.get('maximumPercentSyndicated').setValue(maximumPercentSyndicated);

      if(row.get('calculationLogic').value === 'PRINCIPAL_SYNDICATED_AMOUNT') {
        row.get('principalToBePaid').setValue(maximumAmountSyndicated);
      } else {
        row.get('principalToBePaid').setValue(disbursementAmount * (maximumPercentSyndicated / 100));
      }
      this.recalculate(row);
    }
  }

  recalculate(row) {
    const paybackAmount = parseFloat(this.application.appliedFundingOffer.paybackAmount);

    if (row.get('principalToBePaid').value !== '' && row.get('commissionPercent').value !== '') {
      const principalToBePaid = parseFloat(row.get('principalToBePaid').value);
      const commissionPercent = parseFloat(row.get('commissionPercent').value);
      const maximumAmountSyndicated = parseFloat(row.get('maximumAmountSyndicated').value);
      const maxTotalReceived = principalToBePaid + (maximumAmountSyndicated * commissionPercent / 100);
      row.get('maxTotalReceived').setValue(maxTotalReceived);
    }

    if (row.get('maximumPercentSyndicated').value !== '') {
      const maximumPercentSyndicated = parseFloat(row.get('maximumPercentSyndicated').value);
      row.get('maxPayback').setValue(paybackAmount * (maximumPercentSyndicated / 100));
    }
  }

  populateSyndicationOffer() {
    const fundedAmount = parseFloat(this.application.appliedFundingOffer.fundedAmount);
    const syndicationOffer = new SyndicationOffer();
    syndicationOffer.addControl('calculationLogic', new UntypedFormControl());
    syndicationOffer.addControl('principalToBePaid', new UntypedFormControl({value: null, disabled: true}));

    syndicationOffer.patchValue({
      syndicatorId: this.data.syndicationOffer.syndicatorId,
      syndicatorName: this.data.syndicationOffer.syndicator.name,
      maximumAmountSyndicated: this.data.syndicationOffer.maximumAmountSyndicated,
      minimumPercentSyndicated: this.data.syndicationOffer.minimumPercentSyndicated,
      maximumPercentSyndicated: this.data.syndicationOffer.maximumPercentSyndicated,
      commissionPercent: this.data.syndicationOffer.commissionPercent,
      managementFeePercent: this.data.syndicationOffer.managementFeePercent,
      calculationLogic: this.data.syndicationOffer.calculationLogic
    });

    syndicationOffer.get('minimumPercentSyndicated').setValidators([Validators.required, (control: AbstractControl): ValidationErrors => this.validateMinimumPercent(syndicationOffer, control)]);
    syndicationOffer.get('maximumPercentSyndicated').setValidators([Validators.required, (control: AbstractControl): ValidationErrors => this.validateMaximumPercent(syndicationOffer, control)]);

    syndicationOffer.get('maximumAmountSyndicated').valueChanges.subscribe((value) => {
      if (this.fieldUpdating === 'maximumAmountSyndicated') {
        return;
      }

      this.fieldUpdating = 'maximumPercentSyndicated';

      this.onMaximumAmountSyndicatedChange(syndicationOffer);

      this.fieldUpdating = null;
    });

    syndicationOffer.get('maximumPercentSyndicated').valueChanges.subscribe((value) => {
      if (this.fieldUpdating === 'maximumPercentSyndicated') {
        return;
      }

      this.fieldUpdating = 'maximumAmountSyndicated';

      this.onMaximumPercentSyndicatedChange(syndicationOffer);

      this.fieldUpdating = null;
    });


    //This will start recalculation and fill fields that are calculated on the fly
    syndicationOffer.get('maximumAmountSyndicated').setValue(this.data.syndicationOffer.maximumAmountSyndicated);

    (this.form.get('syndicationOffers') as UntypedFormArray).push(syndicationOffer);
  }

  validateMinimumPercent(form: UntypedFormGroup, control: AbstractControl): ValidationErrors {
    if (!control.value || control.value === null) {
      return null;
    }

    const minimumPercentSyndicated = parseFloat(control.value);

    if (isNaN(minimumPercentSyndicated)) {
      const result = <ValidationErrors> {
        minimumPercentSyndicatedTooSmall: true
      };

      return result;
    }

    if (minimumPercentSyndicated < 0) {
      const result = <ValidationErrors> {
        minimumPercentSyndicatedTooSmall: true
      };

      return result;
    }

    const maximumPercentSyndicated = parseFloat(form.get('maximumPercentSyndicated').value);

    if (!isNaN(maximumPercentSyndicated)) {
      if (minimumPercentSyndicated > maximumPercentSyndicated) {
        const result = <ValidationErrors> {
          minimumPercentSyndicatedTooLarge: true
        };

        return result;
      }
    }

    return null;
  }

  roundPercent(input) {
    return Math.round((input + Number.EPSILON) * 100) / 100;
  }

  validateMaximumPercent(form: UntypedFormGroup, control: AbstractControl): ValidationErrors {
    if (!control.value || control.value === null) {
      return null;
    }

    const maximumPercentSyndicated = parseFloat(control.value);

    if (isNaN(maximumPercentSyndicated)) {
      const result = <ValidationErrors> {
        maximumPercentSyndicatedTooSmall: true
      };

      return result;
    }

    if (maximumPercentSyndicated > 100) {
      const result = <ValidationErrors> {
        maximumPercentSyndicatedTooLarge: true
      };

      return result;
    }

    form.get('minimumPercentSyndicated').updateValueAndValidity();

    return null;
  }

  getSettings() {
    this.settingService.getTenantFinancialSettings()
      .subscribe((r: any) => {
        this.tenantFinancialSettings = r;
      });
  }

  removeSyndicator(i) {
    (this.form.get('syndicationOffers') as UntypedFormArray).removeAt(i);
  }

  save() {
    const observables = [];

    for (let i = 0; i < this.form.get('syndicationOffers')['controls'].length; i++) {
      observables.push(this.applicationService.updateSyndicationOffer(this.application.id, this.data.syndicationOffer.id, this.form.get('syndicationOffers')['controls'][i].value));
    }

    forkJoin(observables).subscribe((r: any) => {
        this.dialogRef.close(true);
      });
  }

  onSelectedSyndicationCalculationLogic(event, syndicationOfferControl) {
    syndicationOfferControl.get('calculationLogic').setValue(event.value);
    syndicationOfferControl.get('maximumPercentSyndicated').setValue(null, {emitEvent: false, onlySelf: true});
    syndicationOfferControl.get('maximumAmountSyndicated').setValue(null, {emitEvent: false, onlySelf: true});
    syndicationOfferControl.get('principalToBePaid').setValue(null, {emitEvent: false, onlySelf: true});

    syndicationOfferControl.get('maximumPercentSyndicated').setErrors({'incorrect': true} )
    syndicationOfferControl.get('maximumAmountSyndicated').setErrors({'incorrect': true})
  }
}
