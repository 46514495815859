import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

const SUCCESS_MESSAGE = "Success";
const ERROR_MESSAGE = "Error - try again";

@Injectable({
  providedIn: 'root'
})

export class AlertService {
  durationInSeconds = 2.5;

  constructor(
    private _snackbar: MatSnackBar
  ) { }

  private open(message: string, action: any, type?: string, duration?: number) {
    this._snackbar.open(message, action, {
      duration: duration == null ? this.durationInSeconds * 1000 : duration,
      panelClass: ['onyxiq-snack-bar',type]
    });
  }

  public success(message: string = null, action: any = null, duration?: number){
    const successMessage  = message ? message : SUCCESS_MESSAGE;
    return this.open(successMessage, action? action: null, 'success', duration);
  }

  public error(message: string = null, action: any = null, duration?: number){
    const errorMessage  = message ? message : ERROR_MESSAGE;
    return this.open(errorMessage, action? action: null, 'error', duration);
  }

  public info(message: string, action: any = null, duration?: number){
    return this.open(message, action? action: null, 'info', duration);
  }
}



