<table mat-table matSort [dataSource]="offersTable" multiTemplateDataRows>
    <ng-container matColumnDef="prepayTerms">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <div class="expand-cell" (click)="toggleOffersRow(row)">
          Prepayments/Fees
          <i class="material-icons" *ngIf="row !== expandedOffersRow">arrow_right</i>
          <i class="material-icons" *ngIf="row === expandedOffersRow">arrow_drop_down</i>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="includeOffer"  *ngIf="isShowingIncludeOffer">
      <th mat-header-cell *matHeaderCellDef>Include</th>
      <td mat-cell *matCellDef="let row">
        <label class="togglebox">
          <input type="checkbox" [disabled]="disabled" [(ngModel)]="row.sendOffer" [ngModelOptions]="{ standalone: true }" #enabledSwitch data-md-icheck (change)="toggleOffer($event, row.id)"/>
          <div class="slider" [ngClass]="{ 'active': enabledSwitch.checked }">
            <div class="switch"></div>
            <span class="failure" [ngClass]="{ 'active': offerUpdateError }">
              {{ offerUpdateError }}
            </span>
          </div>
        </label>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let row">
        <b *ngIf="application?.appliedFundingOffer && row.id === application?.appliedFundingOffer.id">Applied<br></b>
        <b *ngIf="application?.appliedFundingOffer && row.id !== application?.appliedFundingOffer.id && !row.originalOffer">N/A<br></b>
        <b *ngIf="application?.appliedFundingOffer && row.id !== application?.appliedFundingOffer.id && row.originalOffer">Originally&nbsp;Funded<br></b>
        <b *ngIf="row.sentOn">Sent on <br>{{ row.sentOn | date: 'shortDate' }}</b>
      </td>
    </ng-container>
    <ng-container matColumnDef="sentOn">
      <th mat-header-cell *matHeaderCellDef>Created Date</th>
      <td mat-cell *matCellDef="let row"><b *ngIf="row.createdDate">{{ row.createdDate | date:'short' || 'n/a' }}</b></td>
    </ng-container>
    <ng-container matColumnDef="fundedAmount">
      <th mat-header-cell *matHeaderCellDef>Approved</th>
      <td class="align-center" mat-cell *matCellDef="let row">{{ row.fundedAmount | currency }}</td>
    </ng-container>
    <ng-container matColumnDef="paybackAmount">
      <th mat-header-cell *matHeaderCellDef>Payback</th>
      <td class="align-center" at-cell *matCellDef="let row">{{ row.paybackAmount | currency }}</td>
    </ng-container>

    <ng-container matColumnDef="transmissionMethod">
      <th mat-header-cell *matHeaderCellDef>Transmission Method</th>
      <td class="align-center" at-cell *matCellDef="let row">{{ (row.transmissionMethod | status) || 'ACH' }}</td>
    </ng-container>
    <ng-container matColumnDef="splitPercent">
      <th mat-header-cell *matHeaderCellDef>Split Percent</th>
      <td class="align-center" at-cell *matCellDef="let row">{{ row.transmissionMethod === 'Split_Lockbox' ? (row.splitPercent/100 | percent: '1.0-2' || 'n/a') : 'n/a' }}</td>
    </ng-container>


    <ng-container matColumnDef="termLength">
      <th mat-header-cell *matHeaderCellDef>Term Length</th>
      <td class="align-center" mat-cell *matCellDef="let row">{{ row.termLength }}</td>
    </ng-container>
    <ng-container matColumnDef="buyRate">
      <th mat-header-cell *matHeaderCellDef>Buy rate</th>
      <td class="align-center" mat-cell *matCellDef="let row">{{ row.buyRate | number: '1.1-4'}}</td>
    </ng-container>
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>Position</th>
      <td class="align-center" mat-cell *matCellDef="let row">{{ row.position }}</td>
    </ng-container>
    <ng-container matColumnDef="commission">
      <th mat-header-cell *matHeaderCellDef>Commission</th>
      <td class="align-center" mat-cell *matCellDef="let row">{{ row.commissionAmount | currency }}</td>
    </ng-container>
    <ng-container matColumnDef="commissionPercent">
      <th mat-header-cell *matHeaderCellDef>Commission Percent</th>
      <td class="align-center" mat-cell *matCellDef="let row">{{ row.commissionPercent | number : '1.2-2' }}%</td>
    </ng-container>
    <ng-container matColumnDef="maximumCommissionPercent">
      <th mat-header-cell *matHeaderCellDef>Maximum Commission Percent</th>
      <td class="align-center" mat-cell *matCellDef="let row">{{ getMaximumCommissionValue(row) | number : '1.2-2' }}%</td>
    </ng-container>
    <ng-container matColumnDef="factorRate">
      <th mat-header-cell *matHeaderCellDef>Factor Rate</th>
      <td class="align-center" mat-cell *matCellDef="let row">{{ row.factorRate }}</td>
    </ng-container>
    <ng-container matColumnDef="totalPayments">
      <th mat-header-cell *matHeaderCellDef># of Payments</th>
      <td class="align-center" mat-cell *matCellDef="let row">{{ row.transmissionMethod === 'Split_Lockbox' ? 'n/a' : row.totalPayments }}</td>
    </ng-container>
    <ng-container matColumnDef="bankFeeAmount">
      <th mat-header-cell *matHeaderCellDef>Origination Fee</th>
      <td class="align-center" mat-cell *matCellDef="let row">{{ showBankFee(row.fees) | currency }}</td>
    </ng-container>
    <ng-container matColumnDef="paymentAmount">
      <th mat-header-cell *matHeaderCellDef>Payment Amount</th>
      <td class="align-center" mat-cell *matCellDef="let row">{{ row.transmissionMethod === 'Split_Lockbox' ? 'n/a' : (row.paymentAmount | currency) }}</td>
    </ng-container>
    <ng-container matColumnDef="paymentFrequency">
      <th mat-header-cell *matHeaderCellDef>Frequency</th>
      <td class="align-center" mat-cell *matCellDef="let row">{{ row.transmissionMethod === 'Split_Lockbox' ? 'n/a' : row.paymentFrequency }}</td>
    </ng-container>
    <ng-container matColumnDef="percentOfGross">
      <th mat-header-cell *matHeaderCellDef>% Gross</th>
      <td class="align-center" mat-cell *matCellDef="let row">{{ row.percentOfGross | number : '1.2-2' }}%</td>
    </ng-container>
    <ng-container matColumnDef="actions" *ngIf="isShowingActions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let row; let offerIndex = dataIndex" class="text-nowrap">

        <mat-form-field appearance="outline" *ngIf="!disabled">
          <mat-select class="hide-selected" #termAction [placeholder]="'Action'"
                      (selectionChange)="invokeTermAction(row, termAction.value, $event, offerIndex)"
                      [disabled]="application.status === 'Declined' || application.status === 'Funded'"
                      (click)="checkPrepaysLImit(row)">
            <mat-option [value]="'applyTerm'" *ngIf="application?.appliedFundingOffer && row.id !== application?.appliedFundingOffer.id">
              APPLY
            </mat-option>
            <mat-option [value]="'addPrepay'" [disabled]="row.sentOn || (prepaysCount >= maxPrepays)" [matTooltip]="prepaysCount >= maxPrepays ?? 'Maximum number of prepays reached. You can only add up to 8 prepays'">
              ADD PREPAY TERMS
            </mat-option>
            <mat-option [value]="'addFee'" [disabled]="row.sentOn">
              ADD FEES
            </mat-option>
            <mat-option [value]="'edit'">
              EDIT
            </mat-option>
            <mat-option [value]="'delete'" [class.disabled-option]="row.sentOn && application?.appliedFundingOffer && row.id === application?.appliedFundingOffer.id">
              DELETE
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let row" [attr.colspan]="displayedOffersColumns.length" class="expandable-cell">
        <div class="row-details" [@detailExpand]="row == expandedOffersRow ? 'expanded' : 'collapsed'">
          <div class="table-wrapper">
            <table class="inner-table">
              <thead>
              <tr>
                <th colspan="5">
                  <h5>
                    PREPAYMENT TERMS
                  </h5>
                  <small><i>{{prepaysCount}} of the allowed {{maxPrepays}} rows added.</i></small>
                </th>
              </tr>
              <tr>
                <th>Buy Rate</th>
                <th>Start Day</th>
                <th>End Day</th>
                <th>Factor Rate</th>
                <th>Point Built In</th>
                <th>Total Repayment</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let p of prepayments; let i = index">
                <td class="align-center" >{{ p.buyRate | number: '1.1-4'}}</td>
                <td class="align-center" >{{ p.startDay }}</td>
                <td class="align-center" >{{ p.endDay }}</td>
                <td class="align-center" >{{ p.factorRate }}</td>
                <td class="align-center" >{{ p.commissionPercent | number : '1.2-2' }}%</td>
                <td class="align-center" >{{ p.paybackAmount | currency}}</td>
                <td>
                  <div *ngIf="!disabled && !p.deleting">
                    <button mat-raised-button color="primary" class="btn-table me-2" type="button" (click)="editPrepaymentDialog(row, i)"
                      [disabled]="application.status === 'Declined' || application.status === 'Funded' || row.sentOn">
                      EDIT
                    </button>
                    <button mat-raised-button color="warn" class="btn-table" type="button" (click)="deletePrepayment(p)"
                      [disabled]="application.status === 'Declined' || application.status === 'Funded' || row.sentOn">DELETE
                    </button>
                  </div>
                  <div *ngIf="!disabled && p.deleting">
                    Are you sure?
                    <a href="#" (click)="$event.preventDefault(); confirmDeletePrepayment(row, p)">Yes</a>
                    |
                    <a href="#" (click)="$event.preventDefault(); cancelDeletePrepayment(p)">No</a>
                  </div>
                </td>
              </tr>
              <tr>
                <td [attr.colspan]="6">
                  <button mat-raised-button color="primary" class="btn-table me-2 my-1" type="button" (click)="openPrepaymentDialog(row, offerIndex)"
                  [disabled]="row.sentOn || (prepaysCount >= maxPrepays)">
                    ADD PREPAY
                  </button>
                </td>
              </tr>
              <tr *ngIf="prepaysCount >= maxPrepays">
                <td [attr.colspan]="6">
                  <span class="mb-2 text-black-50">Maximum number of prepays reached. You can only add up to {{ maxPrepays }} prepays</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="table-wrapper">
            <table class="inner-table fees-table">
              <thead>
              <tr>
                <th colspan="5">
                  <h5>
                    APPLIED FEES
                  </h5>
                </th>
              </tr>
              <tr>
                <th>Fee Name</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Percentage</th>
                <th>Frequency</th>
                <th>Payment Days</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fee of row.fees; let feeIndex = index;">
                  <td>{{ fee.type !== 'Other' ? fee.type.replace('_', ' ') + ' Fee' : fee.name }}</td>
                  <td>{{ fee.recurring ? 'Recurring' : 'One Time' }}</td>
                  <td>{{ fee.amount | currency }}</td>
                  <td>{{ fee.amount / row.fundedAmount * 100 | number : '1.2-2' }}%</td>
                  <td>{{ fee.paymentPlan?.paymentFrequency }}</td>
                  <td><div [innerHTML]="fee.paymentPlan?.paymentDays ? (fee.paymentPlan?.paymentDays | paymentDays) : ''"></div></td>
                  <td>
                    <div *ngIf="!disabled && !fee.deleting">
                      <button mat-raised-button color="primary" class="btn-table me-2" type="button" (click)="openEditFeeDialog(row, fee.id, feeIndex, fee.recurring, fee.paymentPlan)"
                        [disabled]="application.status === 'Declined' || application.status === 'Funded' || row.sentOn">
                        EDIT
                      </button>
                      <button mat-raised-button color="warn" class="btn-table me-2" type="button" (click)="deleteFee(fee)"
                        [disabled]="application.status === 'Declined' || application.status === 'Funded' || row.sentOn">
                        DELETE
                      </button>
                    </div>
                    <div *ngIf="!disabled && fee.deleting">
                      Are you sure?
                      <a href="#" (click)="$event.preventDefault(); confirmDeleteFee(row, fee, feeIndex, fee.recurring)">Yes</a>
                      |
                      <a href="#" (click)="$event.preventDefault(); cancelDeleteFee(fee)">No</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td [attr.colspan]="6">
                    <button mat-raised-button color="primary" class="btn-table me-2 my-1" type="button" (click)="openEditFeeDialog(row)"
                    [disabled]="row.sentOn">
                      ADD FEE
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedOffersColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedOffersColumns;" class="element-row"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>
