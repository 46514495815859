import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'oiq-v2-app',
  templateUrl: './v2-app.component.html',
  styleUrls: ['./v2-app.component.scss'],
})
export class V2AppComponent implements OnInit, OnDestroy {
  isOpen: boolean = true;

  constructor() { }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  toggleDrawer() {
    this.isOpen = !this.isOpen;
    //
  }
}
