<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Docket Details</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <div mat-dialog-content>
    <div *ngIf="loading" class="loading-spinner">Loading...</div>
    <div *ngIf="!loading" [innerHTML]="docketHtml"></div>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button (click)="onCancel()">Close</button>
  </div>
</div>
