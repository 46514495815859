import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SyndicatorService, TransmissionService } from '@core/services';
import * as moment from "moment/moment";

@Component({
  selector: 'oiq-add-manual-wallet-transaction',
  templateUrl: './add-manual-wallet-transaction.component.html',
  styleUrls: ['./add-manual-wallet-transaction.component.scss']
})
export class AddManualWalletTransactionComponent implements OnInit {

  form: UntypedFormGroup;
  loading = false;
  errorMessage: string | any[];

  // add transmissionMethodList and transmissionProcessorList from add-manual-wallet-transaction.component.html
  transmissionMethodList = [];
  transmissionMethods = [];
  transmissionProcessors = [];

  whiteLabelId: any;
  paymentReason: string;
  processorType: string = 'Manual';
  minDate: Date;
  maxDate: Date = new Date();

  statuses = [
    { name: 'New', value: 'New' },
    { name: 'Scheduled', value: 'Scheduled' },
    { name: 'Submitted', value: 'Submitted' },
    { name: 'Settled', value: 'Settled' },
    { name: 'Canceled', value: 'Canceled' },
    { name: 'Rejected', value: 'Rejected' },
    { name: 'Refunded', value: 'Refunded' },
    { name: 'Returned', value: 'Returned' },
    { name: 'Chargeback', value: 'Chargeback' }
  ];

  responseDescriptions = [
    { name: 'ACH Bounced', value: 'Returned_Insufficient_Funds' },
    { name: 'ACH Stopped', value: 'Returned_Stop_Payment' }
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private syndicatorService: SyndicatorService,
    private transmissionService: TransmissionService,
    public dialogRef: MatDialogRef<AddManualWalletTransactionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.form = this.fb.group({
      transmissionMethod: ['', Validators.required],
      transmissionProcessor: [''],
      amount: ['', Validators.compose([Validators.required, Validators.min(0.01)])],
      hitDate: ['', Validators.required],
      settlementDate: ['', Validators.required],
      bankId: [''],
      syndicatorWalletId: [this.data.wallet.id]
    });

    this.paymentReason = this.data.paymentReason;
    this.whiteLabelId = this.data.whiteLabelId;
    this.transmissionService.getProcessorsByWhiteLabel(this.whiteLabelId, this.paymentReason)
      .subscribe((r: any) => {
        this.transmissionMethods = r;
        this.setTransmissionMethodList();
      });

      this.form.get('transmissionMethod').valueChanges.subscribe(res => {
        if (res === 'Wire_Transfer') {
          if (this.form.get('hitDate').value) {
            this.form.get('settlementDate').enable();
            this.form.get('settlementDate').setValue(this.form.get('hitDate').value);
          }
          this.form.updateValueAndValidity();
        } else {
          this.form.get('settlementDate').enable();
          this.form.updateValueAndValidity();
        }
      });
  }

  onBankSelected(event) {
    if (event) {
      this.form.get('bankId').setValue(event);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      if (this.form.get('hitDate').value) {
        const formatHitDate = moment(this.form.get('hitDate').value).format('YYYY-MM-DD');
        this.form.get('hitDate').setValue(formatHitDate);
      }
      if (this.form.get('settlementDate').value && this.processorType === 'Manual') {
        const formatSettlementDate = moment(this.form.get('settlementDate').value).format('YYYY-MM-DD');
        this.form.get('settlementDate').setValue(formatSettlementDate);
      } else {
        this.form.get('settlementDate').setValue(null);
      }
      this.loading = true;
      this.errorMessage = null;

      if (this.paymentReason === 'Wallet_Credit') {
        this.syndicatorService.addSyndicatorWalletCredit(this.data.syndicatorId, this.form.value)
        .subscribe((r: any) => {
          this.dialogRef.close(true);
        },
          (error) => {
            this.loading = false;
            this.errorMessage = error;
          },
          () => {
            this.loading = false;
          }
        );
      }
      else if (this.paymentReason === 'Wallet_Debit') {
        this.syndicatorService.addSyndicatorWalletDebit(this.data.syndicatorId, this.form.value)
        .subscribe((r: any) => {
          this.dialogRef.close(true);
        },
          (error) => {
            this.loading = false;
            this.errorMessage = error;
          },
          () => {
            this.loading = false;
          }
        );
      }
    }
  }

  onTransmissionMethodChanged(event) {
    this.processorType = 'Manual';
    this.form.get('settlementDate').setValue('');
    this.form.get('settlementDate').setValidators(Validators.required);
    this.form.get('transmissionProcessor').setValue('');
    this.form.get('bankId').clearValidators();

    this.setTransmissionMethodProcessors(event.value);

    if (this.transmissionProcessors) {
      this.form.get('transmissionProcessor').setValidators([Validators.required]);
      this.form.get('bankId').setValidators([Validators.required]);

      this.form.patchValue({
        transmissionProcessor: '',
        bankId: '',
      });
    } else {
      this.form.get('transmissionProcessor').clearValidators();
      this.form.get('bankId').clearValidators();

      this.form.patchValue({
        transmissionProcessor: 'Manual',
        bankId: '',
      });
    }

    this.form.get('transmissionProcessor').updateValueAndValidity();
  }

  onTransmissionProcessorChanged(event) {
    this.transmissionService.getTransmissionProcessor(
      this.whiteLabelId,
      this.data.paymentReason,
      this.form.get('transmissionMethod').value,
      this.form.get('transmissionProcessor').value
    )
    .subscribe((r: any) => {
      this.processorType = r.type;
      if (r.type === 'Automatic') {

        this.form.get('hitDate').setValue('');
        this.form.get('settlementDate').setValue('');
        this.form.get('settlementDate').clearValidators();

        const today = new Date();
        this.minDate = new Date(today);
        this.minDate.setDate(this.minDate.getDate() + 1);

        this.getEffectiveDate();
        this.form.get('settlementDate').updateValueAndValidity();
      } else if (r.type === 'Manual') {
        this.form.get('bankId').clearValidators();
        this.form.get('settlementDate').setValue('');
        this.form.get('settlementDate').setValidators(Validators.required);
      }

    });
  }

  getEffectiveDate() {
    const date = new Date();
    this.transmissionService.getEffectiveDate(
      this.form.get('transmissionMethod').value,
      this.form.get('transmissionProcessor').value
    ).subscribe((r: any) => {
      const effectiveDate = new Date();
      effectiveDate.setFullYear(r[0], r[1] - 1, r[2]);

      this.minDate = effectiveDate;
    });
  }

  setTransmissionMethodProcessors(transmissionMethod: string) {
    this.transmissionProcessors = this.transmissionMethods.filter((method: any) => method.name === transmissionMethod)[0].processors;
  }

  setTransmissionMethodList() {
     this.transmissionMethods.map((obj:any) => {
      this.transmissionMethodList.push({
        label: obj.name.replace('_', ' '),
        value: obj.name
      });
    });
  }

}
