import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PropertyComparisonChange } from '@shared/utils';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { StatusPipe } from '@shared/status.pipe';

type FormattedChangedProperty = PropertyComparisonChange & { label: string };
@Component({
  selector: 'oiq-property-changes-table',
  templateUrl: './property-changes-table.component.html',
  styleUrls: ['./property-changes-table.component.scss'],
  providers: [DecimalPipe, CurrencyPipe, StatusPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyChangesTableComponent implements OnInit, OnChanges {

  @Input() changedProperties: PropertyComparisonChange[] = [];
  @Input() showOnlyOldValue = false;
  @Input() showOnlyNewValue = false;

  changesDataSource = new MatTableDataSource([]);
  columns: string[] = [];

  constructor(
    private currencyPipe: CurrencyPipe,
    private statusPipe: StatusPipe,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.changedProperties) {
      this.columns = this.getColumns();
      const { currentValue } = changes.changedProperties;

      if (currentValue && currentValue.length > 0) {
        const formattedChangedProperties = currentValue.map(this.formatForRepresentingInTable.bind(this));
        this.changesDataSource = new MatTableDataSource(formattedChangedProperties);
      } else {
        this.changesDataSource = new MatTableDataSource([]);
      }
    }
  }

  trackByProperty(index: number, item: PropertyComparisonChange): string {
    return item.propertyName;
  }

  getColumns(): string[] {
    if (this.showOnlyNewValue) {
      return ['label', 'addedValueIndicator', 'newValue'];
    }
    if (this.showOnlyOldValue) {
      return ['label', 'removedValueIndicator', 'oldValue'];
    }
    return ['label', 'oldValue', 'editedValueIndicator', 'newValue'];
  }

  formatForRepresentingInTable(changedProperty: PropertyComparisonChange) {
    switch (changedProperty.propertyName) {
      case 'termLength':
        return {
          label: this.getLabel(changedProperty.propertyName),
          oldValue: `${changedProperty.oldValue} ${changedProperty.oldValue === 1 ? 'month' : 'months'}`,
          newValue: `${changedProperty.newValue} ${changedProperty.newValue === 1 ? 'month' : 'months'}`,
          propertyName: changedProperty.propertyName,
        };

      case 'bankFeePercent':
        return {
          label: this.getLabel(changedProperty.propertyName),
          oldValue: `${this.decimalPipe.transform(changedProperty.oldValue, '1.2-2')} %`,
          newValue: `${this.decimalPipe.transform(changedProperty.newValue, '1.2-2')} %`,
          propertyName: changedProperty.propertyName,
        };

      case 'amount':
      case 'bankFeeAmount':
      case 'commissionAmount':
      case 'fundedAmount':
      case 'paybackAmount':
      case 'paymentAmount': {
        return {
          label: this.getLabel(changedProperty.propertyName),
          oldValue: this.currencyPipe.transform(changedProperty.oldValue),
          newValue: this.currencyPipe.transform(changedProperty.newValue),
          propertyName: changedProperty.propertyName,
        };
      }

      case 'commissionPercent':
        return {
          label: this.getLabel(changedProperty.propertyName),
          oldValue: `${this.decimalPipe.transform(changedProperty.oldValue, '1.2-2')} %`,
          newValue: `${this.decimalPipe.transform(changedProperty.newValue, '1.2-2')} %`,
          propertyName: changedProperty.propertyName,
        };

      case 'transmissionMethod':
      case 'transmissionProcessor':
        return {
          label: this.getLabel(changedProperty.propertyName),
          oldValue: this.statusPipe.transform(changedProperty.oldValue),
          newValue: this.statusPipe.transform(changedProperty.newValue),
          propertyName: changedProperty.propertyName,
        };

      case 'paymentDays':
        return {
          label: this.getLabel(changedProperty.propertyName),
          oldValue: this.formatPaymentDays(changedProperty.oldValue),
          newValue: this.formatPaymentDays(changedProperty.newValue),
          propertyName: changedProperty.propertyName,
        };

      case 'position':
      case 'totalPayments':
      case 'buyRate':
      case 'factorRate':
      case 'paymentFrequency':
      case 'startDay':
      case 'customCommissionEnabled':
      case 'endDay':
      case 'status':
        return {
          label: this.getLabel(changedProperty.propertyName),
          oldValue: changedProperty.oldValue,
          newValue: changedProperty.newValue,
          propertyName: changedProperty.propertyName,
        };
      case 'processorAccountName':
        return {
          label: this.getLabel(changedProperty.propertyName),
          oldValue: changedProperty.oldValue,
          newValue: changedProperty.newValue,
          propertyName: changedProperty.propertyName,
        };
      case 'startDate':
      case 'endDate':
        return {
          label: this.getLabel(changedProperty.propertyName),
          oldValue: this.datePipe.transform(changedProperty.oldValue, 'd/MM/yyyy'),
          newValue: this.datePipe.transform(changedProperty.newValue, 'd/MM/yyyy'),
          propertyName: changedProperty.propertyName,
        };

      case 'bankId':
        return {
          label: this.getLabel(changedProperty.propertyName),
          oldValue: this.formatBankData(changedProperty.oldValue),
          newValue: this.formatBankData(changedProperty.newValue),
          propertyName: changedProperty.propertyName,
        }

      case 'waived':
        return {
          label: this.getLabel(changedProperty.propertyName),
          oldValue: changedProperty.oldValue ? 'Yes' : 'No',
          newValue: changedProperty.newValue ? 'Yes' : 'No',
          propertyName: changedProperty.propertyName,
        }

      default:
        console.error('Unknown property:', changedProperty);
        return {
          label: changedProperty.propertyName,
          oldValue: changedProperty.oldValue,
          newValue: changedProperty.newValue,
          propertyName: changedProperty.propertyName,
        };
    }
  }

  private getLabel(propertyName: string): string {
    const labels = {
      'amount': 'Amount',
      'bankId': 'Bank',
      'bankFeePercent': 'Origination Fee Percent',
      'bankFeeAmount': 'Origination Fee Amount',
      'buyRate': 'Buy Rate',
      'factorRate': 'Factor Rate',
      'endDay': 'End Day',
      'commissionAmount': 'Commission Amount',
      'commissionPercent': 'Commission Percent',
      'endDate': 'End Date',
      'fundedAmount': 'Approved (Funded) Amount',
      'notes': 'Notes',
      'paybackAmount': 'Payback Amount',
      'paymentAmount': 'Payment Amount',
      'paymentDays': 'Payment Days',
      'paymentFrequency': 'Payment Frequency',
      'paymentPlanType': 'Payment Plan Type',
      'position': 'Position',
      'processorAccountName': 'Processor Account Name',
      'startDate': 'Start Date',
      'startDay': 'Start Day',
      'status': 'Status',
      'termLength': 'Term Length',
      'totalPayments': 'Total Payments',
      'transmissionMethod': 'Transmission Method',
      'transmissionProcessor': 'Transmission Processor',
      'waived': "Waived recurring fees",
      'customCommissionEnabled': 'Set Custom Commission'
    };

    return labels[propertyName] || propertyName;
  }

  formatPaymentDays(days) {
    const selectedDays = Object.entries(days)
      .filter(([key, val]) => key !== 'firstDayOfWeek' && val === true)
      .map(([key]) => this.capitalizeFirstLetter(key));

    return selectedDays.join(', ');
  }

  formatBankData(bank) {
    return `${bank?.name}${bank?.branch ? ' - ' + bank?.branch : ''} (${bank?.routingNumber} / ${bank.accountNumber})`
  }

  private capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
