

<div class="row">
  <div class="col">
    <div class="loading-records" *ngIf="isLoadingSyndicators">Loading</div>
  </div>
</div>
<div class="row" *ngIf="syndicators && syndicators.length && !isEditMode" >
  <div class="col-sm-3">
        <mat-label>Select Syndicators</mat-label>
        <mat-form-field appearance="outline" *ngIf="!loadingSyndicators">
          <mat-select [(ngModel)]="selectedSyndicators" [ngModelOptions]="{standalone: true}" (selectionChange)="onSyndicatorSelected($event)"  multiple>
            <mat-option *ngFor="let syndicator of syndicators" [value]="syndicator">
              {{ syndicator.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
  </div>
</div>
<table>
    <thead>
    <tr>
  <th>Name</th>
  <th>Principal Syndicated *</th>
  <th>Percent Syndicated % *</th>
  <th>Principal To Be Paid *</th>
  <th>Management Fee Percentage *</th>
  <th>Commission * <br> <span *ngIf="customCommissionPercentEnabled" class="maximum-commission-percent">Maximum commission percent is: {{this.maximumCommissionPercent ? this.maximumCommissionPercent : this.commissionPercent}}</span></th>
  <th>Factor Rate</th>
  <th>Total To Be Paid</th>
  <th>Payback Amount</th>
  <th>Syndication Calculation Logic</th>
  <th>&nbsp;</th>
  </tr>
  </thead>

  <tbody>
    <ng-container *ngFor="let syndicationForm of syndicationForms"  [formGroup]="syndicationForm">
    <tr >
      <td>
        <p class="syndicator-name"> {{ syndicationForm.get('syndicatorName').value }}</p>
      </td>
      <td>
        <mat-form-field appearance="outline">
          <input matInput formControlName="acceptedAmountSyndicated" (keyup)="onAmountSyndicatedChanged(syndicationForm)" type="text"
            autocomplete="off" currencyMask>
        </mat-form-field>
      </td>

      <td>
        <mat-form-field appearance="outline">
          <input matInput formControlName="acceptedPercentSyndicated" (input)="onPercentSyndicatedChanged(syndicationForm)"
            type="number" autocomplete="off">
        </mat-form-field>
      </td>

      <td>
        <mat-form-field appearance="outline">
          <input matInput formControlName="principalToBePaid" [readonly]="true" type="text" autocomplete="off" currencyMask>
        </mat-form-field>
      </td>

      <td>
        <mat-form-field appearance="outline">
          <input matInput formControlName="managementFeePercent" type="number" autocomplete="off">
        </mat-form-field>
      </td>

      <td *ngIf="customCommissionPercentEnabled">
        <mat-form-field appearance="outline">
          <input matInput formControlName="commissionPercent" type="number" autocomplete="off" readonly="false" (input)="onCommissionChanged(syndicationForm)" min="0">
        </mat-form-field>
      </td>

      <td *ngIf="!customCommissionPercentEnabled">
        {{commissionPercent}}
      </td>

      <td>
        {{ factorRate }}
      </td>

      <td>
        {{ syndicationForm.get('maxTotalReceived').value | currency }}
      </td>

      <td>
        {{ syndicationForm.get('maxPayback').value | currency }}
      </td>

      <td class="defaultSyndicationCalculationLogicOptionColumn">
        <mat-form-field appearance="outline">
          <mat-select formControlName="calculationLogic" required
            (selectionChange)="onCalculationLogicChanged($event, syndicationForm)">
            <mat-option *ngFor="let calculationLogicOption of syndicationCalculationLogicOptions"
              [value]="calculationLogicOption.value">
              {{calculationLogicOption.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>

      <td *ngIf="!isEditMode">
        <button type="button" class="delete-btn" title="Remove Syndicator"
          (click)="onRemoveSyndicator(syndicationForm)"></button>
      </td>

    </tr>
    <tr>
      <p class="error-text warn" *ngIf="syndicationForm.get('acceptedPercentSyndicated').errors?.max">
        Percent must be less than 100
      </p>
    </tr>
  </ng-container>
  </tbody>
</table>

